/** @format */

import React, { useEffect } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Page404 from "./Pages/404";
import Booking from "./Pages/Booking";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import SignupLogin from "./Pages/Signup-Login";
import Property from "./Pages/Property";
import UserProfile from "./Pages/UserProfile";
import UserProfileChangePassword from "./Pages/UserProfile/changepassword";
import PaymentHistory from "./Pages/UserProfile/payment";
import WishlistPage from "./Pages/UserProfile/wishlistpage";
import RecommendedProperties from "./Pages/UserProfile/recommendedProperties";
import MyPropertyPage from "./Pages/UserProfile/myPropertyPage";
import AddProperty from "./Pages/AddProperty";
import SingleProperty from "./Pages/SingleProperty";
import ResetPassword from "./Pages/ResetPassword";
import UpdatePassword from "./Pages/UpdatePassword";
import MyVisit from "./Pages/UserProfile/myVisit";
import HeaderWrapper from "./LayoutWrapper/ContextWrapper/HeaderWrapper";
import SingleRecommended from "./Components/Userprofile/singleRecommended";
import OwnerPlan from "./Pages/OwnerPlan";
import TenantPlan from "./Pages/TenantPlan";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsConditions from "./Pages/T&Cs";
import ScrollTop from "./Components/ScrollToTop";
import ReferEarn from "./Pages/ReferEarn";
import PriceTrends from "./Pages/PriceTrends";
import LocalityInsight from "./Pages/LocalityInsight";
import ZeroBrokerage from "./Pages/ZeroBrokerage";
import HelpSupport from "./Pages/UserProfile/help";
import Payment from "./Pages/Payment";
import PhonePay from "./Pages/PhonePay";
// import { messaging } from "./firebase";
import { getToken } from "firebase/messaging";
import { putApihandler } from "./Apihandler";
import TrackMyThing from "./Pages/UserProfile/trackMyThing";
import FAQ from "./Pages/FAQ";
import TenantFAQ from "./Pages/FAQ/TenantFAQ";
import LandlordFAQ from "./Pages/FAQ/LandlordFAQ";
import FaqList from "./Pages/FAQ/faqList";
import FaqDetails from "./Pages/FAQ/faqDetails";
import Test from "./Pages/Test";
import RefundPolicy from "./Pages/RefundPolicy";
import ResponsiveBedcard from "./Components/Properties/ResponsiveBedcard";

function App() {
  const [userId, setUserId] = React.useState();

  // Get Local Storage Data
  React.useEffect(() => {
    setUserId(localStorage.getItem("UserId"));
  }, []);
  return (
    <div className="App">
      <HeaderWrapper>
        <HashRouter>
          <ScrollTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              {/* Faq's-------------------------------------------------- */}
              <Route path="/faq" element={<FAQ />} />
              <Route path="/faq/I'm a Tenant" element={<TenantFAQ />} />
              <Route path="/faq/I'm a Landlord" element={<LandlordFAQ />} />
              <Route path="/faq-list/:Type/:Category" element={<FaqList />} />
              <Route path="/faq-details/:QueryID" element={<FaqDetails />} />
              {/* /Faq's-------------------------------------------------- */}

              <Route path="/owner-plan" element={<OwnerPlan />} />
              <Route path="/tenant-plan" element={<TenantPlan />} />
              <Route path="/refer-earn" element={<ReferEarn />} />
              <Route path="/price-trends" element={<PriceTrends />} />
              <Route path="/locality-insight" element={<LocalityInsight />} />
              <Route
                path="/property-details/:searchKey"
                element={<SingleProperty />}
              />
              <Route path="/signup-login" element={<SignupLogin />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/update-password" element={<UpdatePassword />} />

              {/* User Profile-------- */}
              <Route path="/user-profile" element={<UserProfile />} />
              <Route
                path="/change-password"
                element={<UserProfileChangePassword />}
              />
              <Route path="/payment-history" element={<PaymentHistory />} />
              {/* <Route path="/wishlist" element={<WishlistPage />} /> */}
              <Route
                path="/recommended-properties"
                element={<RecommendedProperties />}
              />
              <Route
                path="/recommended-property/:searchKey"
                element={<SingleRecommended />}
              />
              <Route path="/my-properties" element={<MyPropertyPage />} />
              <Route path="/my-visits" element={<MyVisit />} />
              <Route path="/track-my-thing" element={<TrackMyThing />} />

              {/* Properties Tab ------------ */}
              <Route
                path="/properties/:proprertyKeys/:propertyType"
                element={<Property />}
              />
              {/* <Route path="/property/rooms" element={<RoomsProperty />} />
          <Route path="/property/houses" element={<HousesProperty />} />
          <Route path="/property/offers" element={<OffersProperty />} /> */}

              <Route path="/add-property" element={<AddProperty />} />
              <Route path="/booking" element={<Booking />} />
              {/* <Route path="/payment" element={<Payment />} /> */}
              <Route path="/payment/:planType/:planId" element={<PhonePay />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route path="/zero-brokerage" element={<ZeroBrokerage />} />
              <Route path="/help" element={<HelpSupport />} />
              <Route path="*" element={<Page404 />} />
              <Route path="test" element={<Test />} />
              <Route
                path="/refund-and-return-policy"
                element={<RefundPolicy />}
              />
              <Route
                path="/responsiveproperties"
                element={<ResponsiveBedcard />}
              ></Route>
            </Routes>
          </ScrollTop>
        </HashRouter>
      </HeaderWrapper>
    </div>
  );
}

export default App;
