/** @format */

import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styles from "../../Css/signup-login.module.css";
import { useForm } from "react-hook-form";
import { postApihandler, putApihandler } from "../../Apihandler";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../LayoutWrapper/AdminLayout";
import { Container } from "react-bootstrap";
import Money from "../../Images/ReferEarn/money-bag.png";
import Marketing from "../../Images/ReferEarn/money-bag-colored.png";
import { useEffect } from "react";

const defaultTheme = createTheme();

export default function ReferEarn() {
  const [userEmail, setUserEmail] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const { register, handleSubmit, reset } = useForm();
  const [id, setId] = React.useState("");

  const navigate = useNavigate();

  React.useEffect(() => {
    setUserEmail(localStorage.getItem("userEmail"));
    setUserName(localStorage.getItem("UserName"));
    setId(localStorage.getItem("UserId"));
  }, [userEmail, userName]);

  const ReferEarnSubmit = async (value) => {
    console.log("value====>", value);
    const res = await postApihandler("/inviteFriendForReferal", value);
    console.log("res=====>", res);
    if (res.status === 200) {
      Swal.fire({
        position: "middle-centre",
        icon: "success",
        title: "Your Details Submitted Successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      reset();
      // navigate("/login");
    } else {
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        text: res.error.response.data.message,
      });
    }
  };
  useEffect(() => {
    localStorage.setItem("activatedPage", "refer-earn");
  }, []);

  return (
    <Layout>
      <ThemeProvider theme={defaultTheme}>
        <section className="refer-earn-body insight-tools py-5 px-md-0 px-2">
          <Container maxWidth="xxl" className="py-4">
            <Grid
              container
              component="main"
              className="flex-lg-row flex-column flex-lg-wrap flex-nowrap mt-4 justify-content-center"
            >
              <CssBaseline />
              <Grid item xs={12} elevation={6} square className="px-md-5 px-2 ">
                <div
                  className="refer-earn-header flex-md-row flex-column d-flex align-items-center px-md-4 px-2 py-3"
                  style={{ backgroundColor: "rgb(7, 139, 76)" }}
                >
                  <div className="refer-small-icon me-3">
                    <img src={Money} alt="" style={{ width: "40px" }} />
                  </div>
                  <Typography
                    variant="h5"
                    component="h4"
                    className="largeHeading_TwoHeadings mb-2 text-white"
                  >
                    Refer and EARN
                  </Typography>
                </div>
                <Grid
                  container
                  className="flex-lg-row flex-column flex-lg-wrap flex-nowrap mt-4 justify-content-between"
                >
                  <Grid item lg={4} xs={12} elevation={6} square className="">
                    <Typography variant="h5" component="h5" accordion>
                      Refer your friends, family, colleagues or anyone who is
                      looking to rent a place and earn
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      className="mt-lg-5 mt-4"
                    >
                      <span
                        style={{
                          color: "rgb(7, 139, 76)",
                          paddingRight: "10px",
                        }}
                      >
                        <strong>₹ 1000</strong>
                      </span>
                      for EVERY referral.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={5}
                    xs={12}
                    elevation={6}
                    square
                    className=" py-lg-0 py-5"
                  >
                    <div className="refer-img-wrapper">
                      <img src={Marketing} alt="" style={{ width: "300px" }} />
                    </div>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    mb: 3,
                    display: "flex",
                    flexDirection: "column",
                  }}
                ></Box>
              </Grid>
              <Grid
                item
                lg={12}
                xs={12}
                elevation={6}
                square
                className="px-md-5 px-2 py-lg-0 py-5 mt-5"
              >
                <Typography
                  variant="h5"
                  component="h4"
                  className="smallHeading_TwoHeadings mb-2 "
                >
                  Invite Your Friends
                </Typography>
                <Typography variant="body1" component="p" accordion>
                  Add your friend's phone number & invite them to stay with
                  Nestaway & earn rewards.
                </Typography>
                <Box
                  sx={{
                    mb: 3,
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="pe-md-5"
                >
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit(ReferEarnSubmit)}
                    sx={{ mt: 3 }}
                    className="pe-md-5"
                  >
                    <TextField
                      placeholder="Insert Your Friend’s phone number*"
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      name="friend_phone"
                      type="number"
                      id="friend_phone"
                      autoComplete="friend_phone"
                      {...register("friend_phone")}
                      style={{ marginTop: "10px" }}
                    />
                    <TextField
                      placeholder="Insert Your Friend Name ( Optional )"
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      id="friend_name"
                      name="friend_name"
                      autoComplete="friend_name"
                      autoFocus
                      {...register("friend_name")}
                      style={{ marginTop: "10px" }}
                    />
                    <TextField
                      placeholder="Your Friend ‘s needed locality  or City ( Optional )"
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      id="friend_city"
                      name="friend_city"
                      autoComplete="friend_city"
                      autoFocus
                      {...register("friend_city")}
                      style={{ marginTop: "10px" }}
                    />
                    <Typography
                      variant="h6"
                      component="h4"
                      className=" mt-5 mb-2 "
                    >
                      Your Details
                    </Typography>
                    <TextField
                      placeholder="Your mobile number*"
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      name="your_phone"
                      type="number"
                      id="your_phone"
                      autoComplete="your_phone"
                      {...register("your_phone")}
                      style={{ marginTop: "10px" }}
                    />
                    <TextField
                      placeholder="Your Name*"
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      id="your_name"
                      name="your_name"
                      autoComplete="your_name"
                      autoFocus
                      {...register("your_name")}
                      style={{ marginTop: "10px" }}
                    />
                    <Box className="refer-btn-wrapper text-end">
                      <Button
                        type="submit"
                        variant="contained"
                        className="px-4 py-2 mt-4"
                        style={{ backgroundColor: "rgb(7, 139, 76)" }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </section>
      </ThemeProvider>
    </Layout>
  );
}
