import React from "react";
import { Container } from "react-bootstrap";
import "../../Pages/Home/home.css";
import { Box, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import HomeFamilies from "../../Images/Home/propertyType-1.svg";
import HomeFriends from "../../Images/Home/propertyType-2.svg";
import HomeSingle from "../../Images/Home/propertyType-3.svg";
import { Link } from "react-router-dom";

const PropertyType = () => {
  // Page Refresh
  function refreshPage() {
    window.location.reload();
  }
  // Handle Page Refresh-------------
  const handleRefresh = () => {
    setTimeout(() => {
      refreshPage();
    }, 200);
  };
  return (
    <section className="property-type-wrapper pt-lg-5 pt-md-3 pb-md-5 pb-4 mt-md-0 mt-5">
      <Container maxWidth="100%" className="py-lg-4 py-0">
        <Grid container spacing={2} className="justify-content-center">
          <Grid
            item
            className="pt-lg-0 pb-lg-0 pt-2 pb-0 px-lg-3 property-typeboxWrapper"
            lg={4}
            sm={10}
            xs={12}
          >
            <Card
              className="mx-auto"
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#1F4069",
                border: "1px solid #D9D9D9",
                boxShadow: "4px 4px 30px 0px #0000000D",
                padding: "35px 30px",
              }}
            >
              <div className="families property-type-block position-relative">
                <Grid container className="justify-content-center">
                  <Grid item xs={7}>
                    <div className="property-type-imgwrapper">
                      <img src={HomeFamilies} alt="" />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <CardContent className=" pb-0 px-0">
                <Typography
                  variant="subtitle1"
                  component="h5"
                  className="property-type-title"
                  style={{
                    fontSize: "24px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Buy Home
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  className="pt-2 text-center"
                >
                  Find your place with an immersive photo experience and the
                  most listings, including things you won't find anywhere else.
                </Typography>
              </CardContent>
              <Box className="pt-lg-4 pt-4 pb-2">
                <Typography
                  variant="subtitle2"
                  component="h5"
                  style={{
                    color: "#00C0FB",
                    fontWeight: 400,
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  <Link to={`/properties/list/sell`} onClick={handleRefresh}>
                    <span
                      style={{
                        padding: "10px 15px",
                        color: "#fff",
                        background: "#078b4c",
                        borderRadius: "4px",
                      }}
                    >
                      Explore
                    </span>
                  </Link>
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid
            item
            className="pt-lg-0 pb-lg-0 pt-4 pb-0 px-lg-3 mt-xl-0 mt-2 property-typeboxWrapper"
            lg={4}
            sm={10}
            xs={12}
          >
            <Card
              className="mx-auto"
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#1F4069",
                border: "1px solid #D9D9D9",
                boxShadow: "4px 4px 30px 0px #0000000D",
                padding: "35px 30px",
              }}
            >
              <div className="friends property-type-block position-relative">
                <Grid container className="justify-content-center">
                  <Grid item xs={7}>
                    <div className="property-type-imgwrapper">
                      <img src={HomeFriends} alt="" />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <CardContent className=" pb-0 px-0">
                <Typography
                  variant="subtitle1"
                  component="h5"
                  className="property-type-title"
                  style={{
                    fontSize: "24px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Buy/Rent Commercial Space
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  className="pt-2 text-center"
                >
                  No matter what path you take to Buy your First Commercial
                  Property, we can help you navigate a successful Deal.
                </Typography>
              </CardContent>
              <Box className="pt-lg-4 pt-4 pb-2">
                <Typography
                  variant="subtitle2"
                  component="h5"
                  style={{
                    color: "#00C0FB",
                    fontWeight: 400,
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  <Link
                  // to={`/properties/list/rent residential`}
                  // onClick={handleRefresh}
                  >
                    <span
                      style={{
                        padding: "10px 15px",
                        color: "#fff",
                        background: "#078b4c",
                        borderRadius: "4px",
                      }}
                    >
                      Explore
                    </span>
                  </Link>
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid
            item
            className="pt-lg-0 pb-lg-0 pt-4 pb-0 px-lg-3 mt-xl-0 mt-2 property-typeboxWrapper"
            lg={4}
            sm={10}
            xs={12}
          >
            <Card
              className="mx-auto"
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#1F4069",
                border: "1px solid #D9D9D9",
                boxShadow: "4px 4px 30px 0px #0000000D",
                padding: "35px 30px",
              }}
            >
              <div className="personal property-type-block position-relative">
                <Grid container className="justify-content-center">
                  <Grid item xs={7}>
                    <div className="property-type-imgwrapper">
                      <img src={HomeSingle} alt="" />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <CardContent className=" pb-0 px-0">
                <Typography
                  variant="subtitle1"
                  component="h5"
                  className="property-type-title"
                  style={{
                    fontSize: "24px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Rent Home
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  className="pt-2 text-center"
                >
                  We're creating a seamless online experience - from shopping on
                  the largest rental network, to applying,to paying rent.
                </Typography>
              </CardContent>
              <Box className="pt-lg-4 pt-4 pb-2">
                <Typography
                  variant="subtitle2"
                  component="h5"
                  style={{
                    color: "#00C0FB",
                    fontWeight: 400,
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  <Link to={`/properties/list/rent`} onClick={handleRefresh}>
                    <span
                      style={{
                        padding: "10px 15px",
                        color: "#fff",
                        background: "#078b4c",
                        borderRadius: "4px",
                      }}
                    >
                      Explore
                    </span>
                  </Link>
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default PropertyType;
