/** @format */

import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Grid, IconButton, Typography } from "@mui/material";

import styles2 from "../../Css/userprofile.module.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ShareIcon from "@mui/icons-material/Share";

import FavoriteIcon from "@mui/icons-material/Favorite";
import { imageUrl } from "../../Apihandler";
import Carousel from "react-bootstrap/Carousel";
import { RWebShare } from "react-web-share";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Swal from "sweetalert2";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import swal from "sweetalert";

const tabs = [
  { name: "Profile Detail", path: "/user-profile" },
  { name: "Change Password", path: "/change-password" },

  // { name: "Watchlist", path: "/wishlist" },
  {
    name: "Recommended Properties",
    path: "/recommended-properties",
  },
  {
    name: "My Properties",
    path: "/my-properties",
  },
  { name: "My Visits", path: "/my-visits" },
  { name: "Track My Thing", path: "/track-my-thing" },
  // { name: "Bank Details", path: "#" },
  // { name: "Setting", path: "#" },
  { name: "Help", path: "/help", color: `#02C988` },
];

export default function HelpAndSupport() {
  const Navigate = useNavigate();
  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "space-evenly" }}
        className="flex-md-row flex-column"
      >
        <Box
          sx={{ borderRight: "solid 1px #D2D2D2" }}
          className="ps-lg-3 ps-1 userProfile_TabSection"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "-31px",
            }}
          >
            <IconButton>
              <AccountCircleIcon
                style={{ color: "rgb(2, 201, 136)", fontSize: "110px" }}
              />
            </IconButton>
          </div>
          <List className="p-lg-5 p-3 d-md-block d-flex flex-wrap">
            {tabs.map((text, index) => (
              <Link
                to={text.path}
                className="me-md-0 me-2 mb-md-0 mb-2 userProfile_tab"
              >
                <ListItem
                  key={index}
                  disablePadding
                  sx={
                    {
                      // borderBottom: "1px solid #E7E7E7",
                      // marginBottom: "20px",
                    }
                  }
                  className="mb-md-4 mb-1 userProfile_tabInner"
                >
                  <ListItemButton>
                    <ListItemText
                      sx={{ color: `${text.color ? text.color : "#222222"}` }}
                      primary={text.name}
                      className="tab-text"
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
            <Link className="me-md-0 me-2 mb-md-0 mb-2 userProfile_tab">
              <ListItem
                disablePadding
                className="mb-md-4 mb-1 userProfile_tabInner"
              >
                <ListItemButton
                  onClick={() => {
                    swal({
                      title: "Are you sure?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        localStorage.clear();
                        swal({
                          icon: "success",
                          text: "You have been logged out!",
                        });
                        Navigate("/");
                        // refreshPage();
                      } else {
                        swal("Your login details are secure!", {
                          icon: "success",
                        });
                      }
                    });
                  }}
                >
                  <ListItemText
                    sx={{ color: "#222222" }}
                    primary="Logout"
                    className="tab-text"
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          className="userProfile_BodySection mb-md-5 mb-0"
        >
          <Typography className={styles2.profiledetail_heading}>
            Help & Support
          </Typography>
          <div className="mt-5">
            <Typography style={{ textAlign: "center" }}>
              <QuestionMarkIcon style={{ fontSize: "8rem" }} />
            </Typography>
            <br />
            <Typography variant="h6" style={{ textAlign: "center" }}>
              DON'T WORRY WE ARE HERE FOR YOU
            </Typography>
            <Typography
              variant="h6"
              style={{ textAlign: "center", fontWeight: "600" }}
            >
              beyond@bharatmakaan.com
            </Typography>
            <Typography
              variant="h6"
              style={{ textAlign: "center", fontWeight: "600" }}
            >
              Contact : 9429691949
            </Typography>
          </div>
        </Box>
      </Box>
    </>
  );
}
