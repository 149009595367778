/** @format */

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import {
  Box,
  Grid,
  InputBase,
  Typography,
  IconButton,
  Chip,
  InputAdornment,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
const LocalityData = [
  {
    name: "Nipania",
    city: "Indore",
  },
  {
    name: "Bhanwarkuan",
    city: "Indore",
  },

  {
    name: "Vijay nagar",
    city: "Indore",
  },
  {
    name: "Mr 10",
    city: "Indore",
  },
  {
    name: "Mr 9",
    city: "Indore",
  },
  {
    name: "AB BypassRoad",
    city: "Indore",
  },
  {
    name: "Mahalakshmi Nagar",
    city: "Indore",
  },
  {
    name: "Bicholi Mardana",
    city: "Indore",
  },
  {
    name: "Rau",
    city: "Indore",
  },
  {
    name: "Mhow",
    city: "Indore",
  },
  {
    name: "Khandwa Road",
    city: "Indore",
  },
  {
    name: "Bengali Square",
    city: "Indore",
  },
  {
    name: "Kanadia Road",
    city: "Indore",
  },
  {
    name: "Talawali Chanda",
    city: "Indore",
  },
  {
    name: "Rajendra Nagar",
    city: "Indore",
  },
  {
    name: "Silicon City",
    city: "Indore",
  },
  {
    name: "Pipliyahana",
    city: "Indore",
  },
  {
    name: "Kanadiya",
    city: "Indore",
  },
  {
    name: "Scheme No 54",
    city: "Indore",
  },
  {
    name: "Saket Nagar",
    city: "Indore",
  },
  {
    name: "Scheme No 114",
    city: "Indore",
  },
  {
    name: "Jhalaria",
    city: "Indore",
  },
  {
    name: "AB Road indore",
    city: "Indore",
  },
  {
    name: "Ida Scheme No 134",
    city: "Indore",
  },
  {
    name: "Devguradia",
    city: "Indore",
  },
  {
    name: "Tilak Nagar",
    city: "Indore",
  },
  {
    name: "Bijalpur",
    city: "Indore",
  },
  {
    name: "Bhawrasla",
    city: "Indore",
  },
  {
    name: "Bada Bangarda",
    city: "Indore",
  },
  {
    name: "Niranjanpur",
    city: "Indore",
  },
  {
    name: "Scheme No 78",
    city: "Indore",
  },
  {
    name: "Race Course Road",
    city: "Indore",
  },
  {
    name: "Old Palasia",
    city: "Indore",
  },
  {
    name: "MR 11",
    city: "Indore",
  },
  {
    name: "Nainod",
    city: "Indore",
  },
  {
    name: "Bicholi Hapsi",
    city: "Indore",
  },
  {
    name: "Kesar Bagh",
    city: "Indore",
  },
  {
    name: "Tejaji Nagar",
    city: "Indore",
  },
  {
    name: "Palakhedi",
    city: "Indore",
  },
  {
    name: "Scheme No 71",
    city: "Indore",
  },
  {
    name: "Sai Kripa Colony",
    city: "Indore",
  },
  {
    name: "Manorama Ganj",
    city: "Indore",
  },
  {
    name: "Scheme No 103",
    city: "Indore",
  },
  {
    name: "Gulab Bagh",
    city: "Indore",
  },
  {
    name: "Navlakha",
    city: "Indore",
  },
  {
    name: "Rau Pitampur Road",
    city: "Indore",
  },
  {
    name: "Rangwasa",
    city: "Indore",
  },
  {
    name: "Tigaria Badshah",
    city: "Indore",
  },
  {
    name: "Pipliya Kumar",
    city: "Indore",
  },
  {
    name: "LIG Colony",
    city: "Indore",
  },
  {
    name: "Luv Kush Square",
    city: "Indore",
  },
  {
    name: "Kalani Nagar",
    city: "Indore",
  },
  {
    name: "Magarkheda",
    city: "Indore",
  },
  {
    name: "Gumasta Nagar",
    city: "Indore",
  },
  {
    name: "South Tukoganj",
    city: "Indore",
  },
  {
    name: "Nanda Nagar",
    city: "Indore",
  },
  {
    name: "Scheme No 94",
    city: "Indore",
  },
  {
    name: "Sangam Nagar",
    city: "Indore",
  },

  {
    name: "Alok Nagar",
    city: "Indore",
  },
  {
    name: "Anurag Nagar",
    city: "Indore",
  },
  {
    name: "Snehlataganj",
    city: "Indore",
  },
  {
    name: "Sapna Sangeeta Road",
    city: "Indore",
  },
  {
    name: "Vasant Vihar",
    city: "Indore",
  },
  {
    name: "Mangaliya Sadak",
    city: "Indore",
  },
  {
    name: "Paliya",
    city: "Indore",
  },
  {
    name: "Anoop Nagar",
    city: "Indore",
  },
  {
    name: "Bangali Square",
    city: "Indore",
  },
  {
    name: "Arandia",
    city: "Indore",
  },
  {
    name: "Balya Kheda",
    city: "Indore",
  },
  {
    name: "Geeta Bhavan",
    city: "Indore",
  },
  {
    name: "Musakhedi",
    city: "Indore",
  },
  {
    name: "Mig Colony",
    city: "Indore",
  },
  {
    name: "Ring Road",
    city: "Indore",
  },
  {
    name: "Jakhiya",
    city: "Indore",
  },
  {
    name: "Khatiwala Tank",
    city: "Indore",
  },
  {
    name: "Palsikar Colony",
    city: "Indore",
  },
  {
    name: "Rajwada",
    city: "Indore",
  },
  {
    name: "Kordia Barda",
    city: "Indore",
  },
  {
    name: "Mundla Nayta",
    city: "Indore",
  },
  {
    name: "Pithampur Road",
    city: "Indore",
  },
  {
    name: "Bakhtawar Ram Nagar",
    city: "Indore",
  },
  {
    name: "Pigdambar",
    city: "Indore",
  },
  {
    name: "Rambag",
    city: "Indore",
  },
  {
    name: "Vishnupuri Colony",
    city: "Indore",
  },
  {
    name: "Shri Nagar Extension",
    city: "Indore",
  },
  {
    name: "Annapurna Nagar",
    city: "Indore",
  },
  {
    name: "Palasia",
    city: "Indore",
  },
  {
    name: "Budhaniya",
    city: "Indore",
  },
  {
    name: "Gokul Nagar",
    city: "Indore",
  },
  {
    name: "Morod",
    city: "Indore",
  },
  {
    name: "Sanchar Nagar Extention",
    city: "Indore",
  },
  {
    name: "NH3 Agra Mumbai Highway",
    city: "Indore",
  },
  {
    name: "Vallabh Nagar",
    city: "Indore",
  },
  {
    name: "Indrapuri Colony",
    city: "Indore",
  },
  {
    name: "Vaishali Nagar",
    city: "Indore",
  },
  {
    name: "Banganga",
    city: "Indore",
  },
  {
    name: "Shiv Dham Colony",
    city: "Indore",
  },
  {
    name: "Panda",
    city: "Indore",
  },
  {
    name: "Vandana Nagar",
    city: "Indore",
  },
  {
    name: "Sanawadia",
    city: "Indore",
  },
  {
    name: "Dhanvantri Nagar",
    city: "Indore",
  },
  {
    name: "Panchderiya",
    city: "Indore",
  },
  {
    name: "Sainath Colony",
    city: "Indore",
  },
  {
    name: "Tejpur Gadbadi",
    city: "Indore",
  },
  {
    name: "Sawer",
    city: "Indore",
  },
  {
    name: "Malviya Nagar",
    city: "Indore",
  },
  {
    name: "Malharganj",
    city: "Indore",
  },
  {
    name: "Chandan Nagar",
    city: "Indore",
  },
  {
    name: "Mahaveer Nagar",
    city: "Indore",
  },
  {
    name: "Barodiya Ema",
    city: "Indore",
  },
  {
    name: "Usha Nagar Extension",
    city: "Indore",
  },
  {
    name: "Nehru Nagar",
    city: "Indore",
  },
  {
    name: "Baliyakhedi",
    city: "Indore",
  },
  {
    name: "Sirpur",
    city: "Indore",
  },
  {
    name: "Choral",
    city: "Indore",
  },
  {
    name: "Green Park Colony",
    city: "Indore",
  },
  {
    name: "Scheme 54 PU4",
    city: "Indore",
  },
  {
    name: "Sindhi Colony",
    city: "Indore",
  },
  {
    name: "Karolbagh",
    city: "Indore",
  },
  {
    name: "Scheme no 53",
    city: "Indore",
  },
  {
    name: "New palasia",
    city: "Indore",
  },
  {
    name: "Scheme no 74",
    city: "Indore",
  },
  {
    name: "Chikitsak nagar",
    city: "Indore",
  },
  {
    name: "Veena nagar",
    city: "Indore",
  },
  {
    name: "Mansorawar nagar",
    city: "Indore",
  },
  {
    name: "Bapat square",
    city: "Indore",
  },
  {
    name: "Sukliya",
    city: "Indore",
  },
  {
    name: "Royal banglow",
    city: "Indore",
  },
  {
    name: "Dewas Naka",
    city: "Indore",
  },
  {
    name: "Industrial house",
    city: "Indore",
  },
  {
    name: "Khajrana",
    city: "Indore",
  },
  {
    name: "Atal dwar",
    city: "Indore",
  },
  {
    name: "Anand bazar",
    city: "Indore",
  },
  {
    name: "Super corridor",
    city: "Indore",
  },
  {
    name: "Lasudia mori",
    city: "Indore",
  },
  {
    name: "Scheme no 136",
    city: "Indore",
  },
  {
    name: "Scheme no 140",
    city: "Indore",
  },
  {
    name: "Hira nagar",
    city: "Indore",
  },
];

const HeaderSection = () => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedLocalities, setSelectedLocalities] = useState([]);
  console.log("selectedLocalities data is --------->", selectedLocalities);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setShowSuggestions(true);
  };

  const handleFilterData = () => {
    const filteredSuggestions = LocalityData.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    if (!selectedLocalities.includes(suggestion.name)) {
      setSelectedLocalities([...selectedLocalities, suggestion.name]);
    }
    setInputValue("");
    setShowPlaceholder(false); // Hide the placeholder when a locality is selected
    setShowSuggestions(false);
  };

  const handleClearInput = () => {
    setInputValue("");
    setShowSuggestions(false);
  };

  const removeLocality = (locality) => {
    setSelectedLocalities(selectedLocalities.filter((loc) => loc !== locality));
    if (selectedLocalities.length === 1) {
      setShowPlaceholder(true); // Show the placeholder if no localities are selected
    }
  };

  useEffect(() => {
    if (inputValue !== "") {
      handleFilterData();
    } else {
      setShowSuggestions(false);
    }
  }, [inputValue]);

  function refreshPage() {
    window.location.reload();
  }

  const handleRefresh = () => {
    setTimeout(() => {
      refreshPage();
    }, 600);
  };

  const handleInputClick = () => {
    // Navigate to the desired page when the input is clicked
    navigate("/responsiveproperties");
  };
  return (
    <section className="home-header position-relative pt-5 mb-lg-5">
      <Container className="overflow-hidden pt-5" fluid="true">
        <Row className="justify-content-center pt-md-5 pt-4">
          <Col md={12}>
            <Grid
              container
              spacing={2}
              fluid
              className="ps-lg-5 flex-lg-row flex-column-reverse justify-content-center"
            >
              <Grid
                item
                lg={6}
                sm={10}
                className="pt-lg-5 pt-md-4 pt-0 px-lg-4 px-5 text-center home-location-form-column position-relative"
              >
                <Typography
                  variant="h4"
                  component="h3"
                  className="font-bold position-relative largeHeading_TwoHeadings pb-3 mb-4"
                  style={{ color: "#fff" }}
                >
                  Har Pata, Humein Pata Hai.
                </Typography>
                <div className="tabs1_smallscreen">
                  <Tabs
                    defaultActiveKey="rent"
                    id="justify-tab-example"
                    className="home-header-location-form "
                    justify="flex-start"
                    style={{
                      paddingX: "5px",
                      borderRadius: "6px",
                      marginBlockEnd: "-1px",
                    }}
                  >
                    <Tab
                      eventKey="rent"
                      title="Rent Home"
                      className="home-header-location"
                    >
                      <Box>
                        <Paper
                          sx={{
                            p: "15px 23px 25px",
                            // display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            boxShadow: "none",
                            borderRadius: "0px 8px 8px 8px",
                            zIndex: 1,
                            position: "relative",
                            background: "#ed7225",
                          }}
                          className="home-header-searchBox"
                        >
                          <div style={{ textAlign: "left" }}>
                            <p style={{ color: "white", marginBottom: "8px" }}>
                              Location
                            </p>
                          </div>
                          <Box
                            className="search-locationBox-wrapper w-100"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <InputBase
                              sx={{
                                flex: 1,
                                backgroundColor: "#fff",
                                borderRadius: "6px",
                                color: "#222222",
                                padding: "8px",
                                marginRight: "8px", // Margin to space between input and button
                                display: "flex",
                                alignItems: "center",
                              }}
                              placeholder={
                                showPlaceholder
                                  ? "Search Location Example - Vijay Nagar"
                                  : ""
                              }
                              value={inputValue}
                              onChange={handleInputChange}
                              startAdornment={
                                <InputAdornment position="start">
                                  {selectedLocalities.map((locality, index) => (
                                    <Chip
                                      key={index}
                                      label={locality}
                                      onDelete={() => removeLocality(locality)}
                                      sx={{ margin: "2px" }}
                                    />
                                  ))}
                                </InputAdornment>
                              }
                              endAdornment={
                                inputValue && (
                                  <InputAdornment position="end">
                                    <IconButton
                                      edge="end"
                                      onClick={handleClearInput}
                                      sx={{ color: "#222222" }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }
                              className="locationBox"
                            />
                            <Link
                              to={
                                selectedLocalities.length > 0
                                  ? `/properties/${selectedLocalities.join(
                                      ","
                                    )}/rent`
                                  : "#"
                              }
                            >
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "#078b4c",
                                  color: "#FFF",
                                  padding: "10px 48px",
                                }}
                                className="search-button"
                              >
                                Search
                              </Button>
                            </Link>
                          </Box>
                        </Paper>
                      </Box>
                    </Tab>
                    <Tab
                      eventKey="buy"
                      title="Buy a Home"
                      className="home-header-location"
                    >
                      <Box>
                        <Paper
                          sx={{
                            p: "15px 23px 25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            boxShadow: "none",
                            borderRadius: "0px 8px 8px 8px",
                            zIndex: 1,
                            position: "relative",
                            background: "#ed7225",
                          }}
                          className="home-header-searchBox"
                        >
                          <Box
                            className="search-locationBox-wrapper w-100"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <InputBase
                              sx={{
                                flex: 1,
                                backgroundColor: "#fff",
                                borderRadius: "6px",
                                color: "#222222",
                                padding: "8px",
                                marginRight: "8px", // Margin to space between input and button
                                display: "flex",
                                alignItems: "center",
                              }}
                              placeholder={
                                showPlaceholder
                                  ? "Search Location Example - Vijay Nagar"
                                  : ""
                              }
                              value={inputValue}
                              onChange={handleInputChange}
                              startAdornment={
                                <InputAdornment position="start">
                                  {selectedLocalities.map((locality, index) => (
                                    <Chip
                                      key={index}
                                      label={locality}
                                      onDelete={() => removeLocality(locality)}
                                      sx={{ margin: "2px" }}
                                    />
                                  ))}
                                </InputAdornment>
                              }
                              endAdornment={
                                inputValue && (
                                  <InputAdornment position="end">
                                    <IconButton
                                      edge="end"
                                      onClick={handleClearInput}
                                      sx={{ color: "#222222" }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }
                              className="locationBox"
                            />
                            <Link
                              to={
                                selectedLocalities.length > 0
                                  ? `/properties/${selectedLocalities.join(
                                      ","
                                    )}/sell`
                                  : "#"
                              }
                            >
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "#078b4c",
                                  color: "#FFF",
                                  padding: "10px 48px",
                                  // backgroundColor: "black",
                                }}
                                className="search-button"
                              >
                                Search
                              </Button>
                            </Link>
                          </Box>
                        </Paper>
                      </Box>
                    </Tab>
                  </Tabs>
                  <Box>
                    {showSuggestions && (
                      <ul className="searchData-wrapper">
                        {suggestions.length > 0 ? (
                          suggestions.map((suggestion, index) => (
                            <li
                              style={{
                                textAlign: "start",
                                listStyle: "none",
                              }}
                              key={index}
                              onClick={() => handleSuggestionClick(suggestion)}
                            >
                              {suggestion.name}
                            </li>
                          ))
                        ) : (
                          <li style={{ textAlign: "start" }}>
                            No suggestions available
                          </li>
                        )}
                      </ul>
                    )}
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Col>
          {/* ****** for small screen ********* */}
          <div className="tabs_smallscreen container">
            <Tabs
              defaultActiveKey="rent"
              id="justify-tab-example"
              className="home-header-location-form "
              justify="flex-start"
              style={{
                paddingX: "5px",
                borderRadius: "6px",
                marginBlockEnd: "-1px",
              }}
            >
              <Tab
                eventKey="rent"
                title="Rent Home"
                className="home-header-location"
              >
                <Box>
                  <Paper
                    sx={{
                      p: "15px 23px 25px",
                      // display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      boxShadow: "none",
                      borderRadius: "0px 8px 8px 8px",
                      zIndex: 1,
                      position: "relative",
                      background: "#1F4069",
                    }}
                    className="home-header-searchBox"
                  >
                    <div style={{ textAlign: "left" }}>
                      <p style={{ color: "white", marginBottom: "8px" }}>
                        Location
                      </p>
                    </div>
                    <Box
                      className="search-locationBox-wrapper w-100"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <InputBase
                        sx={{
                          flex: 1,
                          backgroundColor: "#fff",
                          borderRadius: "6px",
                          color: "#222222",
                          padding: "8px",
                          marginRight: "8px", // Margin to space between input and button
                          display: "flex",
                          alignItems: "center",
                        }}
                        placeholder={
                          showPlaceholder
                            ? "Search Location Example - Vijay Nagar"
                            : ""
                        }
                        value={inputValue}
                        // onChange={handleInputChange}
                        onClick={handleInputClick}
                        startAdornment={
                          <InputAdornment position="start">
                            {selectedLocalities.map((locality, index) => (
                              <Chip
                                key={index}
                                label={locality}
                                onDelete={() => removeLocality(locality)}
                                sx={{ margin: "2px" }}
                              />
                            ))}
                          </InputAdornment>
                        }
                        endAdornment={
                          inputValue && (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={handleClearInput}
                                sx={{ color: "#222222" }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                        className="locationBox"
                      />
                    </Box>
                  </Paper>
                </Box>
              </Tab>
              <Tab
                eventKey="buy"
                title="Buy a Home"
                className="home-header-location"
              >
                <Box>
                  <Paper
                    sx={{
                      p: "15px 23px 25px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      boxShadow: "none",
                      borderRadius: "0px 8px 8px 8px",
                      zIndex: 1,
                      position: "relative",
                      background: "#1F4069",
                    }}
                    className="home-header-searchBox"
                  >
                    <Box
                      className="search-locationBox-wrapper w-100"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Link
                        to={
                          selectedLocalities.length > 0
                            ? `/properties/${selectedLocalities.join(",")}/sell`
                            : "#"
                        }
                      >
                        <InputBase
                          sx={{
                            flex: 1,
                            backgroundColor: "#fff",
                            borderRadius: "6px",
                            color: "#222222",
                            padding: "8px",
                            marginRight: "8px", // Margin to space between input and button
                            display: "flex",
                            alignItems: "center",
                          }}
                          placeholder={
                            showPlaceholder
                              ? "Search Location Example - Vijay Nagar"
                              : ""
                          }
                          value={inputValue}
                          onChange={handleInputChange}
                          startAdornment={
                            <InputAdornment position="start">
                              {selectedLocalities.map((locality, index) => (
                                <Chip
                                  key={index}
                                  label={locality}
                                  onDelete={() => removeLocality(locality)}
                                  sx={{ margin: "2px" }}
                                />
                              ))}
                            </InputAdornment>
                          }
                          endAdornment={
                            inputValue && (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  onClick={handleClearInput}
                                  sx={{ color: "#222222" }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }
                          className="locationBox"
                        />

                        {/* <Button
                          variant="contained"
                          style={{
                            backgroundColor: "rgba(2, 201, 136, 1)",
                            color: "#FFF",
                            padding: "10px 48px",
                            // backgroundColor: "black",
                          }}
                          className="search-button"
                        >
                          Search
                        </Button> */}
                      </Link>
                    </Box>
                  </Paper>
                </Box>
              </Tab>
            </Tabs>
            <Box>
              {showSuggestions && (
                <ul className="searchData-wrapper">
                  {suggestions.length > 0 ? (
                    suggestions.map((suggestion, index) => (
                      <li
                        style={{
                          textAlign: "start",
                          listStyle: "none",
                        }}
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion.name}
                      </li>
                    ))
                  ) : (
                    <li style={{ textAlign: "start" }}>
                      No suggestions available
                    </li>
                  )}
                </ul>
              )}
            </Box>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default HeaderSection;
