/** @format */

import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../LayoutWrapper/AdminLayout";
import { useEffect } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export default function RefundPolicy() {
  useEffect(() => {
    localStorage.setItem("activatedPage", "privacy-policy");
  }, []);

  return (
    <Layout>
      <div className="py-md-5 my-5 px-md-5 px-4">
        <Typography variant="h4" className="fw-semibold pt-5">
          Refund and Return Policy
        </Typography>
        <br />
        <Typography variant="p" className="text-start">
          At Bharat Makaan Technologies Pvt Ltd. ("Bharat Makaan," "we," "us,"
          or "our"), we are committed to providing exceptional service and
          ensuring customer satisfaction. Our refund and return policy is
          designed to provide clarity and transparency regarding the terms and
          conditions under which refunds and returns are accepted. Please read
          this policy carefully before making any purchases or engaging with our
          services. By accessing our website{" "}
          <Link to="https://gharaaj.com">www.BharatMakaan.com</Link> or using
          our services, you agree to comply with and be bound by the terms and
          conditions outlined in this policy.
        </Typography>
        <br />
        <br />
        <Typography variant="h6" className="fw-semibold">
          1. Scope and Applicability
        </Typography>
        <Typography variant="p" className="text-start">
          This refund and return policy applies to all transactions and
          interactions conducted through Bharat Makaan's online interfaces,
          including our website, mobile applications (iOS, Android, Windows),
          and any other digital platforms owned and operated by Bharat Makaan.
        </Typography>{" "}
        <br />
        <br />
        <Typography variant="h6" className="fw-semibold">
          2. Refund Policy
        </Typography>
        <br />
        <Typography variant="h6" className="fw-semibold">
          a. Token Amount/Booking Amount:
        </Typography>
        <Typography variant="p" className="text-start ">
          i. For Owners:
        </Typography>
        <br />
        <Typography variant="p" className="text-start ">
          <FiberManualRecordIcon sx={{ fontSize: "10px" }} /> Refunds for owners
          are contingent upon specific conditions:
        </Typography>
        <br />
        <Typography variant="p" className="text-start ">
          <FiberManualRecordIcon sx={{ fontSize: "10px" }} /> Completion of the
          360-degree photoshoot by our Relationship Manager.
        </Typography>
        <br />
        <Typography variant="p" className="text-start ">
          <FiberManualRecordIcon sx={{ fontSize: "10px" }} /> Completion of the
          property listing and sending of links to the owner.
        </Typography>
        <br />
        <Typography variant="p" className="text-start ">
          <FiberManualRecordIcon sx={{ fontSize: "10px" }} /> Conducting
          property visits by prospective tenants through our Relationship
          Manager.
        </Typography>
        <br />
        <Typography variant="p" className="text-start ">
          <FiberManualRecordIcon sx={{ fontSize: "10px" }} /> If our
          Relationship Manager fails to contact the owner within 7 days from the
          onboarding date or if the property listing is not completed within a
          week, the owner becomes eligible to apply for a refund.
        </Typography>
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          ii. For Tenants:
        </Typography>
        <br />
        <Typography variant="p" className="text-start ">
          <FiberManualRecordIcon sx={{ fontSize: "10px" }} /> Refunds for
          tenants are subject to certain conditions:
        </Typography>
        <br />
        <Typography variant="p" className="text-start ">
          <FiberManualRecordIcon sx={{ fontSize: "10px" }} /> Conducting
          property visits through our Relationship Manager.
        </Typography>
        <br />
        <Typography variant="p" className="text-start ">
          <FiberManualRecordIcon sx={{ fontSize: "10px" }} /> Engagement with
          brokers or third-party services for property searches.
        </Typography>
        <br />
        <Typography variant="p" className="text-start ">
          <FiberManualRecordIcon sx={{ fontSize: "10px" }} /> Viewing more than
          10 properties.
        </Typography>
        <br />
        <Typography variant="p" className="text-start ">
          <FiberManualRecordIcon sx={{ fontSize: "10px" }} /> Booking a property
          and failing to request cancellation on the same day.
        </Typography>
        <br />
        <Typography variant="p" className="text-start ">
          <FiberManualRecordIcon sx={{ fontSize: "10px" }} /> Changing one's
          mind for any reason after booking.
        </Typography>
        <br />
        <Typography variant="p" className="text-start ">
          <FiberManualRecordIcon sx={{ fontSize: "10px" }} /> If a tenant
          cancels the deal on the same day during working hours, a 100% refund
          of the token amount will be processed within an hour. However, if the
          cancellation is not made on the same day, the token amount can only be
          adjusted for the next property, and no refund will be provided.
        </Typography>
        <br />
        <Typography variant="p" className="text-start ">
          <FiberManualRecordIcon sx={{ fontSize: "10px" }} /> Once an agreement
          is made, onboarding fees will not be refunded.
        </Typography>
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          iii. Contact Information: For any queries or policy-related issues,
          tenants and owners can contact us via email at
          support@bharatmakaan.com or by calling 7314-701660.
        </Typography>
        <br />
        <br />
        <Typography variant="h6" className="fw-semibold">
          b. Onboarding Charges & Rent:
        </Typography>
        <Typography variant="p" className="text-start ">
          Onboarding charges, also known as brokerage fees, are subject to the
          following refund policy:
        </Typography>
        <br />
        <Typography variant="p" className="text-start">
          Non-refundable if the cancellation request is made more than 24 hours
          after the initial payment.
        </Typography>{" "}
        <br />
        <Typography variant="p" className="text-start">
          If the cancellation request is made within 24 hours of payment, a full
          refund of the onboarding charges will be issued.
        </Typography>{" "}
        <br />
        <Typography variant="p" className="text-start">
          If a tenant is successfully onboarded in a rental property and decides
          to cancel on the same day, a 100% refund of the onboarding charges
          will be provided.
        </Typography>{" "}
        <br />
        <Typography variant="p" className="text-start">
          For rent refunds, the following conditions apply:
        </Typography>{" "}
        <br />
        <Typography variant="p" className="text-start">
          If the cancellation request is made before the start date of the lease
          agreement, a 100% refund of the paid rent will be issued.
        </Typography>{" "}
        <br />
        <Typography variant="p" className="text-start">
          If the cancellation request is made after the start date of the lease
          agreement, the refund amount will be determined based on the number of
          days the tenant has occupied the property and the terms outlined in
          the lease agreement.
        </Typography>{" "}
        <br />
        <Typography variant="p" className="text-start">
          Any unpaid rent for the period during which the tenant occupied the
          property will be deducted from the refund amount.
        </Typography>{" "}
        <br />
        <Typography variant="p" className="text-start">
          The refund will be issued to the client within a specified timeframe,
          typically within [X] business days, once approved by the property
          owner.
        </Typography>{" "}
        <br />
        <br />
        <Typography variant="h6" className="fw-semibold">
          3. Return Policy
        </Typography>
        <Typography variant="p" className="text-start ">
          Our return policy pertains to products or physical items purchased
          through Bharat Makaan's online platforms. Since Bharat Makaan
          primarily deals with digital services, physical returns are not
          applicable in most cases. However, if a return request arises, it will
          be handled on a case-by-case basis, and customers are encouraged to
          contact our customer support team for assistance.
        </Typography>{" "}
        <br />
        <br />
        <Typography variant="h6" className="fw-semibold">
          4. Amendments to the Policy
        </Typography>
        <Typography variant="p" className="text-start ">
          This refund and return policy is subject to change at Bharat Makaan's
          sole discretion. Any modifications or updates to the policy will be
          communicated to users through our website or other appropriate
          channels. It is the user's responsibility to review the policy
          periodically for any changes.
        </Typography>{" "}
        <br />
        <br />
        <Typography variant="h6" className="fw-semibold">
          5. Contact Information
        </Typography>
        <Typography variant="p" className="text-start ">
          If you have any questions, comments, concerns, or feedback regarding
          our refund and return policy, please contact our Grievance Redressal
          Officer at <Link>data.privacy@BharatMakaan.com.</Link> We strive to
          address all inquiries and issues promptly and effectively.
        </Typography>{" "}
        <br /> <br />
        <Typography variant="h6" className="fw-semibold">
          Conclusion
        </Typography>
        <Typography variant="p" className="text-start ">
          By accessing our website or engaging with our services, you
          acknowledge and agree to abide by the terms and conditions outlined in
          this refund and return policy. We appreciate your trust in Bharat
          Makaan Technologies Pvt Ltd. and remain dedicated to providing a
          positive and satisfying experience for all our customers.
        </Typography>{" "}
      </div>
    </Layout>
  );
}
