import React, { useEffect, useState } from "react";
import { Button, Container, NavDropdown } from "react-bootstrap";
import "../../Pages/Home/home.css";
import styles from "../../Css/about.module.css";

import { Box, Grid, Typography } from "@mui/material";
import FreeConsultantImg from "../../Images/Home/post-property-with-gharaaj.webp";
import AudioConsultant from "../../Images/Home/audio-consultant.svg";
import VideoConsultant from "../../Images/Home/video-consultant.svg";
import MeetConsultant from "../../Images/Home/meet-consultant.svg";
import { Link } from "react-router-dom";

const PostPropertyWithGharaaj = () => {
  const [userId, setuserId] = useState();

  // Get Local Storage Data
  useEffect(() => {
    setuserId(localStorage.getItem("UserId"));
  }, []);
  return (
    <section className="post-property pt-3 mb-lg-0 mb-5">
      <Container maxWidth="100%" className="">
        <Grid
          container
          spacing={2}
          className="justify-content-center flex-lg-row flex-column flex-lg-nowrap pe-lg-5"
        >
          <Grid item lg={6} className="pt-0 pb-0 ms-lg-3">
            <Box className="post-property-benifits">
              <img src={FreeConsultantImg} />
            </Box>
          </Grid>
          <Grid
            item
            lg={6}
            className="py-0 d-flex align-items-start justify-content-md-start justify-content-center pe-lg-5 mt-lg-5 pt-lg-5"
          >
            <Box className="text-lg-start text-center pt-xxl-5 mt-xxl-5">
              <Typography
                variant="h5"
                component="h4"
                className="smallHeading_TwoHeadings mb-2"
                style={{ color: "#222222" }}
              >
                Sell or Rent your property faster with Bharat Makaan
              </Typography>
              <Box className="post-property-btn-wrapper mt-4 d-flex align-items-center justify-content-lg-start justify-content-center flex-wrap">
                <Link
                  to={
                    userId !== undefined && userId !== null
                      ? "/add-property"
                      : "/signup-login"
                  }
                >
                  <Button className="post-property-btn px-4 py-2 d-flex align-items-center">
                    Post Your Property{" "}
                    <span
                      style={{
                        color: "#2bec90",
                        fontSize: "18px",
                        paddingLeft: "5px",
                      }}
                      className="different-font"
                    >
                      {" "}
                      Free
                    </span>
                  </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default PostPropertyWithGharaaj;
