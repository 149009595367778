import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../../Css/about.module.css";
import propertimg from "../../Images/About/property.png";
import { Link } from "react-router-dom";
export default function Postproperty() {
  const [userId, setuserId] = useState();

  // Get Local Storage Data
  useEffect(() => {
    setuserId(localStorage.getItem("UserId"));
  }, []);
  return (
    <div>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          // paddingTop: "2rem",
          // paddingBottom: "3rem",
        }}
        className="py-md-5 py-3 "
      >
        <Grid
          container
          lg={10.8}
          className={styles.propert_outer_container_grid}
        >
          <Grid lg={6} xs={12}>
            <Typography className={styles.propert_text}>
              India's Only PropertyPortal Available Online & Offline
            </Typography>
          </Grid>
          <Grid
            lg={6}
            xs={12}
            sx={{ display: "flex", alignItems: "center" }}
            className="flex-row px-4"
          >
            <div>
              <img
                src={propertimg}
                alt="images"
                className={styles.propert_img}
              />
            </div>
            <div className={`${styles.propert_button_div}`}>
              <Link
                to={
                  userId !== undefined && userId !== null
                    ? "/add-property"
                    : "/signup-login"
                }
              >
                <Button className={styles.propert_button}>
                  Post Property{" "}
                  <span
                    style={{
                      color: "#2bec90",
                      fontSize: "18px",
                      paddingLeft: "5px",
                    }}
                    className="different-font"
                  >
                    {" "}
                    Free
                  </span>
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
