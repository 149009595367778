/** @format */

import { Box, Grid, OutlinedInput, Typography } from "@mui/material";
import React, { useState } from "react";
import "../../Pages/FAQ/index.css";
import { debounce } from "lodash";
import { getApihandler } from "../../Apihandler";
import { Link } from "react-router-dom";

const FaqBanner = () => {
  const [faqQueryText, setFAQQueryText] = useState("");
  const [faqFilteredData, setFAQFilteredData] = useState([]);
  const [showFAQSuggestions, setShowFAQSuggestions] = useState(false);

  const handleFAQSearchFilter = async (event) => {
    const value = event.target.value;
    const res = await getApihandler(`/searchFAQ/${value}`);
    // console.log("searchFAQ - ", res);
    setFAQFilteredData(res.data);
    setShowFAQSuggestions(true);
  };

  const handleFAQSearch = debounce((e) => {
    // console.log(" handleSocietyInputChange called - ", e);
    setFAQQueryText(e.target.value);
    handleFAQSearchFilter(e);
  }, 300);
  return (
    <div
      className={`faq-banner-wrapper my-5 py-md-4 py-1`}
      style={{ backgroundColor: "rgb(7, 139, 76)" }}
    >
      <Typography component="h1" className="text-white text-center mt-5">
        Hey, we are here to help you!
      </Typography>

      <Grid container className="justify-content-center mt-md-5 mt-3 mb-5">
        <Grid item lg={5} sm={8} xs={10}>
          <Box className="faq-search-wrapper position-relative">
            <OutlinedInput
              fullWidth
              type="text"
              id="search-faq"
              placeholder="Search"
              style={{ border: "1px solid #fff", backgroundColor: "#fff" }}
              // value={societyData}
              onChange={handleFAQSearch}
              // onChange={handleSocietyInputChange}
              // disabled={proprertyKeys === "list" ? false : true}
            />
            {showFAQSuggestions && (
              <ul
                className={`faq-searchData-wrapper d-${
                  faqQueryText.length !== 0 ? "block" : "none"
                }`}
              >
                {faqFilteredData !== undefined &&
                faqFilteredData !== null &&
                faqFilteredData.length !== 0 ? (
                  faqFilteredData.map((val, index) => (
                    <li
                      style={{ textAlign: "start", listStyle: "none" }}
                      key={index}
                    >
                      <Link
                        to={`/faq-details/${val._id}`}
                        className="text-dark"
                      >
                        {" "}
                        {val.title}
                      </Link>
                    </li>
                  ))
                ) : (
                  <li style={{ textAlign: "start", listStyle: "none" }}>
                    No Result Found
                  </li>
                )}
              </ul>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default FaqBanner;
