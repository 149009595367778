import React, { useContext, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import "../../Pages/Home/home.css";
import { Box, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import FavoriteIcon from "@mui/icons-material/Favorite";
import propertyOwner from "../../Images/Home/property-Owner.webp";
import { Link } from "react-router-dom";
import {
  getApihandler,
  imageUrl,
  postApihandler,
  putApihandler,
} from "../../Apihandler";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Swal from "sweetalert2";
import HeaderContext from "../../LayoutWrapper/ContextWrapper/context";
import Carousel from "react-elastic-carousel";

const NewArrivalProperty = () => {
  const { selectedValue } = useContext(HeaderContext);
  // console.log("selected city - ", selectedValue);
  const [PropertiesList, setPropertiesList] = useState([]);
  // console.log("new arrival PropertiesList - ", PropertiesList);
  const [propertyId, setPropertyId] = useState();
  const [userId, setUserId] = useState();
  const [cityFilter, setCityFilter] = useState(
    localStorage.getItem("cityName")
  );

  // Get Local Storage Data
  useEffect(() => {
    setUserId(localStorage.getItem("UserId"));
  }, []);

  // Context Data
  useEffect(() => {
    setCityFilter(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    if (cityFilter !== "") {
      getProperties();
    }
  }, [cityFilter]);

  useEffect(() => {
    if (propertyId !== undefined) {
      updateWatchlist();
    }
  }, [propertyId]);

  const getProperties = async () => {
    if (cityFilter === null) {
      // console.log("getNewArrivalProperty called-x-x-x-x-x-xx-x-x-x-x-x-x");
      const response = await getApihandler("/getNewArrivalProperty");
      setPropertiesList(response.data);
    } else {
      // console.log(
      //   "multipleFilterSearchOnProperty called-x-x-x-x-x-xx-x-x-x-x-x-x"
      // );

      const response = await getApihandler(
        `/multipleFilterSearchOnPropertyForAdmin?newArrival=1&city=${cityFilter}`
      );
      // console.log("multipleFilterSearchOnProperty List", response.data);
      setPropertiesList(response.data);
    }
  };

  const updateWatchlist = async () => {
    const response = await postApihandler(
      `/addPropertyToWishList/${userId}/${propertyId}`
    );
    // console.log("updateWatchlist====>", response);
    if (response.status === 200) {
      setPropertyId();
      Swal.fire({
        position: "middle-centre",
        icon: "success",
        title: "Wishlist Added",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      setPropertyId();

      Swal.fire({
        position: "middle-centre",
        icon: "error",
        title: response.error.response.data.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];
  return (
    <section
      className="property-listing pb-lg-4"
      style={{ overflow: "hidden" }}
    >
      {/* <Container fluid className="pt-5 ps-lg-5 pe-0"> */}
      <Container
        maxWidth="xxl"
        className="pt-lg-5  ps-md-4 pe-2  py-3 property-wrapper-spacing"
      >
        <Grid
          container
          spacing={2}
          // className="justify-content-lg-start justify-content-between align-items-start flex-xl-row flex-column flex-lg-nowrap"
          className="justify-content-lg-start justify-content-between align-items-start flex-row flex-lg-nowrap"
        >
          <Grid
            item
            lg={2.8}
            xs={12}
            style={{
              backgroundColor: "#ed7225",
              borderRadius: "12px",
            }}
            className="properties-Box py-xl-5 py-3 d-flex flex-column justify-content-center align-items-xl-start align-items-center properties-offerBox mt-lg-0 mt-4 ms-lg-0 ms-md-2 ms-3 me-3 me-md-0 mb-0 mb-4"
          >
            <Box
              className="px-3 d-xl-block d-flex justify-content-around align-items-center w-100"
              style={{ columnGap: "20px" }}
            >
              <Typography
                variant="h4"
                component="h3"
                style={{
                  color: "white",
                }}
                className="different-font home-exploreBox-title"
              >
                Explore
              </Typography>
              <Typography
                variant="h5"
                component="h4"
                // className="smallHeading_TwoHeadings"
                className="mt-md-3 mt-2 home-exploreBox-subtitle"
                sx={{
                  color: "#000",
                }}
              >
                New Arrival
                <br /> Properties in
              </Typography>
              <Typography
                variant="h4"
                component="h3"
                // className="font-bold whiteUnderline-text underline-text position-relative home-exploreBox-city"
                className="font-bold  position-relative home-exploreBox-city"
                sx={{
                  fontSize: { sm: "36px", xs: "20px" },
                  lineHeight: "41px",
                  fontweight: 600,
                  zIndex: 1,
                  color: "#000",
                }}
              >
                Indore
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={9} xs={12} className="newArrival-carausel-wrapper">
            {PropertiesList !== undefined && PropertiesList !== null ? (
              <Carousel breakPoints={breakPoints}>
                {PropertiesList !== undefined &&
                  PropertiesList !== null &&
                  PropertiesList.map((val, ind) => {
                    return (
                      <Grid
                        item
                        lg={11}
                        xs={12}
                        className={`properties-Box py-lg-0 mb-0 mb-4 `}
                        key={ind}
                      >
                        <Link
                          to={`property-details/${val._id}`}
                          style={{ color: "#222222" }}
                        >
                          <Card
                            sx={{ maxWidth: 345 }}
                            style={{
                              boxShadow: "0px 4px 30px 0px #E3DAEF",
                              borderRadius: "12px",
                            }}
                            className="mx-auto pb-2 newArrival-Box"
                          >
                            <div className="card-mediaWrapper position-relative">
                              <div className="cardImg-wrapper">
                                <CardMedia
                                  component="img"
                                  // height="130px"
                                  image={`${
                                    val.propertyImages.length !== 0 &&
                                    imageUrl + val.propertyImages[0].images
                                  }`}
                                  alt="Paella dish"
                                  style={{
                                    height: "150px",
                                  }}
                                />
                              </div>

                              <div
                                className={`premiumProperty-wrapper position-absolute p-2 d-${
                                  val.price > 50000 ? "block" : "none"
                                }`}
                              >
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: "#EA3A91",
                                    color: "#fff",
                                    borderRadius: 0,
                                  }}
                                  className="px-1 py-1"
                                >
                                  Premium
                                </Button>
                              </div>
                              <div className="price-wrapper position-absolute bottom-0 rounded-10 p-2">
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: "#fff",
                                  }}
                                  className="px-4 py-2"
                                >
                                  <Link
                                    to={`property-details/${val._id}`}
                                    style={{ color: "#222222" }}
                                  >
                                    ₹ {val.price}
                                  </Link>
                                </Button>
                              </div>
                            </div>
                            <CardContent
                              className="pb-3"
                              // style={{ borderBottom: "1px solid #EEEEEE" }}
                            >
                              <Typography
                                variant="subtitle1"
                                component="h5"
                                className="font-bold"
                              >
                                <Link
                                  to={`property-details/${val._id}`}
                                  style={{ color: "#222222" }}
                                >
                                  {val.propertyName}
                                </Link>
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                component="h5"
                                style={{
                                  color: "#555555",
                                  fontWeight: 400,
                                }}
                                className="d-md-block d-none"
                              >
                                {val.address + ", " + val.city}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                component="h5"
                                style={{
                                  color: "#222222",
                                  fontWeight: 400,
                                  marginTop: "10px",
                                }}
                                className="d-md-block d-none"
                              >
                                {val.bhk_of_house} - Independend
                              </Typography>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                    );
                  })}

                {/* {items.map((item) => (
              <div key={item.id}>{item.title}</div>
            ))} */}
              </Carousel>
            ) : (
              <h6 className="text-center"> No Result Found</h6>
            )}
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default NewArrivalProperty;
