import React from "react";
import { Container } from "react-bootstrap";
import "../../Pages/Home/home.css";
import { Box, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import HomeFamilies from "../../Images/Home/Gharaaj-Help1.svg";
import HomeFriends from "../../Images/Home/Gharaaj-Help2.svg";
import HomeSingle from "../../Images/Home/Gharaaj-Help3.svg";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";

const GharaajHelp = () => {
  // Page Refresh
  function refreshPage() {
    window.location.reload();
  }
  // Handle Page Refresh-------------
  const handleRefresh = () => {
    setTimeout(() => {
      refreshPage();
    }, 200);
  };
  return (
    <section className="gharaaj-help-wrapper pt-md-5 pb-5 pt-4">
      {/* <Container fluid className="pt-4">
       */}
      <Container maxWidth="100%" className="py-lg-4 py-0">
        <Typography
          variant="h5"
          component="h4"
          className="smallHeading_TwoHeadings text-center text-white mb-lg-5 mb-md-4"
        >
          See How Bharat Makaan Can Help
        </Typography>
        {/* Desktop======================================== */}
        <Grid
          container
          spacing={2}
          className="justify-content-center px-md-5 d-flex pt-md-0 pt-4"
        >
          <Grid
            item
            className="pt-lg-0 pb-lg-0 pt-4 pb-0 px-lg-3 mx-md-2 gharaaj-helpboxWrapper"
            lg={3.8}
            sm={10}
            xs={12}
          >
            <Card
              className="mx-auto"
              style={{
                backgroundColor: "#fff",
                boxShadow: "none",
                color: "#1F4069",
                border: "1px solid #D9D9D9",
                boxShadow: "4px 4px 30px 0px #0000000D",
                padding: "25px 10px",
              }}
            >
              <div className="families gharaaj-help-block position-relative">
                <Grid container className="justify-content-center">
                  <Grid item xs={7}>
                    <div className="gharaaj-help-imgwrapper">
                      <img src={HomeFamilies} alt="" />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <CardContent className=" pb-0 px-0">
                <Typography
                  variant="subtitle1"
                  component="h5"
                  className="gharaaj-help-title"
                  style={{
                    fontSize: "24px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Rental Furniture
                </Typography>
              </CardContent>
              <Box className="pt-lg-4 pt-4 pb-2">
                <Typography
                  variant="subtitle2"
                  component="h5"
                  style={{
                    color: "#00C0FB",
                    fontWeight: 400,
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  <Link
                    to={`https://gharaajrentalfurniture.godaddysites.com/`}
                    onClick={handleRefresh}
                    target="_blank"
                  >
                    <span
                      style={{
                        padding: "10px 40px",
                        color: "#fff",
                        background: "#078b4c",
                        borderRadius: "4px",
                      }}
                    >
                      View More
                    </span>
                  </Link>
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid
            item
            className="pt-lg-0 pb-lg-0 pt-4 pb-0 px-lg-3 mx-md-2 gharaaj-helpboxWrapper"
            lg={3.8}
            sm={10}
            xs={12}
          >
            <Card
              className="mx-auto"
              style={{
                backgroundColor: "#fff",
                boxShadow: "none",
                color: "#1F4069",
                border: "1px solid #D9D9D9",
                boxShadow: "4px 4px 30px 0px #0000000D",
                padding: "25px 10px",
              }}
            >
              <div className="friends gharaaj-help-block position-relative">
                <Grid container className="justify-content-center">
                  <Grid item xs={7}>
                    <div className="gharaaj-help-imgwrapper">
                      <img src={HomeFriends} alt="" />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <CardContent className=" pb-0 px-0">
                <Typography
                  variant="subtitle1"
                  component="h5"
                  className="gharaaj-help-title"
                  style={{
                    fontSize: "24px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Tenant Plan
                </Typography>
              </CardContent>
              <Box className="pt-lg-4 pt-4 pb-2">
                <Typography
                  variant="subtitle2"
                  component="h5"
                  style={{
                    color: "#00C0FB",
                    fontWeight: 400,
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  <Link
                    to={`/tenant-plan`}
                    // onClick={handleRefresh}
                  >
                    <span
                      style={{
                        padding: "10px 40px",
                        color: "#fff",
                        background: "#078b4c",
                        borderRadius: "4px",
                      }}
                    >
                      View More
                    </span>
                  </Link>
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid
            item
            className="pt-lg-0 pb-lg-0 pt-4 pb-0 px-lg-3 mx-md-2 gharaaj-helpboxWrapper"
            lg={3.8}
            sm={10}
            xs={12}
          >
            <Card
              className="mx-auto"
              style={{
                backgroundColor: "#fff",
                boxShadow: "none",
                color: "#1F4069",
                border: "1px solid #D9D9D9",
                boxShadow: "4px 4px 30px 0px #0000000D",
                padding: "25px 10px",
              }}
            >
              <div className="personal gharaaj-help-block position-relative">
                <Grid container className="justify-content-center">
                  <Grid item xs={7}>
                    <div className="gharaaj-help-imgwrapper">
                      <img src={HomeSingle} alt="" />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <CardContent className=" pb-0 px-0">
                <Typography
                  variant="subtitle1"
                  component="h5"
                  className="gharaaj-help-title"
                  style={{
                    fontSize: "24px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Owner Plan
                </Typography>
              </CardContent>
              <Box className="pt-lg-4 pt-4 pb-2">
                <Typography
                  variant="subtitle2"
                  component="h5"
                  style={{
                    color: "#00C0FB",
                    fontWeight: 400,
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  <Link
                    to={`/owner-plan`}
                    // onClick={handleRefresh}
                  >
                    <span
                      style={{
                        padding: "10px 40px",
                        color: "#fff",
                        background: "#078b4c",
                        borderRadius: "4px",
                      }}
                    >
                      View More
                    </span>
                  </Link>
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
        {/* Mobile=============================================== */}
        {/* <Grid className="hide-slider-controls d-md-none d-block ">
          <Carousel>
            <Carousel.Item interval={3000 + 1 * 1000} key={1}>
              <Grid
                item
                className="pt-lg-0 pb-lg-0 pt-4 pb-0 px-lg-3 mx-md-2 gharaaj-helpboxWrapper"
                xs={11}
              >
                <Card
                  className="mx-auto"
                  style={{
                    backgroundColor: "#fff",
                    boxShadow: "none",
                    color: "#1F4069",
                    border: "1px solid #D9D9D9",
                    boxShadow: "4px 4px 30px 0px #0000000D",
                    padding: "25px 10px",
                  }}
                >
                  <div className="families gharaaj-help-block position-relative">
                    <Grid container className="justify-content-center">
                      <Grid item xs={7}>
                        <div className="gharaaj-help-imgwrapper">
                          <img src={HomeFamilies} alt="" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <CardContent className=" pb-0 px-0">
                    <Typography
                      variant="subtitle1"
                      component="h5"
                      className="gharaaj-help-title"
                      style={{
                        fontSize: "24px",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                    >
                      Rental Furniture
                    </Typography>
                  </CardContent>
                  <Box className="pt-lg-4 pt-4 pb-2">
                    <Typography
                      variant="subtitle2"
                      component="h5"
                      style={{
                        color: "#00C0FB",
                        fontWeight: 400,
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                    >
                      <Link
                        to={`https://gharaajrentalfurniture.godaddysites.com/`}
                        onClick={handleRefresh}
                        target="_blank"
                      >
                        <span
                          style={{
                            padding: "10px 40px",
                            color: "#fff",
                            background: "#1F4069",
                            borderRadius: "4px",
                          }}
                        >
                          View Mores
                        </span>
                      </Link>
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            </Carousel.Item>
            <Carousel.Item interval={3000 + 1 * 1000} key={1}>
              <Grid
                item
                className="pt-lg-0 pb-lg-0 pt-4 pb-0 px-lg-3 mx-md-2 mt-xl-0 gharaaj-helpboxWrapper"
                xs={12}
              >
                <Card
                  className="mx-auto"
                  style={{
                    backgroundColor: "#fff",
                    boxShadow: "none",
                    color: "#1F4069",
                    border: "1px solid #D9D9D9",
                    boxShadow: "4px 4px 30px 0px #0000000D",
                    padding: "25px 10px",
                  }}
                >
                  <div className="friends gharaaj-help-block position-relative">
                    <Grid container className="justify-content-center">
                      <Grid item xs={7}>
                        <div className="gharaaj-help-imgwrapper">
                          <img src={HomeFriends} alt="" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <CardContent className=" pb-0 px-0">
                    <Typography
                      variant="subtitle1"
                      component="h5"
                      className="gharaaj-help-title"
                      style={{
                        fontSize: "24px",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                    >
                      Tenant Plan
                    </Typography>
                  </CardContent>
                  <Box className="pt-lg-4 pt-4 pb-2">
                    <Typography
                      variant="subtitle2"
                      component="h5"
                      style={{
                        color: "#00C0FB",
                        fontWeight: 400,
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                    >
                      <Link
                        to={`/tenant-plan`}
                        // onClick={handleRefresh}
                      >
                        <span
                          style={{
                            padding: "10px 40px",
                            color: "#fff",
                            background: "#1F4069",
                            borderRadius: "4px",
                          }}
                        >
                          View More
                        </span>
                      </Link>
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            </Carousel.Item>
            <Carousel.Item interval={3000 + 1 * 1000} key={1}>
              <Grid
                item
                className="pt-lg-0 pb-lg-0 pt-4 pb-0 px-lg-3 mx-md-2 mt-xl-0 gharaaj-helpboxWrapper"
                xs={12}
              >
                <Card
                  className="mx-auto"
                  style={{
                    backgroundColor: "#fff",
                    boxShadow: "none",
                    color: "#1F4069",
                    border: "1px solid #D9D9D9",
                    boxShadow: "4px 4px 30px 0px #0000000D",
                    padding: "25px 10px",
                  }}
                >
                  <div className="personal gharaaj-help-block position-relative">
                    <Grid container className="justify-content-center">
                      <Grid item xs={7}>
                        <div className="gharaaj-help-imgwrapper">
                          <img src={HomeSingle} alt="" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <CardContent className=" pb-0 px-0">
                    <Typography
                      variant="subtitle1"
                      component="h5"
                      className="gharaaj-help-title"
                      style={{
                        fontSize: "24px",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                    >
                      Owner Plan
                    </Typography>
                  </CardContent>
                  <Box className="pt-lg-4 pt-4 pb-2">
                    <Typography
                      variant="subtitle2"
                      component="h5"
                      style={{
                        color: "#00C0FB",
                        fontWeight: 400,
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                    >
                      <Link
                        to={`/owner-plan`}
                        // onClick={handleRefresh}
                      >
                        <span
                          style={{
                            padding: "10px 40px",
                            color: "#fff",
                            background: "#1F4069",
                            borderRadius: "4px",
                          }}
                        >
                          View More
                        </span>
                      </Link>
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            </Carousel.Item>
          </Carousel>
        </Grid> */}
      </Container>
    </section>
  );
};

export default GharaajHelp;
