/** @format */

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Chip,
  Container,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Tabs,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ShareIcon from "@mui/icons-material/Share";
import styles from "../../Css/property.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate, useParams } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useForm } from "react-hook-form";
import { getApihandler, imageUrl, postApihandler } from "../../Apihandler";
import Backdrop from "@mui/material/Backdrop";
// import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
  LocalizationProvider,
  StaticDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Carousel from "react-bootstrap/Carousel";
import ClearIcon from "@mui/icons-material/Clear";
import { RWebShare } from "react-web-share";
import Swal from "sweetalert2";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import HeaderContext from "../../LayoutWrapper/ContextWrapper/context";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import "../../Pages/Property/index.css";
import Logo from "../../Images/Universal/gharaaj-logo.svg";
import Drawer from "@mui/material/Drawer";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTheme } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ArrowCircleLeftSharpIcon from "@mui/icons-material/ArrowCircleLeftSharp";
import ArrowCircleRightSharpIcon from "@mui/icons-material/ArrowCircleRightSharp";
import debounce from "lodash/debounce";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export default function BedCard() {
  const navigate = useNavigate();
  const [currentUrl, setCurrentUrl] = useState("");
  const [alreadyFiltered, setAlreadyFiltered] = useState(false);
  // console.log("alreadyFiltered - ", alreadyFiltered);

  // Locality And Property Type Values Through Params-----------
  const { proprertyKeys, propertyType } = useParams();
  // console.log("hello baby console lgao", proprertyKeys, propertyType);

  const descriptionElementRef = React.useRef(null);
  const [pageCount, setPageCount] = React.useState(0);
  const [completeResp, setCompleteResp] = React.useState();
  const [value5, setValue5] = React.useState("1");
  const [openM, setOpenM] = React.useState(false);
  const { selectedValue } = useContext(HeaderContext);
  const [loaderHide, setloaderHide] = useState(false);
  const [filtersActivated, setFiltersActivated] = useState(false);

  // Mobile Filter Drawer ----------
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  // Availabe From--------
  // Get today's date in the format "YYYY-MM-DD"
  const today = new Date().toISOString().split("T")[0];

  // Initialize the selectedDate state with an empty string
  const [selectedDate, setSelectedDate] = useState("");

  // Range Slider --------
  const [areaRange, setAreaRange] = useState([0, 20000]); // Initial areaRange values
  const [minArea, maxArea] = areaRange;
  // console.log("areaRange - ", areaRange);

  const [rentBudgetRange, setRentBudgetRange] = useState([0, 500000]); // Initial Budget Range values
  const [minRentBudget, maxRentBudget] = rentBudgetRange;
  // console.log("rentBudgetRange - ", rentBudgetRange);
  const [sellBudgetRange, setSellBudgetRange] = useState([0, 50000000]); // Initial Budget Range values
  const [minSellBudget, maxSellBudget] = sellBudgetRange;
  // console.log("sellBudgetRange - ", sellBudgetRange);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [propertyTypePageFilter, setPropertyTypePageFilter] = useState("");
  // console.log("propertyTypePageFilter - ", propertyTypePageFilter);
  const [disableParamsPropertyType, setDisableParamsPropertyType] =
    useState(false);

  // console.log("propertyTypePageFilter -", propertyTypePageFilter);
  const [buildupTypeFilter, setBuildupTypeFilter] = useState("");
  const [tenantTypeFilter, setTenantTypeFilter] = useState("");
  const [lowestAgeFilter, setLowestAgeFilter] = useState();
  const [highestAgeFilter, setHighestAgeFilter] = useState();
  const [furnishedFilter, setfurnishedFilter] = useState("");
  const [verifiedPropertiesFilter, setVerifiedPropertiesFilter] = useState("");
  const [cityFilter, setCityFilter] = useState(
    localStorage.getItem("cityName")
  );
  const [societyData, setSocietyData] = useState("");
  // console.log("societyData - ", societyData);
  const [addressFilter, setAddressFilter] = useState("");
  console.log("address filter is", addressFilter);
  // const [societyFilter, setSocietyFilter] = useState("");
  const [withownerFilter, setWithownerFilter] = useState("");
  const [independentPropertiesFilter, setIndependentPropertiesFilter] =
    useState("");

  const [bedroomsFilter, setBedroomsFilter] = useState("");
  const [bathroomsFilter, setBathroomsFilter] = useState();
  const [propertiesWithPhotoFilter, setPropertiesWithPhotoFilter] =
    useState("");
  const [premiumFilter, setPremiumFilter] = useState("");
  const [constructionStatusFilter, setConstructionStatusFilter] = useState("");
  const [postedByFilter, setPostedByFilter] = useState("");
  const [purchaseTypeFilter, setPurchaseTypeFilter] = useState("");
  const [RERAApprovedFilter, setRERAApprovedFilter] = useState("");
  const [facingFilter, setFacingFilter] = useState("");
  const [campusTypeFilter, setCampusTypeFilter] = useState("");

  const [userId, setUserId] = useState();
  const [propertyId, setPropertyId] = useState();
  const [propertyIdFilterStatus, setPropertyIdFilterStatus] = useState(false);
  const [PropertiesList, setPropertiesList] = useState([]);
  const [propertyIdFilter, setPropertyIdFilter] = React.useState("");
  // console.log("propertyIdFilter - ", propertyIdFilter);
  const [watchlistStatus, setWatchlistStatus] = useState(false);

  // Locality Search Values--------------------------
  const [localitiesData, setLocalitiesData] = useState("");

  const [inputValue, setInputValue] = useState("");
  const [scroll, setScroll] = React.useState("paper");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestionsBox, setShowSuggestionsBox] = useState(true);

  const [showSuggestions, setShowSuggestions] = useState(false);

  // Society Search Values------------------------------------
  const [societyFilteredData, setSocietyFilteredData] = useState([]);
  const [showSocietySuggestions, setShowSocietySuggestions] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setShowSuggestionsBox(true);
  };

  const handleFilterData = () => {
    // Filter suggestions based on the input value
    const filteredSuggestions = LocalityData.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion, e) => {
    setShowSuggestionsBox(false);
    setShowSuggestions(false);
    setInputValue(suggestion.name);

    if (e.target.checked === true) {
      setLocalitiesData([...localitiesData, suggestion.name]);
    }
    if (e.target.checked === false) {
      const updatedArray = localitiesData.filter(
        (item) => item !== suggestion.name
      );
      setLocalitiesData(updatedArray);
      // console.log("Removed");
    }
  };

  useEffect(() => {
    if (inputValue !== "" && showSuggestionsBox) {
      handleFilterData();
    } else if (inputValue === "" || !showSuggestionsBox) {
      setShowSuggestions(false);
    }
  }, [inputValue]);

  // /Locality Search Values----------------

  React.useEffect(() => {
    if (openM) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openM]);

  const handleClickOpen1 = (scrollType) => () => {
    setOpenM(true);
    setScroll(scrollType);
  };

  const handleClose1 = () => {
    setOpenM(false);
  };

  const handleChange5 = (event, newValue) => {
    setValue5(newValue);
  };

  const handleSocietySuggestionClick = (value) => {
    setShowSocietySuggestions(false);
    // setShowSuggestionsBox1(false);
    setSocietyData(value);
  };

  const handleSocietyFilter = async (event) => {
    // setSocietyData(value);

    const value = event.target.value;
    // console.log("society input val -", value);

    const res = await getApihandler(`/getSocietyList/${value}`);
    // console.log("res---->", res);
    setSocietyFilteredData(res.data);
    setShowSocietySuggestions(true);
    // setShowSuggestionsBox1(true);
  };

  const handleIdInputChange = debounce((e) => {
    setPropertyIdFilter(e.target.value);
    setPropertyIdFilterStatus(true);
  }, 300);

  const handleSocietyInputChange = debounce((e) => {
    // console.log(" handleSocietyInputChange called - ", e);
    setSocietyData(e.target.value);
    handleSocietyFilter(e);
  }, 300);

  const handleRentLowBudgetInputChange = debounce((e) => {
    setRentBudgetRange([e.target.value, rentBudgetRange[1]]);
  }, 300);

  const handleRentHighBudgetInputChange = debounce((e) => {
    setRentBudgetRange([rentBudgetRange[0], e.target.value]);
  }, 300);

  const handleSellLowBudgetInputChange = debounce((e) => {
    setSellBudgetRange([e.target.value, rentBudgetRange[1]]);
  }, 300);

  const handleSellHighBudgetInputChange = debounce((e) => {
    setSellBudgetRange([rentBudgetRange[0], e.target.value]);
  }, 300);

  const handleLowAreaInputChange = debounce((e) => {
    setAreaRange([e.target.value, areaRange[1]]);
  }, 300);

  const handleHighAreaInputChange = debounce((e) => {
    setAreaRange([areaRange[0], e.target.value]);
  }, 300);

  const GetPropertyById = async () => {
    if (
      propertyIdFilter !== undefined &&
      propertyIdFilter !== null &&
      propertyIdFilter.length > 23
    ) {
      // console.log("GetPropertyById Searched true");
      const res = await getApihandler(`/getProperties/id_${propertyIdFilter}`);
      // console.log("GetPropertyById resp - ", res);
      if (res.status === 200) {
        setPropertiesList(res.data);
        // setPropertyIdFilterShow(true);
        setCompleteResp(res);
      } else {
        // filteredProperties();
        setPropertiesList([]);
        // setPropertyIdFilterShow(false);
      }
    } else {
      // console.log("GetPropertyById Searched false");

      Swal.fire({
        icon: "error",
        title: "Please Enter Correct Property Id ",
        showConfirmButton: false,
        timer: 2000,
      });
      filteredProperties();
    }
  };

  useEffect(() => {
    setCityFilter(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    if (proprertyKeys !== "list") {
      setAddressFilter(proprertyKeys);
      if (addressFilter !== "") {
        filteredProperties();
      }
    }
  }, [proprertyKeys]);

  useEffect(() => {
    filteredProperties();
    setFiltersActivated(true);
    toggleDrawer();
    // console.log(
    //   "api url is - ",
    //   `/multipleFilterSearchOnProperty/${pageCount}/20/?propertyType=${
    //     propertyTypePageFilter !== null &&
    //     propertyTypePageFilter !== undefined &&
    //     propertyTypePageFilter.length !== 0
    //       ? propertyTypePageFilter
    //       : propertyTypePageFilter.length === 0 && propertyType !== "all"
    //       ? propertyType
    //       : ""
    //   }&addressStr=${
    //     localitiesData !== null &&
    //     localitiesData !== undefined &&
    //     localitiesData.length !== 0
    //       ? localitiesData
    //       : localitiesData.length === 0 && proprertyKeys !== "list"
    //       ? proprertyKeys
    //       : ""
    //   }&minPrice=${
    //     propertyType === "sell" || propertyTypePageFilter === "Sell"
    //       ? sellBudgetRange[0]
    //       : rentBudgetRange[0]
    //   }&maxPrice=${
    //     propertyType === "sell" || propertyTypePageFilter === "Sell"
    //       ? sellBudgetRange[1]
    //       : rentBudgetRange[1]
    //   }&furnishing=${furnishedFilter}&available_for=${tenantTypeFilter}&bhk_of_house=${bedroomsFilter}&city=${
    //     cityFilter !== null && cityFilter !== undefined ? cityFilter : ""
    //   }&buildUp_type=${buildupTypeFilter}&verify_property=${
    //     verifiedPropertiesFilter === true
    //       ? 1
    //       : verifiedPropertiesFilter === false
    //       ? ""
    //       : ""
    //   }&minAge=${lowestAgeFilter === undefined ? "" : lowestAgeFilter}&maxAge=${
    //     highestAgeFilter === undefined ? "" : highestAgeFilter
    //   }&societyStr=${societyData}&owner_availability=${
    //     withownerFilter !== null &&
    //     withownerFilter !== undefined &&
    //     withownerFilter !== ""
    //       ? withownerFilter
    //       : ""
    //   }&independent=${
    //     independentPropertiesFilter === true
    //       ? 1
    //       : independentPropertiesFilter === false
    //       ? ""
    //       : ""
    //   }&bathrooms=${
    //     bathroomsFilter !== null && bathroomsFilter !== undefined
    //       ? bathroomsFilter
    //       : ""
    //   }&propertyImages=${
    //     propertiesWithPhotoFilter === true
    //       ? true
    //       : propertiesWithPhotoFilter === false
    //       ? ""
    //       : ""
    //   }&minArea=${areaRange[0]}&maxArea=${areaRange[1]}&construction_status=${
    //     constructionStatusFilter !== null &&
    //     constructionStatusFilter !== undefined &&
    //     constructionStatusFilter !== ""
    //       ? constructionStatusFilter
    //       : ""
    //   }&posted_by=${
    //     postedByFilter !== null &&
    //     postedByFilter !== undefined &&
    //     postedByFilter !== ""
    //       ? postedByFilter
    //       : ""
    //   }&purchase_type=${
    //     purchaseTypeFilter !== null &&
    //     purchaseTypeFilter !== undefined &&
    //     purchaseTypeFilter !== ""
    //       ? purchaseTypeFilter
    //       : ""
    //   }&rera_approved=${
    //     RERAApprovedFilter !== null &&
    //     RERAApprovedFilter !== undefined &&
    //     RERAApprovedFilter !== ""
    //       ? RERAApprovedFilter
    //       : ""
    //   }&facing=${
    //     facingFilter !== null &&
    //     facingFilter !== undefined &&
    //     facingFilter !== ""
    //       ? facingFilter
    //       : ""
    //   }&startDate=${selectedDate}&premium=${
    //     premiumFilter === true ? 1 : premiumFilter === false ? "" : ""
    //   }&campus_type=${
    //     campusTypeFilter !== null &&
    //     campusTypeFilter !== undefined &&
    //     campusTypeFilter !== ""
    //       ? campusTypeFilter
    //       : ""
    //   }`
    // );
  }, [alreadyFiltered]);

  // Get Local Storage Data
  useEffect(() => {
    setUserId(localStorage.getItem("UserId"));
  }, []);

  // Get URL And Values-x-x-x-x-x-x-x-x-x-x-x
  useEffect(() => {
    const url = window.location.href;
    const hash = url.split("#")[1];
    const regex = /([^?=&]+)(=([^&]*))?/g;
    let match;
    const hashParams = {};

    while ((match = regex.exec(hash))) {
      hashParams[decodeURIComponent(match[1])] = decodeURIComponent(match[3]);
    }
    const filteredParams = Object.fromEntries(
      Object.entries(hashParams).filter(([_, value]) => value !== null)
    );

    // Call The Filter API If Have Data On URL---------------------------------
    // setPropertyTypePageFilter---------------------------------
    if (
      filteredParams.propertyType !== null &&
      filteredParams.propertyType !== undefined
    ) {
      setPropertyTypePageFilter(filteredParams.propertyType);
    }
    // setLocalitiesData----------------------------
    if (
      filteredParams.addressStr !== null &&
      filteredParams.addressStr !== undefined
    ) {
      setLocalitiesData([...localitiesData, filteredParams.addressStr]);
    }
    // setfurnishedFilter------------------------------------
    if (
      filteredParams.furnishing !== null &&
      filteredParams.furnishing !== undefined
    ) {
      setfurnishedFilter(filteredParams.furnishing);
    }
    //setTenantTypeFilter-------------------------
    if (
      filteredParams.available_for !== null &&
      filteredParams.available_for !== undefined
    ) {
      setTenantTypeFilter(filteredParams.available_for);
    }
    // setBedroomsFilter----------------------------
    if (
      filteredParams.bhk_of_house !== null &&
      filteredParams.bhk_of_house !== undefined
    ) {
      setBedroomsFilter(filteredParams.bhk_of_house);
    }
    // setCityFilter --------------------
    if (filteredParams.city !== null && filteredParams.city !== undefined) {
      setCityFilter(filteredParams.city);
    }
    // setBuildupTypeFilter-------------------------
    if (
      filteredParams.buildUp_type !== null &&
      filteredParams.buildUp_type !== undefined
    ) {
      setBuildupTypeFilter(filteredParams.buildUp_type);
    }

    // setVerifiedPropertiesFilter-------------------------
    if (
      filteredParams.verify_property !== null &&
      filteredParams.verify_property !== undefined &&
      filteredParams.verify_property === 1
    ) {
      setVerifiedPropertiesFilter(true);
    }

    // setLowestAgeFilter-------------------------
    if (filteredParams.minAge !== null && filteredParams.minAge !== undefined) {
      setLowestAgeFilter(filteredParams.minAge);
    }
    // setHighestAgeFilter-------------------------
    if (filteredParams.maxAge !== null && filteredParams.maxAge !== undefined) {
      setHighestAgeFilter(filteredParams.maxAge);
    }
    // setSocietyData-------------------------
    if (
      filteredParams.societyStr !== null &&
      filteredParams.societyStr !== undefined
    ) {
      setSocietyData(filteredParams.societyStr);
    }
    // setWithownerFilter-------------------------
    if (
      filteredParams.owner_availability !== null &&
      filteredParams.owner_availability !== undefined
    ) {
      setWithownerFilter(filteredParams.owner_availability);
    }
    // setIndependentPropertiesFilter-------------------------
    if (
      filteredParams.independent !== null &&
      filteredParams.independent !== undefined &&
      filteredParams.independent === 1
    ) {
      setIndependentPropertiesFilter(true);
    }
    // setBathroomsFilter-------------------------
    if (
      filteredParams.bathrooms !== null &&
      filteredParams.bathrooms !== undefined
    ) {
      setBathroomsFilter(filteredParams.bathrooms);
    }
    // setPropertiesWithPhotoFilter-------------------------
    if (
      filteredParams.propertyImages !== null &&
      filteredParams.propertyImages !== undefined &&
      filteredParams.propertyImages === true
    ) {
      setPropertiesWithPhotoFilter(filteredParams.propertyImages);
    }
    if (
      filteredParams.minArea !== null &&
      filteredParams.minArea !== undefined &&
      filteredParams.maxArea !== null &&
      filteredParams.maxArea !== undefined
    ) {
      setAreaRange([filteredParams.minArea, filteredParams.maxArea]);

      // console.log("set value of budget - ", [
      //   filteredParams.minPrice,
      //   filteredParams.maxPrice,
      // ]);
    }
    // // setAreaRange minArea-------------------------
    // if (
    //   filteredParams.minArea !== null &&
    //   filteredParams.minArea !== undefined
    // ) {
    //   setAreaRange([filteredParams.minArea, areaRange[1]]);
    // }
    // // setAreaRange maxArea-------------------------
    // if (
    //   filteredParams.maxArea !== null &&
    //   filteredParams.maxArea !== undefined
    // ) {
    //   setAreaRange([areaRange[0]], filteredParams.maxArea);
    // }
    // setConstructionStatusFilter-------------------------
    if (
      filteredParams.construction_status !== null &&
      filteredParams.construction_status !== undefined
    ) {
      setConstructionStatusFilter(filteredParams.construction_status);
    }
    // setPostedByFilter-------------------------
    if (
      filteredParams.posted_by !== null &&
      filteredParams.posted_by !== undefined
    ) {
      setPostedByFilter(filteredParams.posted_by);
    }
    // setPurchaseTypeFilter-------------------------
    if (
      filteredParams.purchase_type !== null &&
      filteredParams.purchase_type !== undefined
    ) {
      setPurchaseTypeFilter(filteredParams.purchase_type);
    }
    // setRERAApprovedFilter-------------------------
    if (
      filteredParams.rera_approved !== null &&
      filteredParams.rera_approved !== undefined
    ) {
      setRERAApprovedFilter(filteredParams.rera_approved);
    }
    // setFacingFilter-------------------------
    if (filteredParams.facing !== null && filteredParams.facing !== undefined) {
      setFacingFilter(filteredParams.facing);
    }
    // setSelectedDate-------------------------
    if (
      filteredParams.startDate !== null &&
      filteredParams.startDate !== undefined
    ) {
      setSelectedDate(filteredParams.startDate);
    }
    // setPremiumFilter-------------------------
    if (
      filteredParams.premium !== null &&
      filteredParams.premium !== undefined &&
      filteredParams.premium === 1
    ) {
      setPremiumFilter(true);
    }
    // setCampusTypeFilter-------------------------
    if (
      filteredParams.campus_type !== null &&
      filteredParams.campus_type !== undefined
    ) {
      setCampusTypeFilter(filteredParams.campus_type);
    }

    // console.log("furnishing -", filteredParams.furnishing);
    // (propertyType === "sell" || propertyTypePageFilter === "Sell"
    //   ? setSellBudgetRange([(filteredParams.minPrice, rentBudgetRange[1])])
    //   : setRentBudgetRange([(filteredParams.minPrice, rentBudgetRange[1])])) ||
    //   null;
    // (propertyType === "sell" || propertyTypePageFilter === "Sell"
    //   ? setSellBudgetRange([(rentBudgetRange[0], filteredParams.maxPrice)])
    //   : setRentBudgetRange([(rentBudgetRange[0], filteredParams.maxPrice)])) ||
    //   null;

    // set Budget Range------------------------------
    if (
      filteredParams.minPrice !== null &&
      filteredParams.minPrice !== undefined &&
      filteredParams.maxPrice !== null &&
      filteredParams.maxPrice !== undefined
    ) {
      propertyType === "sell" || propertyTypePageFilter === "Sell"
        ? setSellBudgetRange([filteredParams.minPrice, filteredParams.maxPrice])
        : setRentBudgetRange([
            filteredParams.minPrice,
            filteredParams.maxPrice,
          ]);
      // console.log("set value of budget - ", [
      //   filteredParams.minPrice,
      //   filteredParams.maxPrice,
      // ]);
    }

    // if (
    //   filteredParams.maxPrice !== null &&
    //   filteredParams.maxPrice !== undefined
    // ) {
    //   propertyType === "sell" || propertyTypePageFilter === "Sell"
    //     ? setSellBudgetRange([rentBudgetRange[0], filteredParams.maxPrice])
    //     : setRentBudgetRange([rentBudgetRange[0], filteredParams.maxPrice]);
    //   console.log("set value of maxPrice - ", [
    //     rentBudgetRange[0],
    //     filteredParams.maxPrice,
    //   ]);
    // }
    if (Object.keys(filteredParams).length > 2) {
      setTimeout(() => {
        setAlreadyFiltered(true);
      }, 2000);

      // console.log("Filters Entered");
      // alert("Filters Activated");
    }

    const test = {
      // propertyType:
      //   propertyTypePageFilter !== null &&
      //   propertyTypePageFilter !== undefined &&
      //   propertyTypePageFilter.length !== 0
      //     ? propertyTypePageFilter
      //     : propertyTypePageFilter.length === 0 && propertyType !== "all"
      //     ? propertyType
      //     : "",
      // addressStr:
      //   localitiesData !== null &&
      //   localitiesData !== undefined &&
      //   localitiesData.length !== 0
      //     ? localitiesData
      //     : localitiesData.length === 0 && proprertyKeys !== "list"
      //     ? proprertyKeys
      //     : "",
      minPrice:
        propertyType === "sell" || propertyTypePageFilter === "Sell"
          ? sellBudgetRange[0]
          : rentBudgetRange[0],
      maxPrice:
        propertyType === "sell" || propertyTypePageFilter === "Sell"
          ? sellBudgetRange[1]
          : rentBudgetRange[1],
      // furnishing: furnishedFilter,
      // available_for: tenantTypeFilter,
      // bhk_of_house: bedroomsFilter,
      // city: cityFilter !== null && cityFilter !== undefined ? cityFilter : "",
      // buildUp_type: buildupTypeFilter,
      // verify_property:
      //   verifiedPropertiesFilter === true
      //     ? 1
      //     : verifiedPropertiesFilter === false
      //     ? ""
      //     : "",
      // minAge: lowestAgeFilter === undefined ? "" : lowestAgeFilter,
      // maxAge: highestAgeFilter === undefined ? "" : highestAgeFilter,
      // societyStr: societyData,
      // owner_availability:
      //   withownerFilter !== null &&
      //   withownerFilter !== undefined &&
      //   withownerFilter !== ""
      //     ? withownerFilter
      //     : "",
      // independent:
      //   independentPropertiesFilter === true
      //     ? 1
      //     : independentPropertiesFilter === false
      //     ? ""
      //     : "",
      // bathrooms:
      //   bathroomsFilter !== null && bathroomsFilter !== undefined
      //     ? bathroomsFilter
      //     : "",
      // propertyImages:
      //   propertiesWithPhotoFilter === true
      //     ? true
      //     : propertiesWithPhotoFilter === false
      //     ? ""
      //     : "",
      // minArea: areaRange[0],
      // maxArea: areaRange[1],
      // construction_status:
      //   constructionStatusFilter !== null &&
      //   constructionStatusFilter !== undefined &&
      //   constructionStatusFilter !== ""
      //     ? constructionStatusFilter
      //     : "",
      // posted_by:
      //   postedByFilter !== null &&
      //   postedByFilter !== undefined &&
      //   postedByFilter !== ""
      //     ? postedByFilter
      //     : "",
      // purchase_type:
      //   purchaseTypeFilter !== null &&
      //   purchaseTypeFilter !== undefined &&
      //   purchaseTypeFilter !== ""
      //     ? purchaseTypeFilter
      //     : "",
      // rera_approved:
      //   RERAApprovedFilter !== null &&
      //   RERAApprovedFilter !== undefined &&
      //   RERAApprovedFilter !== ""
      //     ? RERAApprovedFilter
      //     : "",
      // facing:
      //   facingFilter !== null &&
      //   facingFilter !== undefined &&
      //   facingFilter !== ""
      //     ? facingFilter
      //     : "",
      // startDate: selectedDate,
      // premium: premiumFilter === true ? 1 : premiumFilter === false ? "" : "",
      // campus_type:
      //   campusTypeFilter !== null &&
      //   campusTypeFilter !== undefined &&
      //   campusTypeFilter !== ""
      //     ? campusTypeFilter
      //     : "",
    };
  }, []);

  // Watchlist
  useEffect(() => {
    if (propertyId !== undefined && watchlistStatus === true) {
      updateWatchlist();
    }
  }, [propertyId, watchlistStatus]);

  useEffect(() => {
    filteredProperties();
  }, [proprertyKeys, propertyType, pageCount]);

  // API's -x-x-x-x-x-x-x-x-x-xx-x-x-x-x-x
  const filteredProperties = async () => {
    console.log(
      "api url is - ",
      `/multipleFilterSearchOnProperty/${pageCount}/20/?propertyType=${
        propertyTypePageFilter !== null &&
        propertyTypePageFilter !== undefined &&
        propertyTypePageFilter.length !== 0
          ? propertyTypePageFilter
          : propertyTypePageFilter.length === 0 && propertyType !== "all"
          ? propertyType
          : ""
      }&addressStr=${
        localitiesData !== null &&
        localitiesData !== undefined &&
        localitiesData.length !== 0
          ? localitiesData
          : localitiesData.length === 0 && proprertyKeys !== "list"
          ? proprertyKeys
          : ""
      }&minPrice=${
        propertyType === "sell" || propertyTypePageFilter === "Sell"
          ? sellBudgetRange[0]
          : rentBudgetRange[0]
      }&maxPrice=${
        propertyType === "sell" || propertyTypePageFilter === "Sell"
          ? sellBudgetRange[1]
          : rentBudgetRange[1]
      }&furnishing=${furnishedFilter}&available_for=${tenantTypeFilter}&bhk_of_house=${bedroomsFilter}&city=${
        cityFilter !== null && cityFilter !== undefined ? cityFilter : ""
      }&buildUp_type=${buildupTypeFilter}&verify_property=${
        verifiedPropertiesFilter === true
          ? 1
          : verifiedPropertiesFilter === false
          ? ""
          : ""
        // }&minAge=${lowestAgeFilter === undefined ? "" : lowestAgeFilter}&maxAge=${
        //   highestAgeFilter === undefined ? "" : highestAgeFilter
      }&societyStr=${societyData}&owner_availability=${
        withownerFilter !== null &&
        withownerFilter !== undefined &&
        withownerFilter !== ""
          ? withownerFilter
          : ""
        // }&independent=${
        //   independentPropertiesFilter === true
        //     ? 1
        //     : independentPropertiesFilter === false
        //     ? ""
        //     : ""
        // }&bathrooms=${
        //   bathroomsFilter !== null && bathroomsFilter !== undefined
        //     ? bathroomsFilter
        //     : ""
        // }&propertyImages=${
        //   propertiesWithPhotoFilter === true
        //     ? true
        //     : propertiesWithPhotoFilter === false
        //     ? ""
        //     : ""
        // }&minArea=${areaRange[0]}&maxArea=${areaRange[1]}&construction_status=${
        //   constructionStatusFilter !== null &&
        //   constructionStatusFilter !== undefined &&
        //   constructionStatusFilter !== ""
        //     ? constructionStatusFilter
        //     : ""
      }&posted_by=${
        postedByFilter !== null &&
        postedByFilter !== undefined &&
        postedByFilter !== ""
          ? postedByFilter
          : ""
      }&purchase_type=${
        purchaseTypeFilter !== null &&
        purchaseTypeFilter !== undefined &&
        purchaseTypeFilter !== ""
          ? purchaseTypeFilter
          : ""
      }&rera_approved=${
        RERAApprovedFilter !== null &&
        RERAApprovedFilter !== undefined &&
        RERAApprovedFilter !== ""
          ? RERAApprovedFilter
          : ""
      }&facing=${
        facingFilter !== null &&
        facingFilter !== undefined &&
        facingFilter !== ""
          ? facingFilter
          : ""
        // }&startDate=${selectedDate}&premium=${
        //   premiumFilter === true ? 1 : premiumFilter === false ? "" : ""
        // }&campus_type=${
        //   campusTypeFilter !== null &&
        //   campusTypeFilter !== undefined &&
        //   campusTypeFilter !== ""
        //     ? campusTypeFilter
        //     : ""
      }`
    );

    const response = await getApihandler(
      `/multipleFilterSearchOnProperty/${pageCount}/20/?propertyType=${
        propertyTypePageFilter !== null &&
        propertyTypePageFilter !== undefined &&
        propertyTypePageFilter.length !== 0
          ? propertyTypePageFilter
          : propertyTypePageFilter.length === 0 && propertyType !== "all"
          ? propertyType
          : ""
      }&addressStr=${
        localitiesData !== null &&
        localitiesData !== undefined &&
        localitiesData.length !== 0
          ? localitiesData
          : localitiesData.length === 0 && proprertyKeys !== "list"
          ? proprertyKeys
          : ""
      }&minPrice=${
        propertyType === "sell" || propertyTypePageFilter === "Sell"
          ? sellBudgetRange[0]
          : rentBudgetRange[0]
      }&maxPrice=${
        propertyType === "sell" || propertyTypePageFilter === "Sell"
          ? sellBudgetRange[1]
          : rentBudgetRange[1]
      }&furnishing=${furnishedFilter}&available_for=${tenantTypeFilter}&bhk_of_house=${bedroomsFilter}&city=${
        cityFilter !== null && cityFilter !== undefined ? cityFilter : ""
      }&buildUp_type=${buildupTypeFilter}&verify_property=${
        verifiedPropertiesFilter === true
          ? 1
          : verifiedPropertiesFilter === false
          ? ""
          : ""
        // }&minAge=${lowestAgeFilter === undefined ? "" : lowestAgeFilter}&maxAge=${
        //   highestAgeFilter === undefined ? "" : highestAgeFilter
      }&societyStr=${societyData}&owner_availability=${
        withownerFilter !== null &&
        withownerFilter !== undefined &&
        withownerFilter !== ""
          ? withownerFilter
          : ""
        // }&independent=${
        //   independentPropertiesFilter === true
        //     ? 1
        //     : independentPropertiesFilter === false
        //     ? ""
        //     : ""
        // }&bathrooms=${
        //   bathroomsFilter !== null && bathroomsFilter !== undefined
        //     ? bathroomsFilter
        //     : ""
        // }&propertyImages=${
        //   propertiesWithPhotoFilter === true
        //     ? true
        //     : propertiesWithPhotoFilter === false
        //     ? ""
        //     : ""
        // }&minArea=${areaRange[0]}&maxArea=${areaRange[1]}&construction_status=${
        //   constructionStatusFilter !== null &&
        //   constructionStatusFilter !== undefined &&
        //   constructionStatusFilter !== ""
        //     ? constructionStatusFilter
        //     : ""
      }&posted_by=${
        postedByFilter !== null &&
        postedByFilter !== undefined &&
        postedByFilter !== ""
          ? postedByFilter
          : ""
      }&purchase_type=${
        purchaseTypeFilter !== null &&
        purchaseTypeFilter !== undefined &&
        purchaseTypeFilter !== ""
          ? purchaseTypeFilter
          : ""
      }&rera_approved=${
        RERAApprovedFilter !== null &&
        RERAApprovedFilter !== undefined &&
        RERAApprovedFilter !== ""
          ? RERAApprovedFilter
          : ""
      }&facing=${
        facingFilter !== null &&
        facingFilter !== undefined &&
        facingFilter !== ""
          ? facingFilter
          : ""
        // }&startDate=${selectedDate}&premium=${
        //   premiumFilter === true ? 1 : premiumFilter === false ? "" : ""
        // }&campus_type=${
        //   campusTypeFilter !== null &&
        //   campusTypeFilter !== undefined &&
        //   campusTypeFilter !== ""
        //     ? campusTypeFilter
        //     : ""
      }`
    );
    console.log("multipleFilterSearchOnProperty response - ", response);
    setPropertiesList(response.data);
    setCompleteResp(response);

    // Clear Property Id Filter If selected Any Others=x=x=x=x=x=x

    // setPropertyIdFilterShow(false);
    setPropertyIdFilter("");
  };

  const updateWatchlist = async () => {
    const response = await postApihandler(
      `/addPropertyToWishList/${userId}/${propertyId}`
    );
    // console.log("updateWatchlist====>", response);
    if (response.status === 200) {
      Swal.fire({
        position: "middle-centre",
        icon: "success",
        title: "Wishlist Added",
        showConfirmButton: false,
        timer: 2000,
      });
      setPropertyId();
    } else {
      Swal.fire({
        position: "middle-centre",
        icon: "error",
        title: response.error.response.data.message,
        showConfirmButton: false,
        timer: 2000,
      });
      setPropertyId();
    }
  };

  // Visit Popup------x-------------x------------------
  const visitPopupBg = {
    background: "#00000061",
  };

  const visitPopupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: { lg: "50%" },
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // Check Number Digit Is Smaller Than 1
  function formatNumber(num) {
    // Convert the number to a string
    num = num.toString();

    // Check if the string has only one character
    if (num.length === 1) {
      // Add a leading '0'
      num = "0" + num;
    }

    return num;
  }

  const submitVisitShedule = async (value) => {
    if (
      value !== null &&
      value.$y !== null &&
      value.$M !== null &&
      value.$D !== null &&
      value.$H !== null &&
      value.$H !== 0 &&
      propertyId !== undefined &&
      propertyId !== null
    ) {
      const visitDate =
        value.$y + "-" + formatNumber(value.$M) + "-" + formatNumber(value.$D);
      const visitTime = formatNumber(value.$H) + ":" + formatNumber(value.$m);
      const response = await postApihandler(
        `/bookingForPropertyVisit/${userId}/${propertyId}/`,
        {
          date: visitDate,
          time: visitTime,
        }
      );
      // console.log("bookingForPropertyVisit response - ", response.status);
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Visit Request Has Sent Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      // console.log("catch error is - ");
      Swal.fire({
        icon: "error",
        title: "Please Select Date & Time Both",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  // Already Seen Hide ------
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  // Availabe From--------
  // Handle date change
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  // Function to clear the date
  const clearDate = () => {
    setSelectedDate("");
  };

  // Range Filter --------------
  const areaHandleChange = (event, newValue) => {
    setAreaRange(newValue);
  };

  const rentBudgetHandleChange = (event, newValue) => {
    setRentBudgetRange(newValue);
  };
  const sellBudgetHandleChange = (event, newValue) => {
    setSellBudgetRange(newValue);
  };

  const defaultTheme = createTheme();

  const theme = createTheme({
    palette: {
      green: defaultTheme.palette.augmentColor({
        color: {
          main: "rgb(2, 201, 136)",
        },
        name: "green",
      }),
    },
  });

  // Refresh Page------------------------------------
  function refreshPage() {
    window.location.reload();
  }

  const handleRefresh = () => {
    setTimeout(() => {
      refreshPage();
    }, 600);
  };

  const handleRemoveFilter = (filterType) => {
    console.log("filter type is");
    switch (filterType) {
      case "propertyType":
        setPropertyTypePageFilter([]);
        break;
      case "localities":
        setLocalitiesData([]);
        break;
      case "buildupType":
        setBuildupTypeFilter("");
        break;
      case "tenantType":
        setTenantTypeFilter("");
        break;
      case "verifiedProperties":
        setVerifiedPropertiesFilter("");
        break;
      case "age":
        setLowestAgeFilter(undefined);
        setHighestAgeFilter(undefined);
        break;
      case "society":
        setSocietyData("");
        break;
      case "withOwner":
        setWithownerFilter("");
        break;
      case "independentProperties":
        setIndependentPropertiesFilter("");
        break;
      case "bedrooms":
        setBedroomsFilter("");
        break;
      case "bathrooms":
        setBathroomsFilter(undefined);
        break;
      case "propertiesWithPhoto":
        setPropertiesWithPhotoFilter("");
        break;
      // Add more cases for other filter types
      default:
        break;
    }
  };

  // ******** clear all functionality **************
  const handleClearAll = () => {
    setPropertyTypePageFilter("");
    setfurnishedFilter("");

    setLocalitiesData([]);
    setBuildupTypeFilter("");
    setTenantTypeFilter("");
    setVerifiedPropertiesFilter("");
    setWithownerFilter("");
    setIndependentPropertiesFilter("");
    setBedroomsFilter("");
    setBathroomsFilter(undefined);
    setPropertiesWithPhotoFilter("");
    // setMinArea("");
    // setMaxArea("");
    // setMinRentBudget("");
    // setMaxRentBudget("");
    setSelectedDate("");
    // Reset any other filters you are using in your app
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // *************** city input  functinality*******************
  const LocalityData = [
    {
      name: "Nipania",
      city: "Indore",
    },
    {
      name: "Bhanwarkuan",
      city: "Indore",
    },

    {
      name: "Vijay nagar",
      city: "Indore",
    },
    {
      name: "Mr 10",
      city: "Indore",
    },
    {
      name: "Mr 9",
      city: "Indore",
    },
    {
      name: "AB BypassRoad",
      city: "Indore",
    },
    {
      name: "Mahalakshmi Nagar",
      city: "Indore",
    },
    {
      name: "Bicholi Mardana",
      city: "Indore",
    },
    {
      name: "Rau",
      city: "Indore",
    },
    {
      name: "Mhow",
      city: "Indore",
    },
    {
      name: "Khandwa Road",
      city: "Indore",
    },
    {
      name: "Bengali Square",
      city: "Indore",
    },
    {
      name: "Kanadia Road",
      city: "Indore",
    },
    {
      name: "Talawali Chanda",
      city: "Indore",
    },
    {
      name: "Rajendra Nagar",
      city: "Indore",
    },
    {
      name: "Silicon City",
      city: "Indore",
    },
    {
      name: "Pipliyahana",
      city: "Indore",
    },
    {
      name: "Kanadiya",
      city: "Indore",
    },
    {
      name: "Scheme No 54",
      city: "Indore",
    },
    {
      name: "Saket Nagar",
      city: "Indore",
    },
    {
      name: "Scheme No 114",
      city: "Indore",
    },
    {
      name: "Jhalaria",
      city: "Indore",
    },
    {
      name: "AB Road indore",
      city: "Indore",
    },
    {
      name: "Ida Scheme No 134",
      city: "Indore",
    },
    {
      name: "Devguradia",
      city: "Indore",
    },
    {
      name: "Tilak Nagar",
      city: "Indore",
    },
    {
      name: "Bijalpur",
      city: "Indore",
    },
    {
      name: "Bhawrasla",
      city: "Indore",
    },
    {
      name: "Bada Bangarda",
      city: "Indore",
    },
    {
      name: "Niranjanpur",
      city: "Indore",
    },
    {
      name: "Scheme No 78",
      city: "Indore",
    },
    {
      name: "Race Course Road",
      city: "Indore",
    },
    {
      name: "Old Palasia",
      city: "Indore",
    },
    {
      name: "MR 11",
      city: "Indore",
    },
    {
      name: "Nainod",
      city: "Indore",
    },
    {
      name: "Bicholi Hapsi",
      city: "Indore",
    },
    {
      name: "Kesar Bagh",
      city: "Indore",
    },
    {
      name: "Tejaji Nagar",
      city: "Indore",
    },
    {
      name: "Palakhedi",
      city: "Indore",
    },
    {
      name: "Scheme No 71",
      city: "Indore",
    },
    {
      name: "Sai Kripa Colony",
      city: "Indore",
    },
    {
      name: "Manorama Ganj",
      city: "Indore",
    },
    {
      name: "Scheme No 103",
      city: "Indore",
    },
    {
      name: "Gulab Bagh",
      city: "Indore",
    },
    {
      name: "Navlakha",
      city: "Indore",
    },
    {
      name: "Rau Pitampur Road",
      city: "Indore",
    },
    {
      name: "Rangwasa",
      city: "Indore",
    },
    {
      name: "Tigaria Badshah",
      city: "Indore",
    },
    {
      name: "Pipliya Kumar",
      city: "Indore",
    },
    {
      name: "LIG Colony",
      city: "Indore",
    },
    {
      name: "Luv Kush Square",
      city: "Indore",
    },
    {
      name: "Kalani Nagar",
      city: "Indore",
    },
    {
      name: "Magarkheda",
      city: "Indore",
    },
    {
      name: "Gumasta Nagar",
      city: "Indore",
    },
    {
      name: "South Tukoganj",
      city: "Indore",
    },
    {
      name: "Nanda Nagar",
      city: "Indore",
    },
    {
      name: "Scheme No 94",
      city: "Indore",
    },
    {
      name: "Sangam Nagar",
      city: "Indore",
    },

    {
      name: "Alok Nagar",
      city: "Indore",
    },
    {
      name: "Anurag Nagar",
      city: "Indore",
    },
    {
      name: "Snehlataganj",
      city: "Indore",
    },
    {
      name: "Sapna Sangeeta Road",
      city: "Indore",
    },
    {
      name: "Vasant Vihar",
      city: "Indore",
    },
    {
      name: "Mangaliya Sadak",
      city: "Indore",
    },
    {
      name: "Paliya",
      city: "Indore",
    },
    {
      name: "Anoop Nagar",
      city: "Indore",
    },
    {
      name: "Bangali Square",
      city: "Indore",
    },
    {
      name: "Arandia",
      city: "Indore",
    },
    {
      name: "Balya Kheda",
      city: "Indore",
    },
    {
      name: "Geeta Bhavan",
      city: "Indore",
    },
    {
      name: "Musakhedi",
      city: "Indore",
    },
    {
      name: "Mig Colony",
      city: "Indore",
    },
    {
      name: "Ring Road",
      city: "Indore",
    },
    {
      name: "Jakhiya",
      city: "Indore",
    },
    {
      name: "Khatiwala Tank",
      city: "Indore",
    },
    {
      name: "Palsikar Colony",
      city: "Indore",
    },
    {
      name: "Rajwada",
      city: "Indore",
    },
    {
      name: "Kordia Barda",
      city: "Indore",
    },
    {
      name: "Mundla Nayta",
      city: "Indore",
    },
    {
      name: "Pithampur Road",
      city: "Indore",
    },
    {
      name: "Bakhtawar Ram Nagar",
      city: "Indore",
    },
    {
      name: "Pigdambar",
      city: "Indore",
    },
    {
      name: "Rambag",
      city: "Indore",
    },
    {
      name: "Vishnupuri Colony",
      city: "Indore",
    },
    {
      name: "Shri Nagar Extension",
      city: "Indore",
    },
    {
      name: "Annapurna Nagar",
      city: "Indore",
    },
    {
      name: "Palasia",
      city: "Indore",
    },
    {
      name: "Budhaniya",
      city: "Indore",
    },
    {
      name: "Gokul Nagar",
      city: "Indore",
    },
    {
      name: "Morod",
      city: "Indore",
    },
    {
      name: "Sanchar Nagar Extention",
      city: "Indore",
    },
    {
      name: "NH3 Agra Mumbai Highway",
      city: "Indore",
    },
    {
      name: "Vallabh Nagar",
      city: "Indore",
    },
    {
      name: "Indrapuri Colony",
      city: "Indore",
    },
    {
      name: "Vaishali Nagar",
      city: "Indore",
    },
    {
      name: "Banganga",
      city: "Indore",
    },
    {
      name: "Shiv Dham Colony",
      city: "Indore",
    },
    {
      name: "Panda",
      city: "Indore",
    },
    {
      name: "Vandana Nagar",
      city: "Indore",
    },
    {
      name: "Sanawadia",
      city: "Indore",
    },
    {
      name: "Dhanvantri Nagar",
      city: "Indore",
    },
    {
      name: "Panchderiya",
      city: "Indore",
    },
    {
      name: "Sainath Colony",
      city: "Indore",
    },
    {
      name: "Tejpur Gadbadi",
      city: "Indore",
    },
    {
      name: "Sawer",
      city: "Indore",
    },
    {
      name: "Malviya Nagar",
      city: "Indore",
    },
    {
      name: "Malharganj",
      city: "Indore",
    },
    {
      name: "Chandan Nagar",
      city: "Indore",
    },
    {
      name: "Mahaveer Nagar",
      city: "Indore",
    },
    {
      name: "Barodiya Ema",
      city: "Indore",
    },
    {
      name: "Usha Nagar Extension",
      city: "Indore",
    },
    {
      name: "Nehru Nagar",
      city: "Indore",
    },
    {
      name: "Baliyakhedi",
      city: "Indore",
    },
    {
      name: "Sirpur",
      city: "Indore",
    },
    {
      name: "Choral",
      city: "Indore",
    },
    {
      name: "Green Park Colony",
      city: "Indore",
    },
    {
      name: "Scheme 54 PU4",
      city: "Indore",
    },
    {
      name: "Sindhi Colony",
      city: "Indore",
    },
    {
      name: "Karolbagh",
      city: "Indore",
    },
    {
      name: "Scheme no 53",
      city: "Indore",
    },
    {
      name: "New palasia",
      city: "Indore",
    },
    {
      name: "Scheme no 74",
      city: "Indore",
    },
    {
      name: "Chikitsak nagar",
      city: "Indore",
    },
    {
      name: "Veena nagar",
      city: "Indore",
    },
    {
      name: "Mansorawar nagar",
      city: "Indore",
    },
    {
      name: "Bapat square",
      city: "Indore",
    },
    {
      name: "Sukliya",
      city: "Indore",
    },
    {
      name: "Royal banglow",
      city: "Indore",
    },
    {
      name: "Dewas Naka",
      city: "Indore",
    },
    {
      name: "Industrial house",
      city: "Indore",
    },
    {
      name: "Khajrana",
      city: "Indore",
    },
    {
      name: "Atal dwar",
      city: "Indore",
    },
    {
      name: "Anand bazar",
      city: "Indore",
    },
    {
      name: "Super corridor",
      city: "Indore",
    },
    {
      name: "Lasudia mori",
      city: "Indore",
    },
    {
      name: "Scheme no 136",
      city: "Indore",
    },
    {
      name: "Scheme no 140",
      city: "Indore",
    },
    {
      name: "Hira nagar",
      city: "Indore",
    },
  ];

  const [modalinputValue, setModalInputValue] = useState("");
  const [modalsuggestions, setModalSuggestions] = useState([]);
  const [modalshowSuggestions, setModalShowSuggestions] = useState(false);
  const [modalselectedLocalities, setModalSelectedLocalities] = useState([]);
  const [modalshowPlaceholder, setModalShowPlaceholder] = useState(true);
  const [selectedLocalities, setSelectedLocalities] = useState([]);

  const handleInputChangeModal = (event) => {
    const value = event.target.value;
    setModalInputValue(value);
    setModalShowSuggestions(true);
  };

  // ******** for modal box already show the values in input **************
  useEffect(() => {
    // Assuming `proprertyKeys` contains the locality you want to show
    if (proprertyKeys) {
      // setModalInputValue(proprertyKeys);
      setModalSelectedLocalities([proprertyKeys]);
    }
  }, [proprertyKeys]);
  const handleFilterDataModal = () => {
    const filteredSuggestions = LocalityData.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(modalinputValue.toLowerCase())
    );
    setModalSuggestions(filteredSuggestions);
  };

  const handleSuggestionClickModal = (suggestion) => {
    if (!modalselectedLocalities.includes(suggestion.name)) {
      setModalSelectedLocalities([...modalselectedLocalities, suggestion.name]);
      setLocalitiesData([...localitiesData, suggestion.name]);
    }
    setModalInputValue("");
    setModalShowPlaceholder(false); // Hide the placeholder when a locality is selected
    setModalShowSuggestions(false);
  };

  const handleClearInput = () => {
    setInputValue("");
    setShowSuggestions(false);
  };

  const removeLocality = (locality) => {
    setModalSelectedLocalities(
      modalselectedLocalities.filter((loc) => loc !== locality)
    );
    if (setModalSelectedLocalities.length === 1) {
      setModalShowPlaceholder(true); // Show the placeholder if no localities are selected
    }
  };

  useEffect(() => {
    if (modalinputValue !== "") {
      handleFilterDataModal();
    } else {
      setModalShowSuggestions(false);
    }
  }, [modalinputValue]);

  function refreshPage() {
    window.location.reload();
  }

  // const handleRefresh = () => {
  //   setTimeout(() => {
  //     refreshPage();
  //   }, 600);
  // };

  const handleChangeDropdown = (event) => {
    setPropertyTypePageFilter(event.target.value);
    // setDisableParamsPropertyType(true);
  };

  useEffect(() => {
    // Log propertyType to debug
    console.log("propertyType is =============================>", propertyType);

    if (propertyType === "rent") {
      setPropertyTypePageFilter("Rent");
    } else if (propertyType === "sell") {
      setPropertyTypePageFilter("Buy");
    }
  }, []);
  const renderAppliedFilters = () => {
    const filters = [];
    // if (propertyTypePageFilter) filters.push(`${propertyTypePageFilter}`);
    // if (propertyTypePageFilter.length)
    //   filters.push(
    //     `Localities: ${propertyTypePageFilter.map((l) => l.name).join(", ")}`
    //   );
    if (selectedLocalities.length)
      filters.push(` ${selectedLocalities.map((l) => l.name).join(", ")}`);
    if (bedroomsFilter) filters.push(` ${bedroomsFilter}`);
    if (bathroomsFilter) filters.push(`${bathroomsFilter}`);
    if (buildupTypeFilter) filters.push(`${buildupTypeFilter}`);
    if (tenantTypeFilter) filters.push(` ${tenantTypeFilter}`);

    if (verifiedPropertiesFilter) filters.push(`${verifiedPropertiesFilter}`);
    if (withownerFilter) filters.push(`${withownerFilter}`);
    if (furnishedFilter) filters.push(`${furnishedFilter}`);

    if (propertiesWithPhotoFilter) filters.push(`${propertiesWithPhotoFilter}`);
    if (premiumFilter) filters.push(`${premiumFilter}`);
    if (minRentBudget && maxRentBudget) {
      filters.push(`${minRentBudget} - ${maxRentBudget} Rent`);
    }

    if (minArea && maxArea) {
      filters.push(` ${minArea} - ${maxArea} Sq. Ft.`);
    }
    if (selectedDate) filters.push(` ${selectedDate}`);
    // if (ageFilter.lowest || ageFilter.highest)
    //   filters.push(
    //     `Age Of Property: ${ageFilter.lowest}-${ageFilter.highest} Years`
    //   );
    if (independentPropertiesFilter)
      filters.push(`Independent Properties: Yes`);

    return filters.map((filter, index) => (
      <div key={index}>
        <button
          style={{
            border: "1px solid gray",
            borderRadius: "20px",
            padding: "5px 10px",
            margin: "7px",
            background: "none",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
            cursor: "pointer",
          }}
        >
          {/* Filter text */}
          <span>{filter}</span>

          {/* Close icon */}
          {/* <span
            style={{
              marginLeft: "10px",
              color: "black", // Color of the close icon
              cursor: "pointer", // Make the icon clickable
              fontSize: "14px",
              width: "20px", // Width for the close icon
              height: "20px", // Height for the close icon
            }}
            onClick={() => handleRemoveFilter(filter.toLowerCase())} // Dynamically pass filter name
          >
            &#10006;
          </span> */}
        </button>
      </div>
    ));
  };

  const filterData = () => {
    return (
      <Box className="px-lg-0 px-5 py-lg-0 py-4 fliter-list-wrapper">
        <div>
          <div className="applied-filters-container mt-3">
            <div className="d-flex justify-content-between">
              <h6 style={{ fontSize: "16px", fontWeight: "600" }}>
                Applied Filters
              </h6>
              <h6 onClick={handleClearAll} style={{ cursor: "pointer" }}>
                Clear All
              </h6>
            </div>
            {renderAppliedFilters()}
          </div>
          {/* The rest of your filtering UI */}
        </div>
        <Grid
          container
          xs={12}
          className={`justify-content-end mb-4 d-lg-none`}
        >
          <Grid lg={3} xs={3} style={{ padding: "5px 0" }} className="text-end">
            <button
              className={"py-2 px-3"}
              onClick={() => {
                toggleDrawer();
              }}
              style={{
                border: "1px solid rgb(2, 201, 136)",
                borderRadius: "50%",
                fontSize: "24px",
                fontWeight: 600,
              }}
            >
              X
            </button>
          </Grid>
        </Grid>
        {/* Type Of Property */}
        <Grid
          container
          lg={12}
          sm={8}
          xs={12}
          className={`search_section_outer_container mb-4`}
        >
          {/* Hide Already Seen */}
          <Grid
            container
            lg={12}
            sm={8}
            xs={12}
            className={`search_section_outer_container search_section_outer_container_border`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Typography
              sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
              variant="h6"
            >
              Hide Already Seen:
            </Typography>
            <FormControlLabel
              control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
              // label="iOS style"
            />
          </Grid>
          {/* Verified properties */}
          <Grid
            container
            lg={12}
            sm={8}
            xs={12}
            className="search_section_outer_container search_section_outer_container_border"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Typography
              sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
              variant="h6"
            >
              Verified Properties:
            </Typography>

            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={verifiedPropertiesFilter}
                  onChange={(e) =>
                    setVerifiedPropertiesFilter(e.target.checked)
                  }
                />
              }
            />
          </Grid>

          {/* Budget */}
          <Grid
            container
            className={`search_section_outer_container d-${
              propertyType === "sell" || propertyTypePageFilter === "Sell"
                ? "none"
                : "block"
            }`}
          >
            <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="p-0"
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontWeight: "600",
                    color: "rgb(9, 30, 66)",
                  }}
                  variant="h6"
                >
                  Budget:
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="p-0">
                <ThemeProvider theme={theme}>
                  <Grid container spacing={2} alignItems="center">
                    {/* <Grid item>
                      <Typography>Min: {minRentBudget}</Typography>
                    </Grid> */}
                    <Grid item xs>
                      <Slider
                        value={rentBudgetRange}
                        onChange={rentBudgetHandleChange}
                        valueLabelDisplay="on"
                        valueLabelFormat={(value) => value}
                        min={0}
                        max={500000}
                        step={100}
                        color="green"
                        className="mt-4"
                      />
                    </Grid>
                    {/* <Grid item>
                      <Typography>Max: {maxRentBudget}</Typography>
                    </Grid> */}
                  </Grid>
                </ThemeProvider>

                <Grid
                  container
                  className={styles.search_button_container2}
                  lg={12}
                  sm={12}
                  xs={10}
                >
                  <Grid
                    lg={5}
                    sm={3}
                    xs={5}
                    style={{ padding: "5px 0", margin: "10px" }}
                  >
                    <Box>
                      <TextField
                        label="Max Rent"
                        variant="outlined"
                        value={minRentBudget} // Shows maxRentBudget dynamically
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">₹</InputAdornment>
                          ), // Units for currency
                          readOnly: true, // Prevent user from editing manually
                        }}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid
                    lg={5}
                    sm={3}
                    xs={5}
                    style={{ padding: "5px 0", margin: "10px" }}
                  >
                    <Box>
                      <TextField
                        label="Max Rent"
                        variant="outlined"
                        value={maxRentBudget} // Shows maxRentBudget dynamically
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">₹</InputAdornment>
                          ), // Units for currency
                          readOnly: true, // Prevent user from editing manually
                        }}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* Bedrooms */}
          <Grid
            container
            lg={12}
            sm={11}
            xs={12}
            className={`search_section_outer_container`}
          >
            <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="p-0"
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontWeight: "600",
                  }}
                  variant="h6"
                >
                  No. of Bedrooms:
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className="filter-modules-wrapper">
                  <Button
                    className={styles.filter_buttons}
                    onClick={() => {
                      setBedroomsFilter("1 BHK");
                    }}
                    style={
                      bedroomsFilter === "1 BHK"
                        ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                        : {}
                    }
                  >
                    + 1BHK/1RK
                  </Button>
                  <Button
                    className={styles.filter_buttons}
                    onClick={() => {
                      setBedroomsFilter("2 BHK");
                    }}
                    style={
                      bedroomsFilter === "2 BHK"
                        ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                        : {}
                    }
                  >
                    + 2 BHK
                  </Button>
                  <Button
                    className={styles.filter_buttons}
                    onClick={() => {
                      setBedroomsFilter("3 BHK");
                    }}
                    style={
                      bedroomsFilter === "3 BHK"
                        ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                        : {}
                    }
                  >
                    + 3 BHK
                  </Button>
                  <Button
                    className={styles.filter_buttons}
                    onClick={() => {
                      setBedroomsFilter("4 BHK");
                    }}
                    style={
                      bedroomsFilter === "4 BHK"
                        ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                        : {}
                    }
                  >
                    + 4 BHK
                  </Button>
                  <Button
                    className={styles.filter_buttons}
                    onClick={() => {
                      setBedroomsFilter("5 BHK");
                    }}
                    style={
                      bedroomsFilter === "5 BHK"
                        ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                        : {}
                    }
                  >
                    + 5+ BHK
                  </Button>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* Tenant Type/Available for */}
          <Grid
            container
            lg={12}
            sm={8}
            xs={12}
            className={`search_section_outer_container d-${
              propertyType === "sell" || propertyTypePageFilter === "Sell"
                ? "none"
                : "block"
            }`}
          >
            <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="p-0"
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontWeight: "600",
                  }}
                  variant="h6"
                >
                  Tenant Type:
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="p-0">
                <Grid container className="filter-modules-wrapper">
                  <Button
                    className={styles.filter_buttons}
                    onClick={() => setTenantTypeFilter("Family")}
                    style={
                      tenantTypeFilter === "Family"
                        ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                        : {}
                    }
                  >
                    + Family
                  </Button>
                  <Button
                    className={styles.filter_buttons}
                    onClick={() => setTenantTypeFilter("Single Woman")}
                    style={
                      tenantTypeFilter === "Single Woman"
                        ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                        : {}
                    }
                  >
                    + Single Woman
                  </Button>
                  <Button
                    className={styles.filter_buttons}
                    onClick={() => setTenantTypeFilter("Single Man")}
                    style={
                      tenantTypeFilter === "Single Man"
                        ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                        : {}
                    }
                  >
                    + Single Man
                  </Button>
                  <Button
                    className={styles.filter_buttons}
                    onClick={() =>
                      setTenantTypeFilter("Tenants with Company Lease")
                    }
                    style={
                      tenantTypeFilter === "Tenants with Company Lease"
                        ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                        : {}
                    }
                  >
                    + Tenants with Company Lease
                  </Button>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: "600",
              padding: "30px 0px",
            }}
            variant="h6"
          >
            Type Of Property:
          </Typography>
          <Grid container className="filter-modules-wrapper">
            <Button
              className={styles.filter_buttons}
              style={
                propertyTypePageFilter === "Rent"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
              onClick={() => {
                setPropertyTypePageFilter("Rent");
                setDisableParamsPropertyType(true);
              }}
            >
              Rent
            </Button>
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                setPropertyTypePageFilter("Sell");
                setDisableParamsPropertyType(true);
              }}
              style={
                propertyTypePageFilter === "Sell"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              Buy
            </Button>
          </Grid>
        </Grid>

        {/* Furnishing */}
        <Grid container className={`search_section_outer_container`}>
          <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="p-0"
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
                variant="h6"
              >
                Furnishing Status:
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="p-0">
              <Grid container className="filter-modules-wrapper">
                <Button
                  className={styles.filter_buttons}
                  onClick={() => {
                    // filteredProperties("Furnished");
                    setfurnishedFilter("Fully Furnished");
                  }}
                  style={
                    furnishedFilter === "Fully Furnished"
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + Fully Furnished
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => {
                    // filteredProperties("Semi Furnished");
                    setfurnishedFilter("Semi Furnished");
                  }}
                  style={
                    furnishedFilter === "Semi Furnished"
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + Semi Furnished
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => {
                    // filteredProperties("Unfurnished");
                    setfurnishedFilter("Unfurnished");
                  }}
                  style={
                    furnishedFilter === "Unfurnished"
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + Unfurnished
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/*Localities Model*/}
        <Grid lg={12} sm={8} xs={12}>
          <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="p-0"
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "28.9px",
                }}
                variant="h6"
              >
                Localities
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="p-0">
              {LocalityData.slice(0, 5).map((val) => {
                return (
                  <>
                    <label>
                      <input
                        type="checkbox"
                        onChange={(e) => handleSuggestionClick(val, e)}
                      />
                      &nbsp;&nbsp;
                      <span>{val.name}</span>
                    </label>
                    <br />
                  </>
                );
              })}
              <Button
                variant="text"
                className="p-0"
                sx={{
                  textTransform: "capitalize",
                  fontSize: "16px",
                  color: "black",
                }}
                onClick={handleClickOpen1("paper")}
              >
                <SearchIcon />
                More Localities
              </Button>
            </AccordionDetails>
          </Accordion>
          <Dialog
            open={openM}
            onClose={handleClose1}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{ height: "100%", marginTop: "3rem" }}
            className="locality-filter-popup"
          >
            <div style={{ textAlign: "end", padding: "5px" }}>
              <CloseIcon onClick={handleClose1} />
            </div>
            <DialogTitle id="scroll-dialog-title">
              Select Localities
              <Box className="w-100 mt-2">
                <InputBase
                  sx={{
                    flex: 1,
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                    color: "#222222",
                    border: "1px solid #22222259",
                  }}
                  placeholder="Search Location"
                  value={inputValue}
                  onChange={handleInputChange}
                  className="px-3 py-1 locationBox w-100"
                />
              </Box>
            </DialogTitle>

            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                {suggestions !== undefined &&
                suggestions !== null &&
                suggestions.length !== 0
                  ? suggestions.map((val) => {
                      return (
                        <>
                          <label>
                            <input
                              type="checkbox"
                              onChange={(e) => handleSuggestionClick(val, e)}
                            />
                            &nbsp;&nbsp;
                            <span>{val.name}</span>
                          </label>
                          <br />
                        </>
                      );
                    })
                  : // <label
                    //   style={{
                    //     textAlign: "start",
                    //     listStyle: "none",
                    //   }}
                    // >
                    //   No Result Found
                    // </label>
                    LocalityData.slice(5, 10).map((val) => {
                      return (
                        <>
                          <label>
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleSuggestionClickModal(val, e)
                              }
                            />
                            &nbsp;&nbsp;
                            <span>{val.name}</span>
                          </label>
                          <br />
                        </>
                      );
                    })}
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Grid>
        {/* Bathrooms */}
        <Grid
          container
          lg={12}
          sm={8}
          xs={12}
          className={`search_section_outer_container d-${
            propertyType === "sell" || propertyTypePageFilter === "Sell"
              ? "none"
              : "block"
          }`}
        >
          <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="p-0"
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
                variant="h6"
              >
                No. Of Bathrooms:
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="p-0">
              <Grid container className="filter-modules-wrapper">
                <Button
                  className={styles.filter_buttons}
                  onClick={() => {
                    setBathroomsFilter(1);
                  }}
                  style={
                    bathroomsFilter === 1
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + 1
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => {
                    setBathroomsFilter(2);
                  }}
                  style={
                    bathroomsFilter === 2
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + 2
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => {
                    setBathroomsFilter(3);
                  }}
                  style={
                    bathroomsFilter === 3
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + 3
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => {
                    setBathroomsFilter(4);
                  }}
                  style={
                    bathroomsFilter === 4
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + 4
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => {
                    setBathroomsFilter(5);
                  }}
                  style={
                    bathroomsFilter === 5
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + 5
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* Properties With Photo */}
        <Grid
          container
          lg={12}
          sm={8}
          xs={12}
          className={`search_section_outer_container`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Typography
            sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
            variant="h6"
          >
            Properties With Photo:
          </Typography>
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={propertiesWithPhotoFilter}
                onChange={(e) => setPropertiesWithPhotoFilter(e.target.checked)}
              />
            }
          />
        </Grid>

        {/* Area */}
        <Grid container className={`search_section_outer_container`}>
          <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="p-0"
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
                variant="h6"
              >
                Area (Sq. Ft.):
              </Typography>
            </AccordionSummary>
            {/* <Grid container className={`applied-filters`}>
            <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
              Applied Area Range: {minArea} - {maxArea} Sq. Ft.
            </Typography>
          </Grid> */}
            <AccordionDetails className="p-0">
              <ThemeProvider theme={theme}>
                <Grid container spacing={2} alignItems="center">
                  {/* <Grid item>
                    <Typography>Min: {minArea}</Typography>
                  </Grid> */}
                  <Grid item xs>
                    <Slider
                      value={areaRange}
                      onChange={areaHandleChange}
                      valueLabelDisplay="on"
                      valueLabelFormat={(value) => value}
                      min={0}
                      max={20000}
                      color="green"
                      className="mt-4"
                    />
                  </Grid>
                  {/* <Grid item>
                    <Typography>Max: {maxArea}</Typography>
                  </Grid> */}
                </Grid>
              </ThemeProvider>

              <Grid
                container
                className={styles.search_button_container2}
                lg={12}
                sm={12}
                xs={10}
              >
                <Grid
                  lg={5}
                  sm={3}
                  xs={5}
                  style={{ padding: "5px 0", margin: "10px" }}
                >
                  <Box>
                    <TextField
                      label="Min Area"
                      variant="outlined"
                      value={areaRange[0]} // Shows minArea dynamically
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            sq. ft.
                          </InputAdornment>
                        ), // Units
                        readOnly: true, // Prevent user from editing manually
                      }}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid
                  lg={5}
                  sm={3}
                  xs={5}
                  style={{ padding: "5px 0", margin: "10px" }}
                >
                  <Box>
                    <TextField
                      label="Max Area"
                      placeholder="Max Area"
                      variant="outlined"
                      value={areaRange[1]} // Shows maxArea dynamically
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            sq. ft.
                          </InputAdornment>
                        ), // Units
                        readOnly: true, // Prevent user from editing manually
                      }}
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {/* Society/Property-Id Filter Input */}
        {/* <Grid
          lg={12}
          sm={8}
          xs={12}
          // className={`d-${proprertyKeys === "list" ? "block" : "none"}`}
        >
          <div className="searchData-field-wrapper">
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value5}>
                <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange5}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Society" value="1" />
                    <Tab label="Property Id" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  {" "}
                  <OutlinedInput
                    fullWidth
                    type="text"
                    id="outlined-adornment-weight"
                    placeholder="Search Societies..."
                    // endAdornment={
                    //   <InputAdornment position="end">
                    //     <SearchIcon sx={{ color: "#222" }} />
                    //   </InputAdornment>
                    // }
                    style={{ border: "1px solid #222222" }}
                    // value={societyData}
                    // onChange={handleSocietyFilter}
                    onChange={handleSocietyInputChange}
                    // disabled={proprertyKeys === "list" ? false : true}
                  />
                  {showSocietySuggestions && (
                    <ul
                      className={`searchData-wrapper d-${
                        societyData.length !== 0 ? "block" : "none"
                      }`}
                    >
                      {societyFilteredData !== undefined &&
                      societyFilteredData !== null &&
                      societyFilteredData.length !== 0 ? (
                        societyFilteredData.map((val, index) => (
                          <li
                            style={{ textAlign: "start", listStyle: "none" }}
                            key={index}
                            onClick={() =>
                              handleSocietySuggestionClick(val.soceityName)
                            }
                          >
                            {val.soceityName}
                          </li>
                        ))
                      ) : (
                        <li style={{ textAlign: "start", listStyle: "none" }}>
                          No Result Found
                        </li>
                      )}
                    </ul>
                  )}
                </TabPanel>
                <TabPanel value="2">
                  <OutlinedInput
                    fullWidth
                    type="text"
                    id="outlined-adornment-weight"
                    placeholder="Search Property Id..."
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchIcon
                          sx={{ color: "#222", cursor: "pointer" }}
                          onClick={() => GetPropertyById()}
                        />
                      </InputAdornment>
                    }
                    style={{ border: "1px solid #222222" }}
                    // value={propertyIdFilter}
                    // onChange={(e) => setPropertyIdFilter(e.target.value)}
                    onChange={handleIdInputChange}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </Grid> */}
        {/* Filter List */}
        {/* <Grid
          lg={12}
          sm={8}
          xs={12}
          sx={{
            borderBottom: "solid 1px rgba(210, 210, 210, 1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "1.5rem",
              paddingBottom: "1.5rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "28.9px",
              }}
            >
              Filters
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "20px",
                color: "rgba(31, 64, 105, 1)",
              }}
            >
              <span
                onClick={() => {
                  handleRefresh();
                }}
                // onClick={() => {
                //   setPropertyTypePageFilter("");
                //   setLocalitiesData("");
                //   setBuildupTypeFilter("");
                //   setTenantTypeFilter("");
                //   setVerifiedPropertiesFilter("");
                //   setLowestAgeFilter();
                //   setHighestAgeFilter();
                //   // setSocietyFilter("");
                //   setSocietyData("");
                //   setWithownerFilter("");
                //   setIndependentPropertiesFilter("");
                //   setBedroomsFilter("");
                //   setBathroomsFilter();
                //   setPropertiesWithPhotoFilter("");

                //   setAreaRange([0, 20000]);
                //   setBudgetRange([0, 500000000]);
                //   setfurnishedFilter("");
                //   setConstructionStatusFilter("");
                //   setPostedByFilter("");
                //   setPurchaseTypeFilter("");
                //   setRERAApprovedFilter("");
                //   setFacingFilter("");
                //   setSelectedDate("");
                //   setCampusTypeFilter("");

                //   setPropertyIdFilter("");
                //   handleRefresh();
                // }}
                // style={{
                //   cursor: "pointer",
                // }}
              >
                Clear
              </span>
            </Typography>
          </Box>
          <Box>
            {propertyIdFilterStatus && (
              <Button
                className={styles.filter_buttons}
                endIcon={<ClearIcon />}
                sx={{ margin: "0 10px 10px 0" }}
                onClick={() => {
                  setPropertyIdFilter("");
                  handleRefresh();
                }}
              >
                {propertyIdFilter !== ""
                  ? propertyIdFilter
                  : "Id Filter Activated"}
              </Button>
            )}
            {filtersActivated && (
              <Link to="/properties/list/all">
                <Button
                  className={styles.filter_buttons}
                  endIcon={<ClearIcon />}
                  sx={{ margin: "0 10px 10px 0" }}
                  onClick={() => {
                    handleRefresh();
                  }}
                >
                  Filters Activated
                </Button>
              </Link>
            )}
          </Box>
        </Grid> */}

        {/* Only Show On Sell Filter***** */}
        <Grid
          container
          className={`search_section_outer_container d-${
            propertyType === "sell" || propertyTypePageFilter === "Sell"
              ? "block"
              : "none"
          }`}
        >
          <Typography
            sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
            variant="h6"
          >
            Budget:
          </Typography>
          <ThemeProvider theme={theme}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography>Min: {minSellBudget}</Typography>
              </Grid>
              <Grid item xs>
                <Slider
                  value={sellBudgetRange}
                  onChange={sellBudgetHandleChange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => value}
                  min={0}
                  max={50000000}
                  color="green"
                  className="mt-4"
                />
              </Grid>
              <Grid item>
                <Typography>Max: {maxSellBudget}</Typography>
              </Grid>
            </Grid>
          </ThemeProvider>
          <Grid
            container
            className={styles.search_button_container2}
            lg={12}
            sm={12}
            xs={10}
          >
            <Grid lg={4.5} sm={3} xs={5} style={{ padding: "5px 0" }}>
              <Box>
                <TextField
                  size="small"
                  className="w-75"
                  id="outlined-basic"
                  placeholder="Min"
                  variant="outlined"
                  name="minArea"
                  type="number"
                  onChange={handleSellLowBudgetInputChange}
                />
              </Box>
            </Grid>
            <Grid lg={4.5} sm={3} xs={5} style={{ padding: "5px 0" }}>
              <Box>
                <TextField
                  size="small"
                  className="w-75"
                  id="outlined-basic"
                  placeholder="Max"
                  variant="outlined"
                  name="maxArea"
                  type="number"
                  onChange={handleSellHighBudgetInputChange}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* Buildup Type */}
        <Grid container xs={12} className="search_section_outer_container">
          <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="p-0"
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
                variant="h6"
              >
                Buildup Type:
              </Typography>
            </AccordionSummary>
            {/* {buildupTypeFilter && (
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "20px",
                  fontWeight: "400",
                  marginTop: "10px",
                }}
              >
                Applied Filter: {buildupTypeFilter}
              </Typography>
            )} */}
            <AccordionDetails className="p-0">
              <Grid container className="filter-modules-wrapper">
                <Button
                  className={styles.filter_buttons}
                  onClick={() => setBuildupTypeFilter("Residential Land")}
                  style={
                    buildupTypeFilter === "Residential Land"
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + Residential Land
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => setBuildupTypeFilter("Studio")}
                  style={
                    buildupTypeFilter === "Studio"
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + Studio
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => setBuildupTypeFilter("Residential Apartment")}
                  style={
                    buildupTypeFilter === "Residential Apartment"
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + Residential Apartment
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => setBuildupTypeFilter("Builder")}
                  style={
                    buildupTypeFilter === "Builder"
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + Builder
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => setBuildupTypeFilter("Independent House")}
                  style={
                    buildupTypeFilter === "Independent House"
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + Independent House
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => setBuildupTypeFilter("Floor")}
                  style={
                    buildupTypeFilter === "Floor"
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + Floor
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => setBuildupTypeFilter("Villa Independent")}
                  style={
                    buildupTypeFilter === "Villa Independent"
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + Villa Independent
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => setBuildupTypeFilter("Farm House")}
                  style={
                    buildupTypeFilter === "Farm House"
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + Farm House
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => setBuildupTypeFilter("Serviced Apartment")}
                  style={
                    buildupTypeFilter === "Serviced Apartment"
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  + Serviced Apartment
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* With Owner */}
        <Grid
          container
          lg={12}
          sm={8}
          xs={12}
          className={`search_section_outer_container d-${
            propertyType === "sell" || propertyTypePageFilter === "Sell"
              ? "none"
              : "block"
          }`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={withownerFilter}
            name="withowner"
          >
            <div>
              <FormControlLabel
                value="With Owner"
                control={
                  <Radio
                    sx={{
                      color: "rgba(34, 34, 34, 1)",
                      "&.Mui-checked": {
                        color: "rgba(2, 201, 136, 1)",
                      },
                    }}
                    onClick={() => {
                      setWithownerFilter("With Owner");
                    }}
                  />
                }
                label="With Owner"
              />
              <FormControlLabel
                value="Without Owner"
                control={
                  <Radio
                    sx={{
                      color: "rgba(34, 34, 34, 1)",
                      "&.Mui-checked": {
                        color: "rgba(2, 201, 136, 1)",
                      },
                    }}
                    onClick={() => {
                      setWithownerFilter("Without Owner");
                    }}
                  />
                }
                label="Without Owner"
              />
            </div>
          </RadioGroup>
        </Grid>

        {/* Available From */}
        <Grid
          container
          lg={12}
          sm={8}
          xs={12}
          className={`search_section_outer_container d-${
            propertyType === "sell" || propertyTypePageFilter === "Sell"
              ? "none"
              : "block"
          }`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: "600",
              marginBottom: "20px",
            }}
            variant="h6"
          >
            Available From:
          </Typography>
          <div className="select-date-inputWrapper">
            <div className="date-input">
              <input
                type="date"
                id="dateInput"
                name="dateInput"
                value={selectedDate}
                min={today}
                onChange={handleDateChange}
                required
              />
              {selectedDate && (
                <button className="clear-button" onClick={clearDate}>
                  &#10006;
                </button>
              )}
            </div>
          </div>
        </Grid>
        {/* Age Of Property */}
        <Grid
          container
          lg={12}
          sm={8}
          xs={12}
          className={`search_section_outer_container d-${
            propertyType === "sell" || propertyTypePageFilter === "Sell"
              ? "none"
              : "block"
          }`}
        >
          <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="p-0"
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
                variant="h6"
              >
                Age Of Property:
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="p-0">
              <Grid container className="filter-modules-wrapper">
                <Button
                  className={styles.filter_buttons}
                  onClick={() => {
                    setLowestAgeFilter(0);
                    setHighestAgeFilter(1);
                  }}
                  style={
                    lowestAgeFilter === 0 && highestAgeFilter === 1
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  0-1 Years Old
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => {
                    setLowestAgeFilter(1);
                    setHighestAgeFilter(5);
                  }}
                  style={
                    lowestAgeFilter === 1 && highestAgeFilter === 5
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  1-5 Years Old
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => {
                    setLowestAgeFilter(5);
                    setHighestAgeFilter(10);
                  }}
                  style={
                    lowestAgeFilter === 5 && highestAgeFilter === 10
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  5-10 Years Old
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => {
                    setLowestAgeFilter(10);
                    setHighestAgeFilter();
                  }}
                  style={
                    lowestAgeFilter === 10
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  10+ Years Old
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {/* Independent */}
        <Grid
          container
          lg={12}
          sm={8}
          xs={12}
          className={`search_section_outer_container d-${
            propertyType === "sell" || propertyTypePageFilter === "Sell"
              ? "none"
              : "block"
          }`}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: "600",
              }}
              variant="h6"
            >
              Independent:
            </Typography>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={independentPropertiesFilter}
                  onChange={(e) =>
                    setIndependentPropertiesFilter(e.target.checked)
                  }
                />
              }
            />
          </Box>
        </Grid>

        {/* Premium Properties */}
        <Grid
          container
          lg={12}
          sm={8}
          xs={12}
          className={`search_section_outer_container`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Typography
            sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
            variant="h6"
          >
            Premium:
          </Typography>
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={premiumFilter}
                onChange={(e) => setPremiumFilter(e.target.checked)}
              />
            }
          />
        </Grid>
        {/* Construction Status */}
        <Grid
          container
          className={`search_section_outer_container d-${
            propertyType === "sell" || propertyTypePageFilter === "Sell"
              ? "block"
              : "none"
          }`}
        >
          <Typography
            sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "400" }}
          >
            Construction Status:
          </Typography>
          <Grid container className="filter-modules-wrapper">
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                setConstructionStatusFilter("Ready to Move");
              }}
              style={
                constructionStatusFilter === "Ready to Move"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              Ready to Move
            </Button>
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                setConstructionStatusFilter("Under Construction");
              }}
              style={
                constructionStatusFilter === "Under Construction"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              Under Construction
            </Button>
          </Grid>
        </Grid>
        {/* Posted By */}
        <Grid
          container
          className={`search_section_outer_container d-${
            propertyType === "sell" || propertyTypePageFilter === "Sell"
              ? "block"
              : "none"
          }`}
        >
          <Typography
            sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "400" }}
          >
            Posted By:
          </Typography>
          <Grid container className="filter-modules-wrapper">
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                // filteredProperties("Furnished");
                setPostedByFilter("Owner");
              }}
              style={
                postedByFilter === "Owner"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              Owner
            </Button>
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                // filteredProperties("Semi Furnished");
                setPostedByFilter("Builder");
              }}
              style={
                postedByFilter === "Builder"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              Builder
            </Button>
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                // filteredProperties("Semi Furnished");
                setPostedByFilter("Dealer");
              }}
              style={
                postedByFilter === "Dealer"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              Dealer
            </Button>
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                // filteredProperties("Semi Furnished");
                setPostedByFilter("Feature Dealer");
              }}
              style={
                postedByFilter === "Feature Dealer"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              Feature Dealer
            </Button>
          </Grid>
        </Grid>
        {/* Purchase Type */}
        <Grid
          container
          className={`search_section_outer_container d-${
            propertyType === "sell" || propertyTypePageFilter === "Sell"
              ? "block"
              : "none"
          }`}
        >
          <Typography
            sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "400" }}
          >
            Purchase Type:
          </Typography>
          <Grid container className="filter-modules-wrapper">
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                // filteredProperties("Furnished");
                setPurchaseTypeFilter("Resell");
              }}
              style={
                purchaseTypeFilter === "Resell"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              Resell
            </Button>
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                // filteredProperties("Semi Furnished");
                setPurchaseTypeFilter("New Booking");
              }}
              style={
                purchaseTypeFilter === "New Booking"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              New Booking
            </Button>
          </Grid>
        </Grid>
        {/* RERA Approved */}
        <Grid
          container
          className={`search_section_outer_container d-${
            propertyType === "sell" || propertyTypePageFilter === "Sell"
              ? "block"
              : "none"
          }`}
        >
          <Typography
            sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "400" }}
          >
            RERA Approved:
          </Typography>
          <Grid container className="filter-modules-wrapper">
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                // filteredProperties("Semi Furnished");
                setRERAApprovedFilter("RERA Approved Properties");
              }}
              style={
                RERAApprovedFilter === "RERA Approved Properties"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              RERA Approved Properties
            </Button>
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                // filteredProperties("Semi Furnished");
                setRERAApprovedFilter("RERA Registered Dealers");
              }}
              style={
                purchaseTypeFilter === "RERA Registered Dealers"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              RERA Registered Dealers
            </Button>
          </Grid>
        </Grid>
        {/* Facing */}
        <Grid
          container
          className={`search_section_outer_container d-${
            propertyType === "sell" || propertyTypePageFilter === "Sell"
              ? "block"
              : "none"
          }`}
        >
          <Typography
            sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "400" }}
          >
            Facing:
          </Typography>
          <Grid container className="filter-modules-wrapper">
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                // filteredProperties("Semi Furnished");
                setFacingFilter("East");
              }}
              style={
                facingFilter === "East"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              East
            </Button>
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                // filteredProperties("Semi Furnished");
                setFacingFilter("West");
              }}
              style={
                facingFilter === "West"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              West
            </Button>
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                // filteredProperties("Semi Furnished");
                setFacingFilter("North");
              }}
              style={
                facingFilter === "North"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              North
            </Button>
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                // filteredProperties("Semi Furnished");
                setFacingFilter("South");
              }}
              style={
                facingFilter === "South"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              South
            </Button>
          </Grid>
        </Grid>
        {/* Campus Type */}
        <Grid
          container
          className={`search_section_outer_container d-${
            propertyType === "sell" || propertyTypePageFilter === "Sell"
              ? "none"
              : "block"
          }`}
        >
          <Typography
            sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
            variant="h6"
          >
            Campus Type:
          </Typography>
          <Grid container className="filter-modules-wrapper">
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                setCampusTypeFilter("Open");
              }}
              style={
                campusTypeFilter === "Open"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              Open
            </Button>
            <Button
              className={styles.filter_buttons}
              onClick={() => {
                // filteredProperties("Semi Furnished");
                setCampusTypeFilter("Covered");
              }}
              style={
                campusTypeFilter === "Covered"
                  ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                  : {}
              }
            >
              Covered
            </Button>
          </Grid>
        </Grid>

        {/* Submit Buttons------------------------------------------------------------- */}
        <Grid container className={`search_section_outer_container `}>
          <Grid container className="filter-modules-wrapper">
            <Button
              variant="contained"
              style={{
                backgroundColor: "rgb(237, 114, 37)",
                color: "#FFF",
                textTransform: "capitalize",
                paddingTop: ".5rem",
                paddingBottom: ".5rem",
              }}
              className="px-md-4 px-2"
              onClick={() => {
                filteredProperties();
                setFiltersActivated(true);
                toggleDrawer();
                setTimeout(() => {
                  const queryParams = {
                    propertyType:
                      propertyTypePageFilter !== null &&
                      propertyTypePageFilter !== undefined &&
                      propertyTypePageFilter.length !== 0
                        ? propertyTypePageFilter
                        : propertyTypePageFilter.length === 0 &&
                          propertyType !== "all"
                        ? propertyType
                        : "",
                    addressStr:
                      localitiesData !== null &&
                      localitiesData !== undefined &&
                      localitiesData.length !== 0
                        ? localitiesData
                        : localitiesData.length === 0 &&
                          proprertyKeys !== "list"
                        ? proprertyKeys
                        : "",
                    minPrice:
                      propertyType === "sell" ||
                      propertyTypePageFilter === "Sell"
                        ? sellBudgetRange[0]
                        : rentBudgetRange[0],
                    maxPrice:
                      propertyType === "sell" ||
                      propertyTypePageFilter === "Sell"
                        ? sellBudgetRange[1]
                        : rentBudgetRange[1],
                    furnishing: furnishedFilter,
                    available_for: tenantTypeFilter,
                    bhk_of_house: bedroomsFilter,
                    city:
                      cityFilter !== null && cityFilter !== undefined
                        ? cityFilter
                        : "",
                    buildUp_type: buildupTypeFilter,
                    // verify_property:
                    //   verifiedPropertiesFilter === true
                    //     ? 1
                    //     : verifiedPropertiesFilter === false
                    //     ? ""
                    //     : "",
                    // minAge:
                    //   lowestAgeFilter === undefined ? "" : lowestAgeFilter,
                    // maxAge:
                    //   highestAgeFilter === undefined ? "" : highestAgeFilter,
                    societyStr: societyData,
                    owner_availability:
                      withownerFilter !== null &&
                      withownerFilter !== undefined &&
                      withownerFilter !== ""
                        ? withownerFilter
                        : "",
                    // independent:
                    //   independentPropertiesFilter === true
                    //     ? 1
                    //     : independentPropertiesFilter === false
                    //     ? ""
                    //     : "",
                    // bathrooms:
                    //   bathroomsFilter !== null && bathroomsFilter !== undefined
                    //     ? bathroomsFilter
                    //     : "",
                    // propertyImages:
                    //   propertiesWithPhotoFilter === true
                    //     ? true
                    //     : propertiesWithPhotoFilter === false
                    //     ? ""
                    //     : "",
                    // minArea: areaRange[0],
                    // maxArea: areaRange[1],
                    construction_status:
                      constructionStatusFilter !== null &&
                      constructionStatusFilter !== undefined &&
                      constructionStatusFilter !== ""
                        ? constructionStatusFilter
                        : "",
                    posted_by:
                      postedByFilter !== null &&
                      postedByFilter !== undefined &&
                      postedByFilter !== ""
                        ? postedByFilter
                        : "",
                    purchase_type:
                      purchaseTypeFilter !== null &&
                      purchaseTypeFilter !== undefined &&
                      purchaseTypeFilter !== ""
                        ? purchaseTypeFilter
                        : "",
                    rera_approved:
                      RERAApprovedFilter !== null &&
                      RERAApprovedFilter !== undefined &&
                      RERAApprovedFilter !== ""
                        ? RERAApprovedFilter
                        : "",
                    facing:
                      facingFilter !== null &&
                      facingFilter !== undefined &&
                      facingFilter !== ""
                        ? facingFilter
                        : "",
                    // startDate: selectedDate,
                    // premium:
                    //   premiumFilter === true
                    //     ? 1
                    //     : premiumFilter === false
                    //     ? ""
                    //     : "",
                    // campus_type:
                    //   campusTypeFilter !== null &&
                    //   campusTypeFilter !== undefined &&
                    //   campusTypeFilter !== ""
                    //     ? campusTypeFilter
                    //     : "",
                  };
                  const filteredQueryParams = Object.fromEntries(
                    Object.entries(queryParams).filter(
                      ([_, value]) => value !== ""
                    )
                  );
                  const queryString = new URLSearchParams(
                    filteredQueryParams
                  ).toString();
                  navigate(`?${queryString}`);
                }, 1000);
              }}
            >
              Filter Now
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const NewfilterData = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
      <>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            height: 600,
            overflowY: "auto",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab label="Hide already seen" {...a11yProps(0)} />
            <Tab label="Verified" {...a11yProps(1)} />
            <Tab label="Budget" {...a11yProps(2)} />
            <Tab label="Property Type" {...a11yProps(3)} />
            <Tab label="BHK" {...a11yProps(4)} />
            <Tab label="Construction Status" {...a11yProps(5)} />
            <Tab label="Posted by" {...a11yProps(6)} />
            <Tab label="Area" {...a11yProps(7)} />
            {/* <Tab label="Localities" {...a11yProps(8)} /> */}
            {/* <Tab label="New Projects/Societies" {...a11yProps(9)} /> */}
            <Tab label="Purchase Type" {...a11yProps(8)} />
            {/* <Tab label="Amenities" {...a11yProps(9)} /> */}
            <Tab label="Photos" {...a11yProps(9)} />
            {/* <Tab label="Videos" {...a11yProps(10)} /> */}
            <Tab label="Bathrooms" {...a11yProps(10)} />
            <Tab label="Furnishing status" {...a11yProps(11)} />
            <Tab label="RERA Approved" {...a11yProps(12)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            {/* Hide Already Seen */}
            <Grid
              container
              lg={12}
              sm={8}
              xs={12}
              className={`search_section_outer_container`}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
                variant="h6"
              >
                Hide Already Seen:
              </Typography>
              <FormControlLabel
                control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                // label="iOS style"
              />
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* Verified properties */}
            <Grid
              container
              lg={12}
              sm={8}
              xs={12}
              className="search_section_outer_container"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
                variant="h6"
              >
                Verified Properties:
              </Typography>

              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={verifiedPropertiesFilter}
                    onChange={(e) =>
                      setVerifiedPropertiesFilter(e.target.checked)
                    }
                  />
                }
              />
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* ***** budget ****** */}
            <Grid
              container
              className={`search_section_outer_container d-${
                propertyType === "sell" || propertyTypePageFilter === "Sell"
                  ? "none"
                  : "block"
              }`}
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
                variant="h6"
              >
                Budget:
              </Typography>
              <ThemeProvider theme={theme}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography>Min: {minRentBudget}</Typography>
                  </Grid>
                  <Grid item xs>
                    <Box
                      style={{
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Slider
                        value={rentBudgetRange}
                        onChange={rentBudgetHandleChange}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) => value}
                        min={0}
                        max={500000}
                        color="green"
                        orientation="vertical" // Ensure this is vertical
                        className="mt-4"
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>Max: {maxRentBudget}</Typography>
                  </Grid>
                </Grid>
              </ThemeProvider>

              <Grid
                container
                className={styles.search_button_container2}
                lg={12}
                sm={12}
                xs={10}
              >
                <Grid lg={4.5} sm={3} xs={5} style={{ padding: "5px 0" }}>
                  <Box>
                    <TextField
                      label="Min Rent"
                      variant="outlined"
                      value={minRentBudget} // Shows minRentBudget dynamically
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">₹</InputAdornment>
                        ), // Units for currency
                        readOnly: true, // Prevent user from editing manually
                      }}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid lg={4.5} sm={3} xs={5} style={{ padding: "5px 0" }}>
                  <Box>
                    <TextField
                      label="Max Rent"
                      variant="outlined"
                      value={maxRentBudget} // Shows maxRentBudget dynamically
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">₹</InputAdornment>
                        ), // Units for currency
                        readOnly: true, // Prevent user from editing manually
                      }}
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={3}>
            {/* Type Of Property */}
            <Grid
              container
              lg={12}
              sm={8}
              xs={12}
              className={`search_section_outer_container`}
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "400" }}
                variant="h6"
              >
                Type Of Property:
              </Typography>
              <Grid
                container
                className="filter-modules-wrapper"
                sx={{ display: "grid" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={propertyTypePageFilter === "Rent"}
                      onChange={() => {
                        setPropertyTypePageFilter("Rent");
                        setDisableParamsPropertyType(true);
                      }}
                      sx={{
                        color: "rgba(2, 201, 136, 1)",
                        "&.Mui-checked": {
                          color: "rgba(2, 201, 136, 1)",
                        },
                      }}
                    />
                  }
                  label="Rent"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={propertyTypePageFilter === "Sell"}
                      onChange={() => {
                        setPropertyTypePageFilter("Sell");
                        setDisableParamsPropertyType(true);
                      }}
                      sx={{
                        color: "rgba(2, 201, 136, 1)",
                        "&.Mui-checked": {
                          color: "rgba(2, 201, 136, 1)",
                        },
                      }}
                    />
                  }
                  label="Buy"
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={4}>
            {/* Bedrooms */}
            <Grid
              container
              lg={12}
              sm={11}
              xs={12}
              className={`search_section_outer_container`}
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "400" }}
              >
                Bedrooms:
              </Typography>
              <Grid
                container
                className="filter-modules-wrapper"
                sx={{ display: "grid" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bedroomsFilter === "1 BHK"}
                      onChange={() => setBedroomsFilter("1 BHK")}
                      sx={{
                        color: "rgba(2, 201, 136, 1)",
                        "&.Mui-checked": {
                          color: "rgba(2, 201, 136, 1)",
                        },
                      }}
                    />
                  }
                  label="1 BHK/1 RK"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bedroomsFilter === "2 BHK"}
                      onChange={() => setBedroomsFilter("2 BHK")}
                      sx={{
                        color: "rgba(2, 201, 136, 1)",
                        "&.Mui-checked": {
                          color: "rgba(2, 201, 136, 1)",
                        },
                      }}
                    />
                  }
                  label="2 BHK"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bedroomsFilter === "3 BHK"}
                      onChange={() => setBedroomsFilter("3 BHK")}
                      sx={{
                        color: "rgba(2, 201, 136, 1)",
                        "&.Mui-checked": {
                          color: "rgba(2, 201, 136, 1)",
                        },
                      }}
                    />
                  }
                  label="3 BHK"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bedroomsFilter === "4 BHK"}
                      onChange={() => setBedroomsFilter("4 BHK")}
                      sx={{
                        color: "rgba(2, 201, 136, 1)",
                        "&.Mui-checked": {
                          color: "rgba(2, 201, 136, 1)",
                        },
                      }}
                    />
                  }
                  label="4 BHK"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bedroomsFilter === "5 BHK"}
                      onChange={() => setBedroomsFilter("5 BHK")}
                      sx={{
                        color: "rgba(2, 201, 136, 1)",
                        "&.Mui-checked": {
                          color: "rgba(2, 201, 136, 1)",
                        },
                      }}
                    />
                  }
                  label="5+ BHK"
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={5}>
            {/* Construction Status */}
            <Grid
              container
              // className={`search_section_outer_container d-${
              //   propertyType === "sell" || propertyTypePageFilter === "Sell"
              //     ? "block"
              //     : "none"
              // }`}
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "400" }}
              >
                Construction Status:
              </Typography>
              <Grid
                container
                className="filter-modules-wrapper"
                sx={{ display: "grid" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={constructionStatusFilter === "Ready to Move"}
                      onChange={() =>
                        setConstructionStatusFilter("Ready to Move")
                      }
                      sx={{
                        color: "rgba(2, 201, 136, 1)",
                        "&.Mui-checked": {
                          color: "rgba(2, 201, 136, 1)",
                        },
                      }}
                    />
                  }
                  label="Ready to Move"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        constructionStatusFilter === "Under Construction"
                      }
                      onChange={() =>
                        setConstructionStatusFilter("Under Construction")
                      }
                      sx={{
                        color: "rgba(2, 201, 136, 1)",
                        "&.Mui-checked": {
                          color: "rgba(2, 201, 136, 1)",
                        },
                      }}
                    />
                  }
                  label="Under Construction"
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={6}>
            {/* Posted By */}
            <Grid
              container
              // className={`search_section_outer_container d-${
              //   propertyType === "sell" || propertyTypePageFilter === "Sell"
              //     ? "block"
              //     : "none"
              // }`}
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "400" }}
              >
                Posted By:
              </Typography>
              <Grid
                container
                className="filter-modules-wrapper"
                sx={{ display: "grid" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={postedByFilter === "Owner"}
                      onChange={() => setPostedByFilter("Owner")}
                      sx={{
                        color: "rgba(2, 201, 136, 1)",
                        "&.Mui-checked": {
                          color: "rgba(2, 201, 136, 1)",
                        },
                      }}
                    />
                  }
                  label="Owner"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={postedByFilter === "Builder"}
                      onChange={() => setPostedByFilter("Builder")}
                      sx={{
                        color: "rgba(2, 201, 136, 1)",
                        "&.Mui-checked": {
                          color: "rgba(2, 201, 136, 1)",
                        },
                      }}
                    />
                  }
                  label="Builder"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={postedByFilter === "Dealer"}
                      onChange={() => setPostedByFilter("Dealer")}
                      sx={{
                        color: "rgba(2, 201, 136, 1)",
                        "&.Mui-checked": {
                          color: "rgba(2, 201, 136, 1)",
                        },
                      }}
                    />
                  }
                  label="Dealer"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={postedByFilter === "Feature Dealer"}
                      onChange={() => setPostedByFilter("Feature Dealer")}
                      sx={{
                        color: "rgba(2, 201, 136, 1)",
                        "&.Mui-checked": {
                          color: "rgba(2, 201, 136, 1)",
                        },
                      }}
                    />
                  }
                  label="Feature Dealer"
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={7}>
            {/* Area */}
            <Grid container className="search_section_outer_container">
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
                variant="h6"
              >
                Area (Sq. Ft.):
              </Typography>

              <Grid container className="applied-filters">
                <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                  Applied Area Range: {minArea} - {maxArea} Sq. Ft.
                </Typography>
              </Grid>

              <ThemeProvider theme={theme}>
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography>Min: {minArea}</Typography>
                    <Slider
                      value={areaRange}
                      onChange={areaHandleChange}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => value}
                      min={0}
                      max={20000}
                      color="green"
                      orientation="vertical" // Make the slider vertical
                      style={{ height: "150px", margin: "0 20px" }} // Set height and margin for the slider
                      className="mt-4"
                    />
                    <Typography>Max: {maxArea}</Typography>
                  </Grid>
                </Grid>
              </ThemeProvider>

              <Grid
                container
                className={styles.search_button_container2}
                lg={12}
                sm={12}
                xs={10}
              >
                <Grid lg={4.5} sm={3} xs={5} style={{ padding: "5px 0" }}>
                  <Box>
                    <TextField
                      size="small"
                      className="w-75"
                      id="outlined-basic"
                      placeholder="Min"
                      variant="outlined"
                      name="minArea"
                      type="number"
                      onChange={handleLowAreaInputChange}
                    />
                  </Box>
                </Grid>
                <Grid lg={4.5} sm={3} xs={5} style={{ padding: "5px 0" }}>
                  <Box>
                    <TextField
                      size="small"
                      className="w-75"
                      id="outlined-basic"
                      placeholder="Max"
                      variant="outlined"
                      name="maxArea"
                      type="number"
                      onChange={handleHighAreaInputChange}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={8}>
            {/* Purchase Type */}
            <Grid
              container
              // className={`search_section_outer_container d-${
              //   propertyType === "sell" || propertyTypePageFilter === "Sell"
              //     ? "block"
              //     : "none"
              // }`}
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "400" }}
              >
                Purchase Type:
              </Typography>
              <Grid
                container
                className="filter-modules-wrapper"
                sx={{ display: "grid" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={purchaseTypeFilter === "Resell"}
                      onChange={() => {
                        setPurchaseTypeFilter("Resell");
                      }}
                    />
                  }
                  label="Resell"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={purchaseTypeFilter === "New Booking"}
                      onChange={() => {
                        setPurchaseTypeFilter("New Booking");
                      }}
                    />
                  }
                  label="New Booking"
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={9}>
            {/* Properties With Photo */}
            <Grid
              container
              lg={12}
              sm={8}
              xs={12}
              className={`search_section_outer_container`}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
                variant="h6"
              >
                Properties With Photo:
              </Typography>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={propertiesWithPhotoFilter}
                    onChange={(e) =>
                      setPropertiesWithPhotoFilter(e.target.checked)
                    }
                  />
                }
              />
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={10}>
            {/* Bathrooms */}
            <Grid
              container
              lg={12}
              sm={8}
              xs={12}
              className={`search_section_outer_container d-${
                propertyType === "sell" || propertyTypePageFilter === "Sell"
                  ? "none"
                  : "block"
              }`}
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "600" }}
                variant="h6"
              >
                No. Of Bathrooms:
              </Typography>
              <Grid
                container
                className="filter-modules-wrapper"
                sx={{ display: "grid" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bathroomsFilter === 1}
                      onChange={() => setBathroomsFilter(1)}
                    />
                  }
                  label="1"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bathroomsFilter === 2}
                      onChange={() => setBathroomsFilter(2)}
                    />
                  }
                  label="2"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bathroomsFilter === 3}
                      onChange={() => setBathroomsFilter(3)}
                    />
                  }
                  label="3"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bathroomsFilter === 4}
                      onChange={() => setBathroomsFilter(4)}
                    />
                  }
                  label="4"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bathroomsFilter === 5}
                      onChange={() => setBathroomsFilter(5)}
                    />
                  }
                  label="5"
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={11}>
            {/* Furnishing */}
            <Grid container className={`search_section_outer_container`}>
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "400" }}
              >
                Furnishing:
              </Typography>
              <Grid
                container
                className="filter-modules-wrapper"
                sx={{ display: "grid" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={furnishedFilter === "Fully Furnished"}
                      onChange={() => setfurnishedFilter("Fully Furnished")}
                      sx={{
                        color: "rgb(2 201 136 / 25%)",
                        "&.Mui-checked": {
                          color: "rgb(2 201 136 / 25%)",
                        },
                      }}
                    />
                  }
                  label="Fully Furnished"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={furnishedFilter === "Semi Furnished"}
                      onChange={() => setfurnishedFilter("Semi Furnished")}
                      sx={{
                        color: "rgb(2 201 136 / 25%)",
                        "&.Mui-checked": {
                          color: "rgb(2 201 136 / 25%)",
                        },
                      }}
                    />
                  }
                  label="Semi Furnished"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={furnishedFilter === "Unfurnished"}
                      onChange={() => setfurnishedFilter("Unfurnished")}
                      sx={{
                        color: "rgb(2 201 136 / 25%)",
                        "&.Mui-checked": {
                          color: "rgb(2 201 136 / 25%)",
                        },
                      }}
                    />
                  }
                  label="Unfurnished"
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={12}>
            {/* RERA Approved */}
            <Grid
              container
              // className={`search_section_outer_container d-${
              //   propertyType === "sell" || propertyTypePageFilter === "Sell"
              //     ? "block"
              //     : "none"
              // }`}
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "400" }}
              >
                RERA Approved:
              </Typography>
              <Grid container className="filter-modules-wrapper">
                <Button
                  className={styles.filter_buttons}
                  onClick={() => {
                    // filteredProperties("Semi Furnished");
                    setRERAApprovedFilter("RERA Approved Properties");
                  }}
                  style={
                    RERAApprovedFilter === "RERA Approved Properties"
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  RERA Approved Properties
                </Button>
                <Button
                  className={styles.filter_buttons}
                  onClick={() => {
                    // filteredProperties("Semi Furnished");
                    setRERAApprovedFilter("RERA Registered Dealers");
                  }}
                  style={
                    purchaseTypeFilter === "RERA Registered Dealers"
                      ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                      : {}
                  }
                >
                  RERA Registered Dealers
                </Button>
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
        <footer
          style={{
            // position: "fixed",
            bottom: "0",
            left: "0",
            width: "100%",
            // backgroundColor: "#333", // Ensure this line is uncommented to set the background color
            color: "white",
            padding: "10px 20px",
            boxShadow: "0px -8px 24px rgba(149, 157, 165, 0.4)",
          }}
        >
          <div className="d-flex justify-content-between">
            <Button variant="text" onClick={toggleDrawer}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                filteredProperties();
                setFiltersActivated(true);
                toggleDrawer();
                setTimeout(() => {
                  const queryParams = {
                    propertyType:
                      propertyTypePageFilter !== null &&
                      propertyTypePageFilter !== undefined &&
                      propertyTypePageFilter.length !== 0
                        ? propertyTypePageFilter
                        : propertyTypePageFilter.length === 0 &&
                          propertyType !== "all"
                        ? propertyType
                        : "",
                    addressStr:
                      localitiesData !== null &&
                      localitiesData !== undefined &&
                      localitiesData.length !== 0
                        ? localitiesData
                        : localitiesData.length === 0 &&
                          proprertyKeys !== "list"
                        ? proprertyKeys
                        : "",
                    minPrice:
                      propertyType === "sell" ||
                      propertyTypePageFilter === "Sell"
                        ? sellBudgetRange[0]
                        : rentBudgetRange[0],
                    maxPrice:
                      propertyType === "sell" ||
                      propertyTypePageFilter === "Sell"
                        ? sellBudgetRange[1]
                        : rentBudgetRange[1],
                    furnishing: furnishedFilter,
                    available_for: tenantTypeFilter,
                    bhk_of_house: bedroomsFilter,
                    city:
                      cityFilter !== null && cityFilter !== undefined
                        ? cityFilter
                        : "",
                    buildUp_type: buildupTypeFilter,
                    // verify_property:
                    //   verifiedPropertiesFilter === true
                    //     ? 1
                    //     : verifiedPropertiesFilter === false
                    //     ? ""
                    //     : "",
                    // minAge:
                    //   lowestAgeFilter === undefined ? "" : lowestAgeFilter,
                    // maxAge:
                    //   highestAgeFilter === undefined ? "" : highestAgeFilter,
                    societyStr: societyData,
                    owner_availability:
                      withownerFilter !== null &&
                      withownerFilter !== undefined &&
                      withownerFilter !== ""
                        ? withownerFilter
                        : "",
                    // independent:
                    //   independentPropertiesFilter === true
                    //     ? 1
                    //     : independentPropertiesFilter === false
                    //     ? ""
                    //     : "",
                    // bathrooms:
                    //   bathroomsFilter !== null && bathroomsFilter !== undefined
                    //     ? bathroomsFilter
                    //     : "",
                    // propertyImages:
                    //   propertiesWithPhotoFilter === true
                    //     ? true
                    //     : propertiesWithPhotoFilter === false
                    //     ? ""
                    //     : "",
                    // minArea: areaRange[0],
                    // maxArea: areaRange[1],
                    construction_status:
                      constructionStatusFilter !== null &&
                      constructionStatusFilter !== undefined &&
                      constructionStatusFilter !== ""
                        ? constructionStatusFilter
                        : "",
                    posted_by:
                      postedByFilter !== null &&
                      postedByFilter !== undefined &&
                      postedByFilter !== ""
                        ? postedByFilter
                        : "",
                    purchase_type:
                      purchaseTypeFilter !== null &&
                      purchaseTypeFilter !== undefined &&
                      purchaseTypeFilter !== ""
                        ? purchaseTypeFilter
                        : "",
                    rera_approved:
                      RERAApprovedFilter !== null &&
                      RERAApprovedFilter !== undefined &&
                      RERAApprovedFilter !== ""
                        ? RERAApprovedFilter
                        : "",
                    facing:
                      facingFilter !== null &&
                      facingFilter !== undefined &&
                      facingFilter !== ""
                        ? facingFilter
                        : "",
                    // startDate: selectedDate,
                    // premium:
                    //   premiumFilter === true
                    //     ? 1
                    //     : premiumFilter === false
                    //     ? ""
                    //     : "",
                    // campus_type:
                    //   campusTypeFilter !== null &&
                    //   campusTypeFilter !== undefined &&
                    //   campusTypeFilter !== ""
                    //     ? campusTypeFilter
                    //     : "",
                  };
                  const filteredQueryParams = Object.fromEntries(
                    Object.entries(queryParams).filter(
                      ([_, value]) => value !== ""
                    )
                  );
                  const queryString = new URLSearchParams(
                    filteredQueryParams
                  ).toString();
                  navigate(`?${queryString}`);
                }, 1000);
              }}
            >
              See All Properties
            </Button>
          </div>
        </footer>
      </>
    );
  };

  const loaderAction = (val) => {
    // console.log("Loader Action Value - ", val);
    function loaderActivated() {
      setloaderHide(false);
    }
    function loaderDeactivated() {
      setloaderHide(true);
    }

    if (val === 0) {
      setTimeout(loaderDeactivated, 3000);
    } else {
      setTimeout(loaderActivated, 100);
    }
  };

  const PaginationActions = (val) => {
    if (val === 1) {
      setPageCount(pageCount + 1);
    } else {
      setPageCount(pageCount - 1);
    }
  };

  // const [openModal, setOpenModal] = useState(false);

  // const handleOpenModal = () => setOpenModal(true);
  // const handleCloseModal = () => setOpenModal(false);

  // const modalContent = (
  //   <Box sx={{ p: 4, bgcolor: "background.paper", borderRadius: 1 }}>
  //     <Typography variant="h6">Modal Title</Typography>
  //     <Typography variant="body1">This is the content of the modal.</Typography>
  //     <Button onClick={handleCloseModal}>Close</Button>
  //   </Box>
  // );
  const style = {
    position: "absolute",
    top: "60%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // *********** modal toggle **********
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpen1, setIsOpen1] = React.useState(false);
  const [isOpen2, setIsOpen2] = React.useState(false);
  const [isOpen3, setIsOpen3] = React.useState(false);
  const [isOpen4, setIsOpen4] = React.useState(false);

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
    if (!isOpen) {
      setIsOpen1(false);
      setIsOpen2(false);
      setIsOpen3(false);
      setIsOpen4(false);
    }
  };

  const toggleDropdown1 = () => {
    setIsOpen1((prev) => !prev);
    if (!isOpen1) {
      setIsOpen(false);
      setIsOpen2(false);
      setIsOpen3(false);
      setIsOpen4(false);
    }
  };

  const toggleDropdown2 = () => {
    setIsOpen2((prev) => !prev);
    if (!isOpen2) {
      setIsOpen(false);
      setIsOpen1(false);
      setIsOpen3(false);
      setIsOpen4(false);
    }
  };

  const toggleDropdown3 = () => {
    setIsOpen3((prev) => !prev);
    if (!isOpen3) {
      setIsOpen(false);
      setIsOpen1(false);
      setIsOpen2(false);
      setIsOpen4(false);
    }
  };

  const toggleDropdown4 = () => {
    setIsOpen4((prev) => !prev);
    if (!isOpen4) {
      setIsOpen(false);
      setIsOpen1(false);
      setIsOpen2(false);
      setIsOpen3(false);
    }
  };
  const handlePropertyTypeChange = (event) => {
    const selectedValue = event.target.value;
    setPropertyTypePageFilter(selectedValue);
    setDisableParamsPropertyType(true);
  };
  return (
    <div>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <Grid
          container
          sx={{
            // display: "flex",
            justifyContent: "center",
            backgroundColor: "#097929",
          }}
          className="pb-4 d-sx-none"
        >
          {/* ******** new code ********** */}

          <Grid
            lg={8}
            xs={11}
            className="d-flex justify-content-between align-items-center bg-white rounded-2 px-4"
          >
            {/* Filter List */}

            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  pt: 1,
                  pb: 1,
                }}
              >
                {/* <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "500",
                    lineHeight: "28.9px",
                    borderRight: "2px solid #0000005c",
                  }}
                  className="me-4 pe-3"
                >
                  Filters
                </Typography> */}
                <Select
                  value={
                    propertyTypePageFilter.charAt(0).toUpperCase() +
                    propertyTypePageFilter.slice(1).toLowerCase()
                  }
                  onChange={handleChangeDropdown}
                  sx={{ marginRight: "10px" }}
                >
                  <MenuItem value="Rent">Rent</MenuItem>
                  <MenuItem value="Buy">Buy</MenuItem>
                </Select>

                <Box>
                  {/* Your existing filter buttons code */}
                  {/* {propertyTypePageFilter.length !== 0 ? (
                    <Button
                      className={styles.filter_buttons}
                      sx={{ margin: "10px 10px 10px 0" }}
                      endIcon={
                        <IconButton
                          onClick={() => handleRemoveFilter("propertyType")}
                        >
                          <CloseIcon />
                        </IconButton>
                      }
                    >
                      {propertyTypePageFilter.length === 0
                        ? propertyType === "Sell" || propertyType === "sell"
                          ? "Buy"
                          : propertyType
                        : propertyTypePageFilter === "Sell" ||
                          propertyTypePageFilter === "sell"
                        ? "Buy"
                        : propertyTypePageFilter}
                    </Button>
                  ) : (
                    propertyType !== "all" && (
                      <Link to="/properties/list/all">
                        <Button
                          className={styles.filter_buttons}
                          sx={{ margin: "10px 10px 10px 0" }}
                          endIcon={
                            <IconButton
                              onClick={() => handleRemoveFilter("propertyType")}
                            >
                              <CloseIcon />
                            </IconButton>
                          }
                        >
                          {propertyTypePageFilter.length === 0
                            ? propertyType === "Sell" || propertyType === "sell"
                              ? "Buy"
                              : propertyType
                            : propertyTypePageFilter === "Sell" ||
                              propertyTypePageFilter === "sell"
                            ? "Buy"
                            : propertyTypePageFilter}
                        </Button>
                      </Link>
                    )
                  )} */}
                  {localitiesData.length !== 0 ? (
                    <Button
                      className={styles.filter_buttons}
                      sx={{ margin: "10px 10px 10px 0" }}
                      endIcon={
                        <IconButton
                          onClick={() => handleRemoveFilter("localities")}
                        >
                          <CloseIcon />
                        </IconButton>
                      }
                    >
                      {localitiesData.length === 0
                        ? proprertyKeys
                        : localitiesData.length === 1
                        ? localitiesData.map((val) => val)
                        : localitiesData.map((val) => val + ", ")}
                    </Button>
                  ) : (
                    proprertyKeys !== "list" && (
                      <Link to="/properties/list/all">
                        <Button
                          className={styles.filter_buttons}
                          sx={{ margin: "10px 10px 10px 0" }}
                          endIcon={
                            <IconButton
                              onClick={() => handleRemoveFilter("localities")}
                            >
                              <CloseIcon />
                            </IconButton>
                          }
                        >
                          {localitiesData.length === 0
                            ? proprertyKeys
                            : localitiesData.map((val) => val + " | ")}
                        </Button>
                      </Link>
                    )
                  )}
                  {buildupTypeFilter !== "" && (
                    <Button
                      className={styles.filter_buttons}
                      sx={{ margin: "10px 10px 10px 0" }}
                      endIcon={
                        <IconButton
                          onClick={() => handleRemoveFilter("buildupType")}
                        >
                          <CloseIcon />
                        </IconButton>
                      }
                    >
                      {buildupTypeFilter}
                    </Button>
                  )}
                  {tenantTypeFilter !== "" && (
                    <Button
                      className={styles.filter_buttons}
                      sx={{ margin: "10px 10px 10px 0" }}
                      endIcon={
                        <IconButton
                          onClick={() => handleRemoveFilter("tenantType")}
                        >
                          <CloseIcon />
                        </IconButton>
                      }
                    >
                      {tenantTypeFilter}
                    </Button>
                  )}
                  {verifiedPropertiesFilter !== "" &&
                    verifiedPropertiesFilter !== undefined &&
                    verifiedPropertiesFilter !== null &&
                    verifiedPropertiesFilter !== false && (
                      <Button
                        className={styles.filter_buttons}
                        sx={{ margin: "10px 10px 10px 0" }}
                        endIcon={
                          <IconButton
                            onClick={() =>
                              handleRemoveFilter("verifiedProperties")
                            }
                          >
                            <CloseIcon />
                          </IconButton>
                        }
                      >
                        Verified Properties
                      </Button>
                    )}
                  {lowestAgeFilter !== undefined && (
                    <Button
                      className={styles.filter_buttons}
                      sx={{ margin: "10px 10px 10px 0" }}
                      endIcon={
                        <IconButton onClick={() => handleRemoveFilter("age")}>
                          <CloseIcon />
                        </IconButton>
                      }
                    >
                      {lowestAgeFilter +
                        (highestAgeFilter !== undefined ? "-" : "+") +
                        (highestAgeFilter !== undefined
                          ? highestAgeFilter
                          : "")}{" "}
                      Years Old
                    </Button>
                  )}
                  {societyData !== "" && (
                    <Button
                      className={styles.filter_buttons}
                      sx={{ margin: "10px 10px 10px 0" }}
                      endIcon={
                        <IconButton
                          onClick={() => handleRemoveFilter("society")}
                        >
                          <CloseIcon />
                        </IconButton>
                      }
                    >
                      {societyData}
                    </Button>
                  )}
                  {withownerFilter !== "" && (
                    <Button
                      className={styles.filter_buttons}
                      sx={{ margin: "10px 10px 10px 0" }}
                      endIcon={
                        <IconButton
                          onClick={() => handleRemoveFilter("withOwner")}
                        >
                          <CloseIcon />
                        </IconButton>
                      }
                    >
                      {withownerFilter}
                    </Button>
                  )}
                  {independentPropertiesFilter !== "" &&
                    independentPropertiesFilter !== undefined &&
                    independentPropertiesFilter !== null &&
                    independentPropertiesFilter !== false && (
                      <Button
                        className={styles.filter_buttons}
                        sx={{ margin: "10px 10px 10px 0" }}
                        endIcon={
                          <IconButton
                            onClick={() =>
                              handleRemoveFilter("independentProperties")
                            }
                          >
                            <CloseIcon />
                          </IconButton>
                        }
                      >
                        Independent Properties
                      </Button>
                    )}
                  {bedroomsFilter !== "" && (
                    <Button
                      className={styles.filter_buttons}
                      sx={{ margin: "10px 10px 10px 0" }}
                      endIcon={
                        <IconButton
                          onClick={() => handleRemoveFilter("bedrooms")}
                        >
                          <CloseIcon />
                        </IconButton>
                      }
                    >
                      {bedroomsFilter}
                    </Button>
                  )}
                  {bathroomsFilter !== undefined &&
                    bathroomsFilter !== null && (
                      <Button
                        className={styles.filter_buttons}
                        sx={{ margin: "10px 10px 10px 0" }}
                        endIcon={
                          <IconButton
                            onClick={() => handleRemoveFilter("bathrooms")}
                          >
                            <CloseIcon />
                          </IconButton>
                        }
                      >
                        {bathroomsFilter} Bathrooms
                      </Button>
                    )}
                  {propertiesWithPhotoFilter !== "" &&
                    propertiesWithPhotoFilter !== undefined &&
                    propertiesWithPhotoFilter !== null &&
                    propertiesWithPhotoFilter !== false && (
                      <Button
                        className={styles.filter_buttons}
                        sx={{ margin: "10px 10px 10px 0" }}
                        endIcon={
                          <IconButton
                            onClick={() =>
                              handleRemoveFilter("propertiesWithPhoto")
                            }
                          >
                            <CloseIcon />
                          </IconButton>
                        }
                      >
                        Properties with Photos
                      </Button>
                    )}
                </Box>
                <Button variant="text" onClick={handleOpenModal}>
                  Add More
                </Button>
              </Box>
            </Box>
            <Modal open={openModal} onClose={handleCloseModal}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                {/* {modalContent} */}

                <Box sx={style}>
                  {/* <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Text in a modal
                  </Typography> */}
                  <div style={{ marginBottom: "30px" }}>
                    <InputBase
                      sx={{
                        flex: 1,
                        backgroundColor: "#fff",
                        borderRadius: "6px",
                        color: "#222222",
                        padding: "8px",
                        marginRight: "8px", // Margin to space between input and button
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #ede4e4",
                        borderRadius: "8px",
                      }}
                      placeholder={
                        modalselectedLocalities.length === 0
                          ? "Search Location Example - Vijay Nagar"
                          : ""
                      }
                      value={modalinputValue}
                      onChange={handleInputChangeModal}
                      startAdornment={
                        <InputAdornment position="start">
                          {modalselectedLocalities.map((locality, index) => (
                            <Chip
                              key={index}
                              label={locality}
                              onDelete={() => removeLocality(locality)}
                              sx={{ margin: "2px" }}
                            />
                          ))}
                        </InputAdornment>
                      }
                      endAdornment={
                        modalinputValue && (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleClearInput}
                              sx={{ color: "#222222" }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                      className="locationBox"
                    />
                    {/* ********** box for cities ************ */}
                    <Box>
                      {modalshowSuggestions && (
                        <ul className="searchData-wrapper">
                          {modalsuggestions.length > 0 ? (
                            modalsuggestions.map((suggestion, index) => (
                              <li
                                style={{
                                  textAlign: "start",
                                  listStyle: "none",
                                }}
                                key={index}
                                onClick={(e) => {
                                  handleSuggestionClickModal(suggestion);
                                }}
                              >
                                {suggestion.name}
                              </li>
                            ))
                          ) : (
                            <li style={{ textAlign: "start" }}>
                              No suggestions available
                            </li>
                          )}
                        </ul>
                      )}
                    </Box>
                  </div>

                  {/* ***** react dropdown ******* */}

                  <Button
                    size="small"
                    onClick={toggleDropdown}
                    endIcon={
                      isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
                    }
                    sx={{
                      borderRadius: "24px",
                      marginRight: "20px",
                      border: "1px solid #ebecf0",
                      padding: "5px 20px",
                    }}
                  >
                    Property Types
                  </Button>
                  <Button
                    size="small"
                    onClick={toggleDropdown1}
                    endIcon={
                      isOpen1 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
                    }
                    sx={{
                      borderRadius: "24px",
                      marginRight: "20px",
                      border: "1px solid #ebecf0",
                      padding: "5px 20px",
                    }}
                  >
                    Budget
                  </Button>
                  <Button
                    size="small"
                    onClick={toggleDropdown2}
                    endIcon={
                      isOpen2 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
                    }
                    sx={{
                      borderRadius: "24px",
                      marginRight: "20px",
                      border: "1px solid #ebecf0",
                      padding: "5px 20px",
                    }}
                  >
                    Bedroom
                  </Button>
                  {/* <Button
                    size="small"
                    onClick={toggleDropdown3}
                    endIcon={
                      isOpen3 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
                    }
                    sx={{
                      borderRadius: "24px",
                      marginRight: "20px",
                      border: "1px solid #ebecf0",
                      padding: "5px 20px",
                    }}
                  >
                    Construction Status
                  </Button> */}
                  <Button
                    size="small"
                    onClick={toggleDropdown4}
                    endIcon={
                      isOpen4 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
                    }
                    sx={{
                      borderRadius: "24px",
                      marginRight: "20px",
                      border: "1px solid #ebecf0",
                      padding: "5px 20px",
                    }}
                  >
                    Furnishing
                  </Button>

                  <div>
                    {isOpen && (
                      <div style={{ marginTop: "30px" }}>
                        <Button
                          className={styles.filter_buttons}
                          style={
                            propertyTypePageFilter === "Rent"
                              ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                              : {}
                          }
                          onClick={() => {
                            setPropertyTypePageFilter("Rent");
                            setDisableParamsPropertyType(true);
                          }}
                        >
                          Rent
                        </Button>
                        <Button
                          className={styles.filter_buttons}
                          onClick={() => {
                            setPropertyTypePageFilter("Sell");
                            setDisableParamsPropertyType(true);
                          }}
                          style={
                            propertyTypePageFilter === "Sell"
                              ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                              : {}
                          }
                        >
                          Buy
                        </Button>
                      </div>
                    )}
                    {isOpen1 && (
                      <h1>
                        {" "}
                        <Typography
                          sx={{
                            fontSize: "16px",
                            lineHeight: "20px",
                            fontWeight: "400",
                            marginTop: "30px",
                          }}
                        >
                          Select Price Range:
                        </Typography>
                        <ThemeProvider theme={theme}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item>
                              <Typography>Min: {minRentBudget}</Typography>
                            </Grid>
                            <Grid item xs>
                              <Slider
                                value={rentBudgetRange}
                                onChange={rentBudgetHandleChange}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(value) => value}
                                min={0}
                                max={500000}
                                color="green"
                              />
                            </Grid>
                            <Grid item>
                              <Typography>Max: {maxRentBudget}</Typography>
                            </Grid>
                          </Grid>
                        </ThemeProvider>
                      </h1>
                    )}
                    {isOpen2 && (
                      <h1>
                        {" "}
                        <Button
                          className={styles.filter_buttons}
                          onClick={() => {
                            setBedroomsFilter("1 BHK");
                          }}
                          style={
                            bedroomsFilter === "1 BHK"
                              ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                              : {}
                          }
                        >
                          1BHK/1RK
                        </Button>
                        <Button
                          className={styles.filter_buttons}
                          onClick={() => {
                            setBedroomsFilter("2 BHK");
                          }}
                          style={
                            bedroomsFilter === "2 BHK"
                              ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                              : {}
                          }
                        >
                          2 BHK
                        </Button>{" "}
                        <Button
                          className={styles.filter_buttons}
                          onClick={() => {
                            setBedroomsFilter("3 BHK");
                          }}
                          style={
                            bedroomsFilter === "3 BHK"
                              ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                              : {}
                          }
                        >
                          3 BHK
                        </Button>
                        <Button
                          className={styles.filter_buttons}
                          onClick={() => {
                            setBedroomsFilter("4 BHK");
                          }}
                          style={
                            bedroomsFilter === "4 BHK"
                              ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                              : {}
                          }
                        >
                          4 BHK
                        </Button>
                        <Button
                          className={styles.filter_buttons}
                          onClick={() => {
                            setBedroomsFilter("5 BHK");
                          }}
                          style={
                            bedroomsFilter === "5 BHK"
                              ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                              : {}
                          }
                        >
                          5+ BHK
                        </Button>
                      </h1>
                    )}
                    {/* {isOpen3 && (
                      <h1>
                        {" "}
                        <Button
                          className={styles.filter_buttons}
                          onClick={() => {
                            setConstructionStatusFilter("Ready to Move");
                          }}
                          style={
                            constructionStatusFilter === "Ready to Move"
                              ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                              : {}
                          }
                        >
                          Ready to Move
                        </Button>
                        <Button
                          className={styles.filter_buttons}
                          onClick={() => {
                            setConstructionStatusFilter("Under Construction");
                          }}
                          style={
                            constructionStatusFilter === "Under Construction"
                              ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                              : {}
                          }
                        >
                          Under Construction
                        </Button>
                      </h1>
                    )} */}
                    {isOpen4 && (
                      <h1>
                        {" "}
                        <Button
                          className={styles.filter_buttons}
                          onClick={() => {
                            // filteredProperties("Furnished");
                            setfurnishedFilter("Fully Furnished");
                          }}
                          style={
                            furnishedFilter === "Fully Furnished"
                              ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                              : {}
                          }
                        >
                          Fully Furnished
                        </Button>
                        <Button
                          className={styles.filter_buttons}
                          onClick={() => {
                            // filteredProperties("Semi Furnished");
                            setfurnishedFilter("Semi Furnished");
                          }}
                          style={
                            furnishedFilter === "Semi Furnished"
                              ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                              : {}
                          }
                        >
                          Semi Furnished
                        </Button>
                        <Button
                          className={styles.filter_buttons}
                          onClick={() => {
                            // filteredProperties("Unfurnished");
                            setfurnishedFilter("Unfurnished");
                          }}
                          style={
                            furnishedFilter === "Unfurnished"
                              ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                              : {}
                          }
                        >
                          Unfurnished
                        </Button>
                      </h1>
                    )}
                  </div>
                  {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Duis mollis, est non commodo luctus, nisi erat porttitor
                    ligula.
                  </Typography> */}
                  <div style={{ marginTop: "20px" }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "rgba(2, 201, 136, 1)",
                        color: "#FFF",
                        textTransform: "capitalize",
                        paddingTop: ".5rem",
                        paddingBottom: ".5rem",
                        marginRight: "20px",
                      }}
                      className="px-md-4 px-2"
                      onClick={() => {
                        handleCloseModal();
                        filteredProperties();
                        setFiltersActivated(true);
                        toggleDrawer();
                        setTimeout(() => {
                          const queryParams = {
                            propertyType:
                              propertyTypePageFilter !== null &&
                              propertyTypePageFilter !== undefined &&
                              propertyTypePageFilter.length !== 0
                                ? propertyTypePageFilter
                                : propertyTypePageFilter.length === 0 &&
                                  propertyType !== "all"
                                ? propertyType
                                : "",
                            addressStr:
                              localitiesData !== null &&
                              localitiesData !== undefined &&
                              localitiesData.length !== 0
                                ? localitiesData
                                : localitiesData.length === 0 &&
                                  proprertyKeys !== "list"
                                ? proprertyKeys
                                : "",
                            minPrice:
                              propertyType === "sell" ||
                              propertyTypePageFilter === "Sell"
                                ? sellBudgetRange[0]
                                : rentBudgetRange[0],
                            maxPrice:
                              propertyType === "sell" ||
                              propertyTypePageFilter === "Sell"
                                ? sellBudgetRange[1]
                                : rentBudgetRange[1],
                            furnishing: furnishedFilter,
                            available_for: tenantTypeFilter,
                            bhk_of_house: bedroomsFilter,
                            city:
                              cityFilter !== null && cityFilter !== undefined
                                ? cityFilter
                                : "",
                            buildUp_type: buildupTypeFilter,
                            verify_property:
                              verifiedPropertiesFilter === true
                                ? 1
                                : verifiedPropertiesFilter === false
                                ? ""
                                : "",
                            minAge:
                              lowestAgeFilter === undefined
                                ? ""
                                : lowestAgeFilter,
                            maxAge:
                              highestAgeFilter === undefined
                                ? ""
                                : highestAgeFilter,
                            societyStr: societyData,
                            owner_availability:
                              withownerFilter !== null &&
                              withownerFilter !== undefined &&
                              withownerFilter !== ""
                                ? withownerFilter
                                : "",
                            independent:
                              independentPropertiesFilter === true
                                ? 1
                                : independentPropertiesFilter === false
                                ? ""
                                : "",
                            bathrooms:
                              bathroomsFilter !== null &&
                              bathroomsFilter !== undefined
                                ? bathroomsFilter
                                : "",
                            propertyImages:
                              propertiesWithPhotoFilter === true
                                ? true
                                : propertiesWithPhotoFilter === false
                                ? ""
                                : "",
                            minArea: areaRange[0],
                            maxArea: areaRange[1],
                            construction_status:
                              constructionStatusFilter !== null &&
                              constructionStatusFilter !== undefined &&
                              constructionStatusFilter !== ""
                                ? constructionStatusFilter
                                : "",
                            posted_by:
                              postedByFilter !== null &&
                              postedByFilter !== undefined &&
                              postedByFilter !== ""
                                ? postedByFilter
                                : "",
                            purchase_type:
                              purchaseTypeFilter !== null &&
                              purchaseTypeFilter !== undefined &&
                              purchaseTypeFilter !== ""
                                ? purchaseTypeFilter
                                : "",
                            rera_approved:
                              RERAApprovedFilter !== null &&
                              RERAApprovedFilter !== undefined &&
                              RERAApprovedFilter !== ""
                                ? RERAApprovedFilter
                                : "",
                            facing:
                              facingFilter !== null &&
                              facingFilter !== undefined &&
                              facingFilter !== ""
                                ? facingFilter
                                : "",
                            startDate: selectedDate,
                            premium:
                              premiumFilter === true
                                ? 1
                                : premiumFilter === false
                                ? ""
                                : "",
                            campus_type:
                              campusTypeFilter !== null &&
                              campusTypeFilter !== undefined &&
                              campusTypeFilter !== ""
                                ? campusTypeFilter
                                : "",
                          };
                          const filteredQueryParams = Object.fromEntries(
                            Object.entries(queryParams).filter(
                              ([_, value]) => value !== ""
                            )
                          );
                          const queryString = new URLSearchParams(
                            filteredQueryParams
                          ).toString();
                          navigate(`?${queryString}`);
                        }, 1000);
                      }}
                    >
                      Search
                    </Button>
                    <Button onClick={handleCloseModal}>Close</Button>
                  </div>
                </Box>
              </Box>
            </Modal>

            {/* new code for clear  */}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "1rem",
                paddingBottom: "1rem",
              }}
              className="me-4 float-end"
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "20px",
                  color: "rgba(31, 64, 105, 1)",
                }}
              >
                <Link to="/properties/list/all">
                  <span
                    onClick={() => {
                      handleRefresh();
                    }}
                    style={{
                      cursor: "pointer",
                      color: "#212529",
                    }}
                    // className="text-black"
                  >
                    Clear All
                  </span>
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          lg={11.5}
          md={12}
          className={styles.propert_outer_container_grid}
        >
          <Grid
            sm={10}
            xs={12}
            // sx={{ padding: "1.5rem" }}
            className="d-lg-none mx-auto pt-2 px-3"
          >
            <Box className="drawer-wrapper">
              <Box className="drawer-title-wrapper d-flex align-items-center">
                <Typography id="drawer-title" variant="h6" component="h2">
                  Filters
                </Typography>
                <IconButton onClick={toggleDrawer}>
                  <FilterListIcon
                    sx={{
                      color: "rgb(2, 201, 136)",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              </Box>
              <Hidden mdUp>
                <Drawer
                  anchor="bottom"
                  open={openDrawer}
                  onClose={toggleDrawer}
                  className="outer-drawer-wrapper"
                >
                  {/* {filterData()} */}
                  {NewfilterData()}
                </Drawer>
              </Hidden>
            </Box>
          </Grid>
          <Grid
            lg={3.5}
            md={4}
            sm={10}
            xs={12}
            sx={{
              padding: "0.5rem",
              paddingTop: "1.5rem",
            }}
            className="mx-auto property_filters d-lg-block d-none"
          >
            <Box
              sx={{
                border: "1px solid #ced4dd",

                borderRadius: "8px",
                padding: "1rem",
              }}
            >
              {filterData()}
            </Box>
          </Grid>
          <Grid
            lg={8.5}
            md={8}
            sm={10}
            xs={12}
            // sx={{ border: "solid 1px rgba(210, 210, 210, 1)" }}
            className="mx-auto property_details"
          >
            <Grid container>
              <Grid
                lg={12}
                xs={12}
                sx={{ padding: "1rem", paddingTop: "1.5rem" }}
              >
                {PropertiesList !== undefined &&
                (PropertiesList !== null) & (PropertiesList.length !== 0) ? (
                  PropertiesList.slice(0, 20).map((val, ind) => {
                    {
                      loaderAction(1);
                    }
                    return (
                      <Card
                        key={ind}
                        sx={{
                          border: "1px solid #D2D2D2",
                          padding: "5px",
                          display: "flex",
                          boxShadow: "none",
                        }}
                        className="mb-4"
                      >
                        <Grid container>
                          <Grid lg={3} xs={12}>
                            <Link
                              to={`/property-details/${val._id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="h-100"
                            >
                              <div
                                className={`property-image-slider-wrapper d-${
                                  val.propertyImages.length !== 0
                                    ? "block"
                                    : "none"
                                }`}
                              >
                                <Carousel>
                                  {val.propertyImages.map((imgsrc, i) => {
                                    return (
                                      <Carousel.Item
                                        interval={3000 + ind * 1000}
                                        key={i}
                                      >
                                        <img
                                          src={`${imageUrl + imgsrc.images}`}
                                          alt=""
                                          style={{
                                            height: "10rem",
                                            borderRadius: "6px",
                                            objectFit: "cover",
                                            width: "100%",
                                          }}
                                        />
                                        <div
                                          className="property-image-overlay position-absolute"
                                          style={{
                                            height: "10rem",
                                            borderRadius: "6px",
                                            width: "100%",
                                            top: 0,
                                            background: "#0000007a",
                                          }}
                                        ></div>
                                        <div
                                          className="property-type position-absolute"
                                          style={{
                                            top: "2%",
                                            left: "2%",
                                            zIndex: "1",
                                          }}
                                        >
                                          <Button
                                            variant="contained"
                                            style={{
                                              backgroundColor: "#fff",
                                              color: "#222",
                                              textTransform: "capitalize",
                                              paddingTop: ".3rem",
                                              paddingBottom: ".3rem",
                                              fontSize: "16px",
                                            }}
                                            className="px-2"
                                          >
                                            {val.available_for[0]}
                                          </Button>
                                        </div>
                                        <div className="mobile-share d-md-none d-block">
                                          <RWebShare
                                            data={{
                                              text: "Web Share - Share Property",
                                              url: `http://gharaaj.com/#/property-details/${val._id}`,
                                              title: "Share Property",
                                            }}
                                            // onClick={() =>
                                            //   console.log(
                                            //     "shared successfully!"
                                            //   )
                                            // }
                                          >
                                            <IconButton sx={{ mr: 4 }}>
                                              <ShareIcon
                                                sx={{
                                                  color: "#fff",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </IconButton>
                                          </RWebShare>
                                        </div>
                                        <div
                                          className={`premiumPropertyCarousel position-absolute d-${
                                            val.price > 500000
                                              ? "block"
                                              : "none"
                                          }`}
                                          style={{
                                            top: "2%",
                                            right: "2%",
                                            zIndex: "1",
                                          }}
                                        >
                                          <Button
                                            variant="contained"
                                            style={{
                                              backgroundColor: "#EA3A91",
                                              color: "#fff",
                                              textTransform: "capitalize",
                                              paddingTop: ".3rem",
                                              paddingBottom: ".3rem",
                                              fontSize: "16px",
                                            }}
                                            className="px-2"
                                          >
                                            Premium
                                          </Button>
                                        </div>
                                      </Carousel.Item>
                                    );
                                  })}
                                </Carousel>
                              </div>
                              <div
                                className={`property-dummyImage-wrapper px-5 align-items-center rounded-2 justify-content-center d-${
                                  val.propertyImages.length !== 0
                                    ? "none"
                                    : "flex"
                                }`}
                                style={{
                                  border: "1px solid rgb(0 0 0 / 18%",
                                  height: "10rem",
                                }}
                              >
                                <img src={Logo} alt="property" />
                              </div>
                            </Link>
                          </Grid>
                          <Grid lg={8} xs={12}>
                            <Link to={`/property-details/${val._id}`}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  // padding: "1rem",
                                }}
                                className="flex-md-row flex-column  pt-md-0 pt-2 px-md-2"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography className="fs-5 fw-medium px-2 py-2 text-dark">
                                    ₹{val.price}/-
                                  </Typography>
                                  <Typography className="fw-medium px-2 py-2 text-dark">
                                    Deposit: ₹{val.deposite_money}
                                  </Typography>
                                </div>
                                <div className="mt-md-0 mt-4 d-md-block d-none">
                                  <RWebShare
                                    data={{
                                      text: "Web Share - Share Property",
                                      url: `http://gharaaj.com/#/property-details/${val._id}`,
                                      title: "Share Property",
                                    }}
                                    // onClick={() =>
                                    //   console.log("shared successfully!")
                                    // }
                                  >
                                    <IconButton sx={{ mr: 4 }}>
                                      <ShareIcon
                                        sx={{
                                          color: "#666666",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </IconButton>
                                  </RWebShare>
                                </div>
                              </div>
                              <Typography className="fw-medium px-3  text-dark pb-4 d-md-block d-none">
                                Property Id: {val._id}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "20px",
                                  fontWeight: "500",
                                  lineHeight: "24px",
                                  paddingLeft: "15px",
                                }}
                              >
                                <Link
                                  to={`/property-details/${val._id}`}
                                  style={{ color: "black" }}
                                >
                                  {val.propertyName}
                                </Link>
                              </Typography>
                              <div>
                                <Link to={`/property-details/${val._id}`}>
                                  <Grid
                                    container
                                    className="py-1 mx-3"
                                    // sx={{ borderBottom: "1px solid #D2D2D2" }}
                                  >
                                    <Grid
                                      sm={3}
                                      xs={6}
                                      className="mb-md-0 mb-2"
                                    >
                                      <Card
                                        sx={{
                                          padding: "0px",
                                          textAlign: "center",
                                          boxShadow: "none",
                                          // borderRight: "1px solid #DEDEDE",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "16px",
                                            fontWeight: 500,
                                          }}
                                          className="text-md-center text-start"
                                        >
                                          Location
                                        </Typography>
                                        <Typography
                                          sx={{ fontSize: "16px" }}
                                          className="text-md-center text-start"
                                        >
                                          {val.address}
                                          {/* , {val.city} */}
                                        </Typography>
                                      </Card>
                                    </Grid>

                                    <Grid
                                      sm={3}
                                      xs={6}
                                      className="mb-md-0 mb-2"
                                    >
                                      <Card
                                        sx={{
                                          textAlign: "center",
                                          boxShadow: "none",
                                          // borderRight: "1px solid #DEDEDE",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "16px",
                                            fontWeight: 500,
                                          }}
                                          className="text-md-center text-start"
                                        >
                                          Property Type
                                        </Typography>
                                        <Typography
                                          sx={{ fontSize: "16px" }}
                                          className="text-md-center text-start"
                                        >
                                          {val.propertyType}
                                        </Typography>
                                      </Card>
                                    </Grid>
                                    <Grid
                                      sm={3}
                                      xs={6}
                                      className="mb-md-0 mb-2"
                                    >
                                      <Card
                                        sx={{
                                          textAlign: "center",
                                          boxShadow: "none",
                                          // borderRight: "1px solid #DEDEDE",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "16px",
                                            fontWeight: 500,
                                          }}
                                          className="text-md-center text-start"
                                        >
                                          BHK
                                        </Typography>
                                        <Typography
                                          sx={{ fontSize: "16px" }}
                                          className="text-md-center text-start"
                                        >
                                          {val.bhk_of_house}
                                        </Typography>
                                      </Card>
                                    </Grid>
                                    <Grid
                                      sm={3}
                                      xs={6}
                                      className="mb-md-0 mb-2 d-md-block d-none"
                                    >
                                      <Button
                                        variant="contained"
                                        style={{
                                          backgroundColor: "rgb(237, 114, 37)",
                                          color: "#FFF",
                                          textTransform: "capitalize",
                                          paddingTop: ".4rem",
                                          paddingBottom: ".4rem",
                                        }}
                                        className="px-md-4 px-2"
                                        // onClick={() => {
                                        //   if (
                                        //     userId !== undefined &&
                                        //     userId !== null
                                        //   ) {
                                        //     handleOpen();
                                        //     setPropertyId(val._id);
                                        //   } else {
                                        //     handleClose();
                                        //     // Swal.fire({
                                        //     //   icon: "error",
                                        //     //   title: "Please Login Your Account",
                                        //     //   showConfirmButton: false,
                                        //     //   timer: 2000,
                                        //     // });
                                        //     navigate("/signup-login");
                                        //   }
                                        // }}
                                      >
                                        Visit For{" "}
                                        <span
                                          style={{
                                            color: "rgba(31, 64, 105, 1)",
                                            fontSize: "18px",
                                          }}
                                          className="different-font"
                                        >
                                          {" "}
                                          Free
                                        </span>
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Link>
                              </div>
                              <Typography
                                sx={{
                                  fontSize: "20px",
                                  fontWeight: "300",
                                  lineHeight: "24px",
                                  paddingLeft: "12px",
                                }}
                              >
                                <Link
                                  to={`/property-details/${val._id}`}
                                  style={{ color: "black" }}
                                >
                                  {val.description}
                                </Link>
                              </Typography>
                            </Link>
                          </Grid>
                        </Grid>
                      </Card>
                    );
                  })
                ) : (
                  <>
                    <div
                      style={{
                        justifyContent: "space-evenly",
                        paddingTop: "2rem",
                      }}
                      className={`d-${loaderHide ? "none" : "flex"}`}
                    >
                      <CircularProgress />
                    </div>
                    <p
                      className={`px-3 py-5 font-bold text-center w-100 d-${
                        loaderHide ? "block" : "none"
                      }`}
                    >
                      No Result Found
                    </p>
                    {loaderAction(0)}
                  </>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              className={`d-${
                completeResp !== undefined &&
                completeResp !== null &&
                completeResp.status === 200
                  ? "block"
                  : "none"
              }`}
            >
              <Grid lg={12} xs={12}>
                <Box className="pagination-wrapper">
                  {/* <IconButton onClick={toggleDrawer}> */}
                  <Grid
                    container
                    className="align-items-center justify-content-md-start justify-content-center"
                  >
                    <Grid item>
                      {" "}
                      <IconButton
                        onClick={() => PaginationActions(-1)}
                        disabled={pageCount === 0 ? true : false}
                      >
                        <ArrowCircleLeftSharpIcon
                          sx={{
                            // color: "rgb(2, 201, 136)",
                            color: `${
                              pageCount === 0
                                ? "rgb(2 201 136 / 34%)"
                                : "rgb(2, 201, 136)"
                            }`,
                            cursor: "pointer",
                          }}
                          fontSize="large"
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => PaginationActions(1)}
                        disabled={
                          completeResp !== undefined &&
                          completeResp !== null &&
                          completeResp.upper === completeResp.total
                            ? true
                            : false
                        }
                      >
                        <ArrowCircleRightSharpIcon
                          sx={{
                            color: `${
                              completeResp !== undefined &&
                              completeResp !== null &&
                              completeResp.upper === completeResp.total
                                ? "rgb(2 201 136 / 34%)"
                                : "rgb(2, 201, 136)"
                            }`,
                            cursor: "pointer",
                          }}
                          fontSize="large"
                        />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        {completeResp !== undefined &&
                          completeResp !== null &&
                          completeResp.lower +
                            " - " +
                            completeResp.upper +
                            " of " +
                            completeResp.total}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Visit Popup */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        sx={visitPopupBg}
      >
        <Fade in={open}>
          <Box sx={visitPopupStyle}>
            <div className="model-header wrapper d-flex justify-content-between align-content-center">
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Book Visit Schedule
              </Typography>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h4"
                className="closeModel"
              >
                <span onClick={handleClose} style={{ cursor: "pointer" }}>
                  X
                </span>
              </Typography>
            </div>
            <Typography id="transition-modal-description">
              Pick a time for a Free assisted house visit
            </Typography>
            <div className="d-md-block d-none">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDateTimePicker
                  orientation="landscape"
                  onAccept={(value) => {
                    submitVisitShedule(value);
                    handleClose();
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="d-md-none d-block">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["StaticDateTimePicker"]}>
                  <DemoItem label="Static variant">
                    <StaticDateTimePicker
                      // defaultValue={dayjs("2022-04-17T15:30")}
                      onAccept={(value) => {
                        submitVisitShedule(value);
                        handleClose();
                        // console.log("Mobile Visit Time Submitted", value);
                      }}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
