/** @format */

/** @format */

import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import {
  Box,
  Grid,
  InputBase,
  Typography,
  IconButton,
  Chip,
  InputAdornment,
  Modal,
  Drawer,
  Card,
  CardContent,
  Button,
  ThemeProvider,
  createTheme,
  Slider,
  TextField,
  FormControlLabel,
  Switch,
  styled,
} from "@mui/material";
import Paper from "@mui/material/Paper";

import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styles from "../../Css/property.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import debounce from "lodash/debounce";
import { getApihandler } from "../../Apihandler";
import HeaderContext from "../../LayoutWrapper/ContextWrapper/context";

// import React, { useContext } from "react";

const LocalityData = [
  {
    name: "Nipania",
    city: "Indore",
  },
  {
    name: "Bhanwarkuan",
    city: "Indore",
  },

  {
    name: "Vijay nagar",
    city: "Indore",
  },
  {
    name: "Mr 10",
    city: "Indore",
  },
  {
    name: "Mr 9",
    city: "Indore",
  },
  {
    name: "AB BypassRoad",
    city: "Indore",
  },
  {
    name: "Mahalakshmi Nagar",
    city: "Indore",
  },
  {
    name: "Bicholi Mardana",
    city: "Indore",
  },
  {
    name: "Rau",
    city: "Indore",
  },
  {
    name: "Mhow",
    city: "Indore",
  },
  {
    name: "Khandwa Road",
    city: "Indore",
  },
  {
    name: "Bengali Square",
    city: "Indore",
  },
  {
    name: "Kanadia Road",
    city: "Indore",
  },
  {
    name: "Talawali Chanda",
    city: "Indore",
  },
  {
    name: "Rajendra Nagar",
    city: "Indore",
  },
  {
    name: "Silicon City",
    city: "Indore",
  },
  {
    name: "Pipliyahana",
    city: "Indore",
  },
  {
    name: "Kanadiya",
    city: "Indore",
  },
  {
    name: "Scheme No 54",
    city: "Indore",
  },
  {
    name: "Saket Nagar",
    city: "Indore",
  },
  {
    name: "Scheme No 114",
    city: "Indore",
  },
  {
    name: "Jhalaria",
    city: "Indore",
  },
  {
    name: "AB Road indore",
    city: "Indore",
  },
  {
    name: "Ida Scheme No 134",
    city: "Indore",
  },
  {
    name: "Devguradia",
    city: "Indore",
  },
  {
    name: "Tilak Nagar",
    city: "Indore",
  },
  {
    name: "Bijalpur",
    city: "Indore",
  },
  {
    name: "Bhawrasla",
    city: "Indore",
  },
  {
    name: "Bada Bangarda",
    city: "Indore",
  },
  {
    name: "Niranjanpur",
    city: "Indore",
  },
  {
    name: "Scheme No 78",
    city: "Indore",
  },
  {
    name: "Race Course Road",
    city: "Indore",
  },
  {
    name: "Old Palasia",
    city: "Indore",
  },
  {
    name: "MR 11",
    city: "Indore",
  },
  {
    name: "Nainod",
    city: "Indore",
  },
  {
    name: "Bicholi Hapsi",
    city: "Indore",
  },
  {
    name: "Kesar Bagh",
    city: "Indore",
  },
  {
    name: "Tejaji Nagar",
    city: "Indore",
  },
  {
    name: "Palakhedi",
    city: "Indore",
  },
  {
    name: "Scheme No 71",
    city: "Indore",
  },
  {
    name: "Sai Kripa Colony",
    city: "Indore",
  },
  {
    name: "Manorama Ganj",
    city: "Indore",
  },
  {
    name: "Scheme No 103",
    city: "Indore",
  },
  {
    name: "Gulab Bagh",
    city: "Indore",
  },
  {
    name: "Navlakha",
    city: "Indore",
  },
  {
    name: "Rau Pitampur Road",
    city: "Indore",
  },
  {
    name: "Rangwasa",
    city: "Indore",
  },
  {
    name: "Tigaria Badshah",
    city: "Indore",
  },
  {
    name: "Pipliya Kumar",
    city: "Indore",
  },
  {
    name: "LIG Colony",
    city: "Indore",
  },
  {
    name: "Luv Kush Square",
    city: "Indore",
  },
  {
    name: "Kalani Nagar",
    city: "Indore",
  },
  {
    name: "Magarkheda",
    city: "Indore",
  },
  {
    name: "Gumasta Nagar",
    city: "Indore",
  },
  {
    name: "South Tukoganj",
    city: "Indore",
  },
  {
    name: "Nanda Nagar",
    city: "Indore",
  },
  {
    name: "Scheme No 94",
    city: "Indore",
  },
  {
    name: "Sangam Nagar",
    city: "Indore",
  },

  {
    name: "Alok Nagar",
    city: "Indore",
  },
  {
    name: "Anurag Nagar",
    city: "Indore",
  },
  {
    name: "Snehlataganj",
    city: "Indore",
  },
  {
    name: "Sapna Sangeeta Road",
    city: "Indore",
  },
  {
    name: "Vasant Vihar",
    city: "Indore",
  },
  {
    name: "Mangaliya Sadak",
    city: "Indore",
  },
  {
    name: "Paliya",
    city: "Indore",
  },
  {
    name: "Anoop Nagar",
    city: "Indore",
  },
  {
    name: "Bangali Square",
    city: "Indore",
  },
  {
    name: "Arandia",
    city: "Indore",
  },
  {
    name: "Balya Kheda",
    city: "Indore",
  },
  {
    name: "Geeta Bhavan",
    city: "Indore",
  },
  {
    name: "Musakhedi",
    city: "Indore",
  },
  {
    name: "Mig Colony",
    city: "Indore",
  },
  {
    name: "Ring Road",
    city: "Indore",
  },
  {
    name: "Jakhiya",
    city: "Indore",
  },
  {
    name: "Khatiwala Tank",
    city: "Indore",
  },
  {
    name: "Palsikar Colony",
    city: "Indore",
  },
  {
    name: "Rajwada",
    city: "Indore",
  },
  {
    name: "Kordia Barda",
    city: "Indore",
  },
  {
    name: "Mundla Nayta",
    city: "Indore",
  },
  {
    name: "Pithampur Road",
    city: "Indore",
  },
  {
    name: "Bakhtawar Ram Nagar",
    city: "Indore",
  },
  {
    name: "Pigdambar",
    city: "Indore",
  },
  {
    name: "Rambag",
    city: "Indore",
  },
  {
    name: "Vishnupuri Colony",
    city: "Indore",
  },
  {
    name: "Shri Nagar Extension",
    city: "Indore",
  },
  {
    name: "Annapurna Nagar",
    city: "Indore",
  },
  {
    name: "Palasia",
    city: "Indore",
  },
  {
    name: "Budhaniya",
    city: "Indore",
  },
  {
    name: "Gokul Nagar",
    city: "Indore",
  },
  {
    name: "Morod",
    city: "Indore",
  },
  {
    name: "Sanchar Nagar Extention",
    city: "Indore",
  },
  {
    name: "NH3 Agra Mumbai Highway",
    city: "Indore",
  },
  {
    name: "Vallabh Nagar",
    city: "Indore",
  },
  {
    name: "Indrapuri Colony",
    city: "Indore",
  },
  {
    name: "Vaishali Nagar",
    city: "Indore",
  },
  {
    name: "Banganga",
    city: "Indore",
  },
  {
    name: "Shiv Dham Colony",
    city: "Indore",
  },
  {
    name: "Panda",
    city: "Indore",
  },
  {
    name: "Vandana Nagar",
    city: "Indore",
  },
  {
    name: "Sanawadia",
    city: "Indore",
  },
  {
    name: "Dhanvantri Nagar",
    city: "Indore",
  },
  {
    name: "Panchderiya",
    city: "Indore",
  },
  {
    name: "Sainath Colony",
    city: "Indore",
  },
  {
    name: "Tejpur Gadbadi",
    city: "Indore",
  },
  {
    name: "Sawer",
    city: "Indore",
  },
  {
    name: "Malviya Nagar",
    city: "Indore",
  },
  {
    name: "Malharganj",
    city: "Indore",
  },
  {
    name: "Chandan Nagar",
    city: "Indore",
  },
  {
    name: "Mahaveer Nagar",
    city: "Indore",
  },
  {
    name: "Barodiya Ema",
    city: "Indore",
  },
  {
    name: "Usha Nagar Extension",
    city: "Indore",
  },
  {
    name: "Nehru Nagar",
    city: "Indore",
  },
  {
    name: "Baliyakhedi",
    city: "Indore",
  },
  {
    name: "Sirpur",
    city: "Indore",
  },
  {
    name: "Choral",
    city: "Indore",
  },
  {
    name: "Green Park Colony",
    city: "Indore",
  },
  {
    name: "Scheme 54 PU4",
    city: "Indore",
  },
  {
    name: "Sindhi Colony",
    city: "Indore",
  },
  {
    name: "Karolbagh",
    city: "Indore",
  },
  {
    name: "Scheme no 53",
    city: "Indore",
  },
  {
    name: "New palasia",
    city: "Indore",
  },
  {
    name: "Scheme no 74",
    city: "Indore",
  },
  {
    name: "Chikitsak nagar",
    city: "Indore",
  },
  {
    name: "Veena nagar",
    city: "Indore",
  },
  {
    name: "Mansorawar nagar",
    city: "Indore",
  },
  {
    name: "Bapat square",
    city: "Indore",
  },
  {
    name: "Sukliya",
    city: "Indore",
  },
  {
    name: "Royal banglow",
    city: "Indore",
  },
  {
    name: "Dewas Naka",
    city: "Indore",
  },
  {
    name: "Industrial house",
    city: "Indore",
  },
  {
    name: "Khajrana",
    city: "Indore",
  },
  {
    name: "Atal dwar",
    city: "Indore",
  },
  {
    name: "Anand bazar",
    city: "Indore",
  },
  {
    name: "Super corridor",
    city: "Indore",
  },
  {
    name: "Lasudia mori",
    city: "Indore",
  },
  {
    name: "Scheme no 136",
    city: "Indore",
  },
  {
    name: "Scheme no 140",
    city: "Indore",
  },
  {
    name: "Hira nagar",
    city: "Indore",
  },
];

export default function ResponsiveBedcard() {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedLocalities, setSelectedLocalities] = useState([]);
  const [showAddButton, setShowAddButton] = useState(false);
  const [rentBudgetRange, setRentBudgetRange] = useState([0, 500000]);
  const [minRentBudget, maxRentBudget] = rentBudgetRange;
  const [bedroomsFilter, setBedroomsFilter] = useState("");
  const [constructionStatusFilter, setConstructionStatusFilter] = useState("");
  const [propertyTypePageFilter, setPropertyTypePageFilter] = useState("");
  const [furnishedFilter, setfurnishedFilter] = useState("");
  const [postedByFilter, setPostedByFilter] = useState("");
  const [areaRange, setAreaRange] = useState([0, 20000]);
  const [pageCount, setPageCount] = React.useState(0);
  const [localitiesData, setLocalitiesData] = useState("");
  const [tenantTypeFilter, setTenantTypeFilter] = useState("");
  const [cityFilter, setCityFilter] = useState(
    localStorage.getItem("cityName")
  );
  const [buildupTypeFilter, setBuildupTypeFilter] = useState("");
  const [verifiedPropertiesFilter, setVerifiedPropertiesFilter] = useState("");
  const [societyData, setSocietyData] = useState("");
  const [withownerFilter, setWithownerFilter] = useState("");
  const [purchaseTypeFilter, setPurchaseTypeFilter] = useState("");
  const [RERAApprovedFilter, setRERAApprovedFilter] = useState("");
  const [facingFilter, setFacingFilter] = useState("");
  const [PropertiesList, setPropertiesList] = useState([]);
  const [completeResp, setCompleteResp] = React.useState();
  const [propertyIdFilter, setPropertyIdFilter] = React.useState("");
  const [sellBudgetRange, setSellBudgetRange] = useState([0, 50000000]); // Initial Budget Range values
  const [minSellBudget, maxSellBudget] = sellBudgetRange;
  const [bathroomsFilter, setBathroomsFilter] = useState();
  const [selectedDate, setSelectedDate] = useState("");
  const today = new Date().toISOString().split("T")[0];
  const [filtersActivated, setFiltersActivated] = useState(false);

  const [minArea, maxArea] = areaRange;
  // const [filtersActivated, setFiltersActivated] = useState(false);

  // console.log("selectedLocalities data is --------->", selectedLocalities);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const { proprertyKeys, propertyType } = useParams();
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  const increment = () => {
    if (count < 4) {
      setCount((prevCount) => prevCount + 1);
    }
  };

  const decrement = () => {
    if (count > 0) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setShowSuggestions(true);
  };

  const handleFilterData = () => {
    const filteredSuggestions = LocalityData.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    if (!selectedLocalities.includes(suggestion.name)) {
      setSelectedLocalities([...selectedLocalities, suggestion.name]);
    }
    setInputValue("");
    setShowAddButton(true);
    setShowPlaceholder(false); // Hide the placeholder when a locality is selected
    setShowSuggestions(false);
  };

  // const handleClearInput = () => {
  //   setInputValue("");
  //   setShowSuggestions(false);
  // };

  const removeLocality = (locality) => {
    setSelectedLocalities(selectedLocalities.filter((loc) => loc !== locality));
    if (selectedLocalities.length === 1) {
      setShowPlaceholder(true); // Show the placeholder if no localities are selected
    }
  };

  useEffect(() => {
    if (inputValue !== "") {
      handleFilterData();
    } else {
      setShowSuggestions(false);
    }
  }, [inputValue]);

  function refreshPage() {
    window.location.reload();
  }
  const defaultTheme = createTheme();
  const theme = createTheme({
    palette: {
      green: defaultTheme.palette.augmentColor({
        color: {
          main: "rgb(2, 201, 136)",
        },
        name: "green",
      }),
    },
  });

  const rentBudgetHandleChange = (event, newValue) => {
    setRentBudgetRange(newValue);
  };
  const handleRentLowBudgetInputChange = debounce((e) => {
    setRentBudgetRange([e.target.value, rentBudgetRange[1]]);
  }, 300);

  const handleRentHighBudgetInputChange = debounce((e) => {
    setRentBudgetRange([rentBudgetRange[0], e.target.value]);
  }, 300);
  const sellBudgetHandleChange = (event, newValue) => {
    setSellBudgetRange(newValue);
  };
  const areaHandleChange = (event, newValue) => {
    setAreaRange(newValue);
  };

  const handleSellLowBudgetInputChange = debounce((e) => {
    setSellBudgetRange([e.target.value, rentBudgetRange[1]]);
  }, 300);

  const handleSellHighBudgetInputChange = debounce((e) => {
    setSellBudgetRange([rentBudgetRange[0], e.target.value]);
  }, 300);

  const handleLowAreaInputChange = debounce((e) => {
    setAreaRange([e.target.value, areaRange[1]]);
  }, 300);

  const handleHighAreaInputChange = debounce((e) => {
    setAreaRange([areaRange[0], e.target.value]);
  }, 300);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  // Handle date change
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };
  // ****** api's *******

  const filteredProperties = async () => {
    const response = await getApihandler(
      `/multipleFilterSearchOnProperty/${pageCount}/20/?propertyType=${
        propertyTypePageFilter && propertyTypePageFilter.length > 0
          ? propertyTypePageFilter
          : propertyType !== "all"
          ? propertyType
          : ""
      }&addressStr=${
        localitiesData && localitiesData.length > 0
          ? localitiesData
          : proprertyKeys !== "list"
          ? proprertyKeys
          : ""
      }&minPrice=${
        propertyType === "sell" || propertyTypePageFilter === "Sell"
          ? sellBudgetRange[0]
          : rentBudgetRange[0]
      }&maxPrice=${
        propertyType === "sell" || propertyTypePageFilter === "Sell"
          ? sellBudgetRange[1]
          : rentBudgetRange[1]
      }&furnishing=${furnishedFilter || ""}&available_for=${
        tenantTypeFilter || ""
      }&bhk_of_house=${bedroomsFilter || ""}&city=${
        cityFilter || ""
      }&buildUp_type=${buildupTypeFilter || ""}&verify_property=${
        verifiedPropertiesFilter ? 1 : ""
      }&societyStr=${societyData || ""}&owner_availability=${
        withownerFilter || ""
      }&posted_by=${postedByFilter || ""}&purchase_type=${
        purchaseTypeFilter || ""
      }&rera_approved=${RERAApprovedFilter || ""}&facing=${facingFilter || ""}`
    );

    console.log("multipleFilterSearchOnProperty response --------->", response);
    setPropertiesList(response.data);
    setCompleteResp(response);

    // Clear Property Id Filter If selected Any Others=x=x=x=x=x=x

    // setPropertyIdFilterShow(false);
    setPropertyIdFilter("");
  };

  const handleSearch = async () => {
    await filteredProperties(); // Apply filters and fetch data
    navigate(
      selectedLocalities.length > 0
        ? `/properties/${selectedLocalities.join(",")}/rent`
        : "#"
    );
  };
  return (
    <>
      <section>
        <Container className="pt-5" fluid="true">
          <Row className=" pt-5" fluid="true">
            <Col md={12}>
              <Grid
                container
                spacing={2}
                fluid
                className="ps-lg-5 flex-lg-row flex-column-reverse justify-content-center"
              >
                <Grid
                  item
                  lg={6}
                  sm={10}
                  className=" pt-md-4 pt-0 px-lg-4 px-5 text-center home-location-form-column position-relative"
                >
                  <div>
                    <Tabs
                      defaultActiveKey="rent"
                      id="justify-tab-example"
                      className="home-header-location-form "
                      justify="flex-start"
                      style={{
                        paddingX: "5px",
                        borderRadius: "6px",
                        marginBlockEnd: "-1px",
                        // background: "#1F4069",
                      }}
                    >
                      <Tab
                        eventKey="rent"
                        title="Rent/PG"
                        className="home-header-location"
                      >
                        <Box>
                          <Paper
                            sx={{
                              p: "15px 23px 25px",
                              alignItems: "center",
                              justifyContent: "space-between",
                              boxShadow: "none",
                              borderRadius: "0px 8px 8px 8px",
                              zIndex: 1,
                              position: "relative",
                              background: "#1F4069",
                            }}
                            className="home-header-searchBox"
                          >
                            <Box
                              className="search-locationBox-wrapper w-100"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <InputBase
                                sx={{
                                  flex: 1,
                                  backgroundColor: "#fff",
                                  borderRadius: "6px",
                                  color: "#222222",
                                  padding: "8px",
                                  marginRight: "8px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                placeholder="Search Location Example - Vijay Nagar"
                                value={inputValue}
                                onChange={handleInputChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    {selectedLocalities.map(
                                      (locality, index) => (
                                        <Chip
                                          key={index}
                                          label={locality}
                                          onDelete={() =>
                                            removeLocality(locality)
                                          }
                                          sx={{ margin: "2px" }}
                                        />
                                      )
                                    )}
                                  </InputAdornment>
                                }
                                // endAdornment={
                                //   showAddButton && ( // Conditionally render the "Add" button
                                //     <InputAdornment position="end">
                                //       <Button variant="text" color="primary">
                                //         Add
                                //       </Button>
                                //     </InputAdornment>
                                //   )
                                // }
                                className="locationBox"
                              />
                            </Box>
                          </Paper>
                          {showSuggestions && (
                            <Box>
                              <ul className="searchData-wrapper w-50">
                                {suggestions.length > 0 ? (
                                  suggestions.map((suggestion, index) => (
                                    <li
                                      style={{
                                        textAlign: "start",
                                        listStyle: "none",
                                      }}
                                      key={index}
                                      onClick={() =>
                                        handleSuggestionClick(suggestion)
                                      }
                                    >
                                      {suggestion.name}
                                    </li>
                                  ))
                                ) : (
                                  <li style={{ textAlign: "start" }}>
                                    No suggestions available
                                  </li>
                                )}
                              </ul>
                            </Box>
                          )}
                        </Box>
                      </Tab>

                      <Tab
                        eventKey="buy"
                        title="Buy"
                        className="home-header-location"
                      >
                        <Box>
                          <Paper
                            sx={{
                              p: "15px 23px 25px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              boxShadow: "none",
                              borderRadius: "0px 8px 8px 8px",
                              zIndex: 1,
                              position: "relative",
                              background: "#1F4069",
                            }}
                            className="home-header-searchBox"
                          >
                            <Box
                              className="search-locationBox-wrapper w-100"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <InputBase
                                sx={{
                                  flex: 1,
                                  backgroundColor: "#fff",
                                  borderRadius: "6px",
                                  color: "#222222",
                                  padding: "8px",
                                  marginRight: "8px", // Margin to space between input and button
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                // placeholder={

                                //   showPlaceholder
                                //     ? "Search Location Example - Vijay Nagar"
                                //     : ""
                                // }
                                value={inputValue}
                                onChange={handleInputChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    {selectedLocalities.map(
                                      (locality, index) => (
                                        <Chip
                                          key={index}
                                          label={locality}
                                          onDelete={() =>
                                            removeLocality(locality)
                                          }
                                          sx={{ margin: "2px" }}
                                        />
                                      )
                                    )}
                                  </InputAdornment>
                                }
                                endAdornment={
                                  inputValue && (
                                    <InputAdornment position="end">
                                      <IconButton
                                        edge="end"
                                        // onClick={handleClearInput}
                                        sx={{ color: "#222222" }}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }
                                className="locationBox"
                              />
                              {/* <Link
                              to={
                                selectedLocalities.length > 0
                                  ? `/properties/${selectedLocalities.join(
                                      ","
                                    )}/sell`
                                  : "#"
                              }
                            >
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "rgba(2, 201, 136, 1)",
                                  color: "#FFF",
                                  padding: "10px 48px",
                                  // backgroundColor: "black",
                                }}
                                className="search-button"
                              >
                                Search
                              </Button>
                            </Link> */}
                            </Box>
                          </Paper>
                        </Box>
                      </Tab>
                    </Tabs>
                    <Box>
                      {showSuggestions && (
                        <ul className="searchData-wrapper">
                          {suggestions.length > 0 ? (
                            suggestions.map((suggestion, index) => (
                              <li
                                style={{
                                  textAlign: "start",
                                  listStyle: "none",
                                }}
                                key={index}
                                onClick={() =>
                                  handleSuggestionClick(suggestion)
                                }
                              >
                                {suggestion.name}
                              </li>
                            ))
                          ) : (
                            <li style={{ textAlign: "start" }}>
                              No suggestions available
                            </li>
                          )}
                        </ul>
                      )}
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Col>
          </Row>
          {/* ******* card ******** */}
          {selectedLocalities.length > 0 && (
            <Card sx={{ minWidth: 275, margin: "20px", marginBottom: "100px" }}>
              <CardContent>
                {/* Type Of Property */}
                <Grid
                  container
                  lg={12}
                  sm={8}
                  xs={12}
                  className={`search_section_outer_container mb-4`}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Type Of Property:
                  </Typography>
                  <Grid container className="filter-modules-wrapper">
                    <Button
                      className={styles.filter_buttons}
                      style={
                        propertyTypePageFilter === "Rent"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                      onClick={() => {
                        setPropertyTypePageFilter("Rent");
                        // setDisableParamsPropertyType(true);
                      }}
                    >
                      Rent
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setPropertyTypePageFilter("Sell");
                        // setDisableParamsPropertyType(true);
                      }}
                      style={
                        propertyTypePageFilter === "Sell"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Buy
                    </Button>
                  </Grid>
                </Grid>
                {/*Localities Model*/}
                {/* <Grid lg={12} sm={8} xs={12}>
                  <Accordion sx={{ boxShadow: "none" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="p-0"
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "500",
                          lineHeight: "28.9px",
                        }}
                      >
                        Localities
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {LocalityData.slice(0, 5).map((val) => {
                        return (
                          <>
                            <label>
                              <input
                                type="checkbox"
                                onChange={(e) => handleSuggestionClick(val, e)}
                              />
                              &nbsp;&nbsp;
                              <span>{val.name}</span>
                            </label>
                            <br />
                          </>
                        );
                      })}
                      <Button
                        variant="text"
                        className="p-0"
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "16px",
                          color: "black",
                        }}
                        onClick={handleClickOpen1("paper")}
                      >
                        <SearchIcon />
                        More Localities
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                  <Dialog
                    open={openM}
                    onClose={handleClose1}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    sx={{ height: "100%", marginTop: "3rem" }}
                    className="locality-filter-popup"
                  >
                    <div style={{ textAlign: "end", padding: "5px" }}>
                      <CloseIcon onClick={handleClose1} />
                    </div>
                    <DialogTitle id="scroll-dialog-title">
                      Select Localities
                      <Box className="w-100 mt-2">
                        <InputBase
                          sx={{
                            flex: 1,
                            backgroundColor: "#fff",
                            borderRadius: "6px",
                            color: "#222222",
                            border: "1px solid #22222259",
                          }}
                          placeholder="Search Location"
                          value={inputValue}
                          onChange={handleInputChange}
                          className="px-3 py-1 locationBox w-100"
                        />
                      </Box>
                    </DialogTitle>

                    <DialogContent dividers={scroll === "paper"}>
                      <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                      >
                        {suggestions !== undefined &&
                        suggestions !== null &&
                        suggestions.length !== 0
                          ? suggestions.map((val) => {
                              return (
                                <>
                                  <label>
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        handleSuggestionClick(val, e)
                                      }
                                    />
                                    &nbsp;&nbsp;
                                    <span>{val.name}</span>
                                  </label>
                                  <br />
                                </>
                              );
                            })
                          : // <label
                            //   style={{
                            //     textAlign: "start",
                            //     listStyle: "none",
                            //   }}
                            // >
                            //   No Result Found
                            // </label>
                            LocalityData.slice(5, 10).map((val) => {
                              return (
                                <>
                                  <label>
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        handleSuggestionClickModal(val, e)
                                      }
                                    />
                                    &nbsp;&nbsp;
                                    <span>{val.name}</span>
                                  </label>
                                  <br />
                                </>
                              );
                            })}
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>
                </Grid> */}
                {/* Society/Property-Id Filter Input */}
                {/* <Grid
                  lg={12}
                  sm={8}
                  xs={12}
                  // className={`d-${proprertyKeys === "list" ? "block" : "none"}`}
                >
                  <div className="searchData-field-wrapper">
                    <Box sx={{ width: "100%", typography: "body1" }}>
                      <TabContext value={value5}>
                        <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
                          <TabList
                            onChange={handleChange5}
                            aria-label="lab API tabs example"
                          >
                            <Tab label="Society" value="1" />
                            <Tab label="Property Id" value="2" />
                          </TabList>
                        </Box>
                        <TabPanel value="1">
                          {" "}
                          <OutlinedInput
                            fullWidth
                            type="text"
                            id="outlined-adornment-weight"
                            placeholder="Search Societies..."
                            // endAdornment={
                            //   <InputAdornment position="end">
                            //     <SearchIcon sx={{ color: "#222" }} />
                            //   </InputAdornment>
                            // }
                            style={{ border: "1px solid #222222" }}
                            // value={societyData}
                            // onChange={handleSocietyFilter}
                            onChange={handleSocietyInputChange}
                            // disabled={proprertyKeys === "list" ? false : true}
                          />
                          {showSocietySuggestions && (
                            <ul
                              className={`searchData-wrapper d-${
                                societyData.length !== 0 ? "block" : "none"
                              }`}
                            >
                              {societyFilteredData !== undefined &&
                              societyFilteredData !== null &&
                              societyFilteredData.length !== 0 ? (
                                societyFilteredData.map((val, index) => (
                                  <li
                                    style={{
                                      textAlign: "start",
                                      listStyle: "none",
                                    }}
                                    key={index}
                                    onClick={() =>
                                      handleSocietySuggestionClick(
                                        val.soceityName
                                      )
                                    }
                                  >
                                    {val.soceityName}
                                  </li>
                                ))
                              ) : (
                                <li
                                  style={{
                                    textAlign: "start",
                                    listStyle: "none",
                                  }}
                                >
                                  No Result Found
                                </li>
                              )}
                            </ul>
                          )}
                        </TabPanel>
                        <TabPanel value="2">
                          <OutlinedInput
                            fullWidth
                            type="text"
                            id="outlined-adornment-weight"
                            placeholder="Search Property Id..."
                            endAdornment={
                              <InputAdornment position="end">
                                <SearchIcon
                                  sx={{ color: "#222", cursor: "pointer" }}
                                  onClick={() => GetPropertyById()}
                                />
                              </InputAdornment>
                            }
                            style={{ border: "1px solid #222222" }}
                            // value={propertyIdFilter}
                            // onChange={(e) => setPropertyIdFilter(e.target.value)}
                            onChange={handleIdInputChange}
                          />
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </div>
                </Grid> */}
                {/* Filter List */}
                {/* <Grid
                  lg={12}
                  sm={8}
                  xs={12}
                  sx={{
                    borderBottom: "solid 1px rgba(210, 210, 210, 1)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingTop: "1.5rem",
                      paddingBottom: "1.5rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "500",
                        lineHeight: "28.9px",
                      }}
                    >
                      Filters
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        color: "rgba(31, 64, 105, 1)",
                      }}
                    >
                      <span
                        onClick={() => {
                          handleRefresh();
                        }}
                        // onClick={() => {
                        //   setPropertyTypePageFilter("");
                        //   setLocalitiesData("");
                        //   setBuildupTypeFilter("");
                        //   setTenantTypeFilter("");
                        //   setVerifiedPropertiesFilter("");
                        //   setLowestAgeFilter();
                        //   setHighestAgeFilter();
                        //   // setSocietyFilter("");
                        //   setSocietyData("");
                        //   setWithownerFilter("");
                        //   setIndependentPropertiesFilter("");
                        //   setBedroomsFilter("");
                        //   setBathroomsFilter();
                        //   setPropertiesWithPhotoFilter("");

                        //   setAreaRange([0, 20000]);
                        //   setBudgetRange([0, 500000000]);
                        //   setfurnishedFilter("");
                        //   setConstructionStatusFilter("");
                        //   setPostedByFilter("");
                        //   setPurchaseTypeFilter("");
                        //   setRERAApprovedFilter("");
                        //   setFacingFilter("");
                        //   setSelectedDate("");
                        //   setCampusTypeFilter("");

                        //   setPropertyIdFilter("");
                        //   handleRefresh();
                        // }}
                        // style={{
                        //   cursor: "pointer",
                        // }}
                      >
                        Clear
                      </span>
                    </Typography>
                  </Box>
                  <Box>
                    {propertyIdFilterStatus && (
                      <Button
                        className={styles.filter_buttons}
                        endIcon={<ClearIcon />}
                        sx={{ margin: "0 10px 10px 0" }}
                        onClick={() => {
                          setPropertyIdFilter("");
                          handleRefresh();
                        }}
                      >
                        {propertyIdFilter !== ""
                          ? propertyIdFilter
                          : "Id Filter Activated"}
                      </Button>
                    )}
                    {filtersActivated && (
                      <Link to="/properties/list/all">
                        <Button
                          className={styles.filter_buttons}
                          endIcon={<ClearIcon />}
                          sx={{ margin: "0 10px 10px 0" }}
                          onClick={() => {
                            handleRefresh();
                          }}
                        >
                          Filters Activated
                        </Button>
                      </Link>
                    )}
                  </Box>
                </Grid> */}
                {/* Hide Already Seen */}
                <Grid
                  container
                  lg={12}
                  sm={8}
                  xs={12}
                  className={`search_section_outer_container`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Hide Already Seen:
                  </Typography>
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                    // label="iOS style"
                  />
                </Grid>
                {/* Budget */}
                <Grid
                  container
                  className={`search_section_outer_container d-${
                    propertyType === "sell" || propertyTypePageFilter === "Sell"
                      ? "none"
                      : "block"
                  }`}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Budget:
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Typography>Min: {minRentBudget}</Typography>
                      </Grid>
                      <Grid item xs>
                        <Slider
                          value={rentBudgetRange}
                          onChange={rentBudgetHandleChange}
                          valueLabelDisplay="auto"
                          valueLabelFormat={(value) => value}
                          min={0}
                          max={500000}
                          color="green"
                        />
                      </Grid>
                      <Grid item>
                        <Typography>Max: {maxRentBudget}</Typography>
                      </Grid>
                    </Grid>
                  </ThemeProvider>

                  <Grid
                    container
                    className={styles.search_button_container2}
                    lg={12}
                    sm={12}
                    xs={10}
                  >
                    <Grid lg={4.5} sm={3} xs={5} style={{ padding: "5px 0" }}>
                      <Box>
                        <TextField
                          size="small"
                          className="w-75"
                          id="outlined-basic"
                          placeholder="Min"
                          variant="outlined"
                          name="minArea"
                          type="number"
                          onChange={handleRentLowBudgetInputChange}
                        />
                      </Box>
                    </Grid>
                    <Grid lg={4.5} sm={3} xs={5} style={{ padding: "5px 0" }}>
                      <Box>
                        <TextField
                          size="small"
                          className="w-75"
                          id="outlined-basic"
                          placeholder="Max"
                          variant="outlined"
                          name="maxArea"
                          type="number"
                          onChange={handleRentHighBudgetInputChange}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Only Show On Sell Filter***** */}
                <Grid
                  container
                  className={`search_section_outer_container d-${
                    propertyType === "sell" || propertyTypePageFilter === "Sell"
                      ? "block"
                      : "none"
                  }`}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Budget:
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Typography>Min: {minSellBudget}</Typography>
                      </Grid>
                      <Grid item xs>
                        <Slider
                          value={sellBudgetRange}
                          onChange={sellBudgetHandleChange}
                          valueLabelDisplay="auto"
                          valueLabelFormat={(value) => value}
                          min={0}
                          max={50000000}
                          color="green"
                        />
                      </Grid>
                      <Grid item>
                        <Typography>Max: {maxSellBudget}</Typography>
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                  <Grid
                    container
                    className={styles.search_button_container2}
                    lg={12}
                    sm={12}
                    xs={10}
                  >
                    <Grid lg={4.5} sm={3} xs={5} style={{ padding: "5px 0" }}>
                      <Box>
                        <TextField
                          size="small"
                          className="w-75"
                          id="outlined-basic"
                          placeholder="Min"
                          variant="outlined"
                          name="minArea"
                          type="number"
                          onChange={handleSellLowBudgetInputChange}
                        />
                      </Box>
                    </Grid>
                    <Grid lg={4.5} sm={3} xs={5} style={{ padding: "5px 0" }}>
                      <Box>
                        <TextField
                          size="small"
                          className="w-75"
                          id="outlined-basic"
                          placeholder="Max"
                          variant="outlined"
                          name="maxArea"
                          type="number"
                          onChange={handleSellHighBudgetInputChange}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Buildup Type */}
                <Grid
                  container
                  xs={12}
                  className="search_section_outer_container"
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Buildup Type:
                  </Typography>

                  {buildupTypeFilter && (
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "20px",
                        fontWeight: "400",
                        marginTop: "10px",
                      }}
                    >
                      Applied Filter: {buildupTypeFilter}
                    </Typography>
                  )}

                  <Grid container className="filter-modules-wrapper">
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => setBuildupTypeFilter("Residential Land")}
                      style={
                        buildupTypeFilter === "Residential Land"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Residential Land
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => setBuildupTypeFilter("Studio")}
                      style={
                        buildupTypeFilter === "Studio"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Studio
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() =>
                        setBuildupTypeFilter("Residential Apartment")
                      }
                      style={
                        buildupTypeFilter === "Residential Apartment"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Residential Apartment
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => setBuildupTypeFilter("Builder")}
                      style={
                        buildupTypeFilter === "Builder"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Builder
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => setBuildupTypeFilter("Independent House")}
                      style={
                        buildupTypeFilter === "Independent House"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Independent House
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => setBuildupTypeFilter("Floor")}
                      style={
                        buildupTypeFilter === "Floor"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Floor
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => setBuildupTypeFilter("Villa Independent")}
                      style={
                        buildupTypeFilter === "Villa Independent"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Villa Independent
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => setBuildupTypeFilter("Farm House")}
                      style={
                        buildupTypeFilter === "Farm House"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Farm House
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => setBuildupTypeFilter("Serviced Apartment")}
                      style={
                        buildupTypeFilter === "Serviced Apartment"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Serviced Apartment
                    </Button>
                  </Grid>
                </Grid>
                {/* Tenant Type/Available for */}
                <Grid
                  container
                  lg={12}
                  sm={8}
                  xs={12}
                  className={`search_section_outer_container d-${
                    propertyType === "sell" || propertyTypePageFilter === "Sell"
                      ? "none"
                      : "block"
                  }`}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Tenant Type:
                  </Typography>

                  <Grid container className="filter-modules-wrapper">
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => setTenantTypeFilter("Family")}
                      style={
                        tenantTypeFilter === "Family"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Family
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => setTenantTypeFilter("Single Woman")}
                      style={
                        tenantTypeFilter === "Single Woman"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Single Woman
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => setTenantTypeFilter("Single Man")}
                      style={
                        tenantTypeFilter === "Single Man"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Single Man
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() =>
                        setTenantTypeFilter("Tenants with Company Lease")
                      }
                      style={
                        tenantTypeFilter === "Tenants with Company Lease"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Tenants with Company Lease
                    </Button>
                  </Grid>
                </Grid>
                {/* Verified properties */}
                <Grid
                  container
                  lg={12}
                  sm={8}
                  xs={12}
                  className="search_section_outer_container"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Verified Properties:
                  </Typography>

                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={verifiedPropertiesFilter}
                        onChange={(e) =>
                          setVerifiedPropertiesFilter(e.target.checked)
                        }
                      />
                    }
                  />
                </Grid>
                {/* With Owner */}
                {/* <Grid
                  container
                  lg={12}
                  sm={8}
                  xs={12}
                  className={`search_section_outer_container d-${
                    propertyType === "sell" || propertyTypePageFilter === "Sell"
                      ? "none"
                      : "block"
                  }`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={withownerFilter}
                    name="withowner"
                  >
                    <div>
                      <FormControlLabel
                        value="With Owner"
                        control={
                          <Radio
                            sx={{
                              color: "rgba(34, 34, 34, 1)",
                              "&.Mui-checked": {
                                color: "rgba(2, 201, 136, 1)",
                              },
                            }}
                            onClick={() => {
                              setWithownerFilter("With Owner");
                            }}
                          />
                        }
                        label="With Owner"
                      />
                      <FormControlLabel
                        value="Without Owner"
                        control={
                          <Radio
                            sx={{
                              color: "rgba(34, 34, 34, 1)",
                              "&.Mui-checked": {
                                color: "rgba(2, 201, 136, 1)",
                              },
                            }}
                            onClick={() => {
                              setWithownerFilter("Without Owner");
                            }}
                          />
                        }
                        label="Without Owner"
                      />
                    </div>
                  </RadioGroup>
                </Grid> */}
                {/* Bedrooms */}
                <Grid
                  container
                  lg={12}
                  sm={11}
                  xs={12}
                  className={`search_section_outer_container`}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Bedrooms:
                  </Typography>
                  <Grid container className="filter-modules-wrapper">
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setBedroomsFilter("1 BHK");
                      }}
                      style={
                        bedroomsFilter === "1 BHK"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      1BHK/1RK
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setBedroomsFilter("2 BHK");
                      }}
                      style={
                        bedroomsFilter === "2 BHK"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      2 BHK
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setBedroomsFilter("3 BHK");
                      }}
                      style={
                        bedroomsFilter === "3 BHK"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      3 BHK
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setBedroomsFilter("4 BHK");
                      }}
                      style={
                        bedroomsFilter === "4 BHK"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      4 BHK
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setBedroomsFilter("5 BHK");
                      }}
                      style={
                        bedroomsFilter === "5 BHK"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      5+ BHK
                    </Button>
                  </Grid>
                </Grid>
                {/* Bathrooms */}
                <Grid
                  container
                  lg={12}
                  sm={8}
                  xs={12}
                  className={`search_section_outer_container d-${
                    propertyType === "sell" || propertyTypePageFilter === "Sell"
                      ? "none"
                      : "block"
                  }`}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    No. Of Bathrooms:
                  </Typography>
                  <Grid container className="filter-modules-wrapper">
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setBathroomsFilter(1);
                      }}
                      style={
                        bathroomsFilter === 1
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      1
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setBathroomsFilter(2);
                      }}
                      style={
                        bathroomsFilter === 2
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      2
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setBathroomsFilter(3);
                      }}
                      style={
                        bathroomsFilter === 3
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      3
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setBathroomsFilter(4);
                      }}
                      style={
                        bathroomsFilter === 4
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      4
                    </Button>{" "}
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setBathroomsFilter(5);
                      }}
                      style={
                        bathroomsFilter === 5
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      5
                    </Button>
                  </Grid>
                </Grid>
                {/* Furnishing */}
                <Grid container className={`search_section_outer_container`}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Furnishing:
                  </Typography>
                  <Grid container className="filter-modules-wrapper">
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        // filteredProperties("Furnished");
                        setfurnishedFilter("Fully Furnished");
                      }}
                      style={
                        furnishedFilter === "Fully Furnished"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Fully Furnished
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        // filteredProperties("Semi Furnished");
                        setfurnishedFilter("Semi Furnished");
                      }}
                      style={
                        furnishedFilter === "Semi Furnished"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Semi Furnished
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        // filteredProperties("Unfurnished");
                        setfurnishedFilter("Unfurnished");
                      }}
                      style={
                        furnishedFilter === "Unfurnished"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Unfurnished
                    </Button>
                  </Grid>
                </Grid>
                {/* Area */}
                <Grid container className={`search_section_outer_container`}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Area (Sq. Ft.):
                  </Typography>
                  <Grid container className={`applied-filters`}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                      Applied Area Range: {minArea} - {maxArea} Sq. Ft.
                    </Typography>
                  </Grid>
                  <ThemeProvider theme={theme}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Typography>Min: {minArea}</Typography>
                      </Grid>
                      <Grid item xs>
                        <Slider
                          value={areaRange}
                          onChange={areaHandleChange}
                          valueLabelDisplay="auto"
                          valueLabelFormat={(value) => value}
                          min={0}
                          max={20000}
                          color="green"
                        />
                      </Grid>
                      <Grid item>
                        <Typography>Max: {maxArea}</Typography>
                      </Grid>
                    </Grid>
                  </ThemeProvider>

                  <Grid
                    container
                    className={styles.search_button_container2}
                    lg={12}
                    sm={12}
                    xs={10}
                  >
                    <Grid lg={4.5} sm={3} xs={5} style={{ padding: "5px 0" }}>
                      <Box>
                        <TextField
                          size="small"
                          className="w-75"
                          id="outlined-basic"
                          placeholder="Min"
                          variant="outlined"
                          name="minArea"
                          type="number"
                          // value={areaRange[0]}
                          // onChange={(e) => setAreaRange([e.target.value, areaRange[1]])}
                          onChange={handleLowAreaInputChange}
                        />
                      </Box>
                    </Grid>
                    <Grid lg={4.5} sm={3} xs={5} style={{ padding: "5px 0" }}>
                      <Box>
                        <TextField
                          size="small"
                          className="w-75"
                          id="outlined-basic"
                          placeholder="Max"
                          variant="outlined"
                          name="maxArea"
                          type="number"
                          // value={areaRange[1]}
                          // onChange={(e) => setAreaRange([areaRange[0], e.target.value])}
                          onChange={handleHighAreaInputChange}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Available From */}
                <Grid
                  container
                  lg={12}
                  sm={8}
                  xs={12}
                  className={`search_section_outer_container d-${
                    propertyType === "sell" || propertyTypePageFilter === "Sell"
                      ? "none"
                      : "block"
                  }`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Available From:
                  </Typography>
                  <div className="select-date-inputWrapper">
                    <div className="date-input">
                      <input
                        type="date"
                        id="dateInput"
                        name="dateInput"
                        value={selectedDate}
                        min={today}
                        onChange={handleDateChange}
                        required
                      />
                      {selectedDate && (
                        <button className="clear-button" >
                          &#10006;
                        </button>
                      )}
                    </div>
                  </div>
                </Grid>
                {/* Age Of Property */}
                {/* <Grid
                  container
                  lg={12}
                  sm={8}
                  xs={12}
                  className={`search_section_outer_container d-${
                    propertyType === "sell" || propertyTypePageFilter === "Sell"
                      ? "none"
                      : "block"
                  }`}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Age Of Property:
                  </Typography>
                  <Grid container className="filter-modules-wrapper">
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setLowestAgeFilter(0);
                        setHighestAgeFilter(1);
                      }}
                      style={
                        lowestAgeFilter === 0 && highestAgeFilter === 1
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      0-1 Years Old
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setLowestAgeFilter(1);
                        setHighestAgeFilter(5);
                      }}
                      style={
                        lowestAgeFilter === 1 && highestAgeFilter === 5
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      1-5 Years Old
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setLowestAgeFilter(5);
                        setHighestAgeFilter(10);
                      }}
                      style={
                        lowestAgeFilter === 5 && highestAgeFilter === 10
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      5-10 Years Old
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setLowestAgeFilter(10);
                        setHighestAgeFilter();
                      }}
                      style={
                        lowestAgeFilter === 10
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      10+ Years Old
                    </Button>
                  </Grid>
                </Grid> */}
                {/* Independent */}
                {/* <Grid
                  container
                  lg={12}
                  sm={8}
                  xs={12}
                  className={`search_section_outer_container d-${
                    propertyType === "sell" || propertyTypePageFilter === "Sell"
                      ? "none"
                      : "block"
                  }`}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "20px",
                        fontWeight: "400",
                      }}
                    >
                      Independent:
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={independentPropertiesFilter}
                          onChange={(e) =>
                            setIndependentPropertiesFilter(e.target.checked)
                          }
                        />
                      }
                    />
                  </Box>
                </Grid> */}

                {/* Properties With Photo */}
                {/* <Grid
                  container
                  lg={12}
                  sm={8}
                  xs={12}
                  className={`search_section_outer_container`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Properties With Photo:
                  </Typography>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={propertiesWithPhotoFilter}
                        onChange={(e) =>
                          setPropertiesWithPhotoFilter(e.target.checked)
                        }
                      />
                    }
                  />
                </Grid> */}
                {/* Premium Properties */}
                {/* <Grid
                  container
                  lg={12}
                  sm={8}
                  xs={12}
                  className={`search_section_outer_container`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Premium:
                  </Typography>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={premiumFilter}
                        onChange={(e) => setPremiumFilter(e.target.checked)}
                      />
                    }
                  />
                </Grid> */}
                {/* Construction Status */}
                <Grid
                  container
                  className={`search_section_outer_container d-${
                    propertyType === "sell" || propertyTypePageFilter === "Sell"
                      ? "block"
                      : "none"
                  }`}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Construction Status:
                  </Typography>
                  <Grid container className="filter-modules-wrapper">
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setConstructionStatusFilter("Ready to Move");
                      }}
                      style={
                        constructionStatusFilter === "Ready to Move"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Ready to Move
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setConstructionStatusFilter("Under Construction");
                      }}
                      style={
                        constructionStatusFilter === "Under Construction"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Under Construction
                    </Button>
                  </Grid>
                </Grid>
                {/* Posted By */}
                <Grid
                  container
                  className={`search_section_outer_container d-${
                    propertyType === "sell" || propertyTypePageFilter === "Sell"
                      ? "block"
                      : "none"
                  }`}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Posted By:
                  </Typography>
                  <Grid container className="filter-modules-wrapper">
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        // filteredProperties("Furnished");
                        setPostedByFilter("Owner");
                      }}
                      style={
                        postedByFilter === "Owner"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Owner
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        // filteredProperties("Semi Furnished");
                        setPostedByFilter("Builder");
                      }}
                      style={
                        postedByFilter === "Builder"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Builder
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        // filteredProperties("Semi Furnished");
                        setPostedByFilter("Dealer");
                      }}
                      style={
                        postedByFilter === "Dealer"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Dealer
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        // filteredProperties("Semi Furnished");
                        setPostedByFilter("Feature Dealer");
                      }}
                      style={
                        postedByFilter === "Feature Dealer"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Feature Dealer
                    </Button>
                  </Grid>
                </Grid>
                {/* Purchase Type */}
                <Grid
                  container
                  className={`search_section_outer_container d-${
                    propertyType === "sell" || propertyTypePageFilter === "Sell"
                      ? "block"
                      : "none"
                  }`}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Purchase Type:
                  </Typography>
                  <Grid container className="filter-modules-wrapper">
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        // filteredProperties("Furnished");
                        setPurchaseTypeFilter("Resell");
                      }}
                      style={
                        purchaseTypeFilter === "Resell"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Resell
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        // filteredProperties("Semi Furnished");
                        setPurchaseTypeFilter("New Booking");
                      }}
                      style={
                        purchaseTypeFilter === "New Booking"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      New Booking
                    </Button>
                  </Grid>
                </Grid>
                {/* RERA Approved */}
                <Grid
                  container
                  className={`search_section_outer_container d-${
                    propertyType === "sell" || propertyTypePageFilter === "Sell"
                      ? "block"
                      : "none"
                  }`}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    RERA Approved:
                  </Typography>
                  <Grid container className="filter-modules-wrapper">
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        // filteredProperties("Semi Furnished");
                        setRERAApprovedFilter("RERA Approved Properties");
                      }}
                      style={
                        RERAApprovedFilter === "RERA Approved Properties"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      RERA Approved Properties
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        // filteredProperties("Semi Furnished");
                        setRERAApprovedFilter("RERA Registered Dealers");
                      }}
                      style={
                        purchaseTypeFilter === "RERA Registered Dealers"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      RERA Registered Dealers
                    </Button>
                  </Grid>
                </Grid>
                {/* Facing */}
                <Grid
                  container
                  className={`search_section_outer_container d-${
                    propertyType === "sell" || propertyTypePageFilter === "Sell"
                      ? "block"
                      : "none"
                  }`}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Facing:
                  </Typography>
                  <Grid container className="filter-modules-wrapper">
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        // filteredProperties("Semi Furnished");
                        setFacingFilter("East");
                      }}
                      style={
                        facingFilter === "East"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      East
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        // filteredProperties("Semi Furnished");
                        setFacingFilter("West");
                      }}
                      style={
                        facingFilter === "West"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      West
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        // filteredProperties("Semi Furnished");
                        setFacingFilter("North");
                      }}
                      style={
                        facingFilter === "North"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      North
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        // filteredProperties("Semi Furnished");
                        setFacingFilter("South");
                      }}
                      style={
                        facingFilter === "South"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      South
                    </Button>
                  </Grid>
                </Grid>
                {/* Campus Type */}
                {/* <Grid
                  container
                  className={`search_section_outer_container d-${
                    propertyType === "sell" || propertyTypePageFilter === "Sell"
                      ? "none"
                      : "block"
                  }`}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Campus Type:
                  </Typography>
                  <Grid container className="filter-modules-wrapper">
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        setCampusTypeFilter("Open");
                      }}
                      style={
                        campusTypeFilter === "Open"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Open
                    </Button>
                    <Button
                      className={styles.filter_buttons}
                      onClick={() => {
                        // filteredProperties("Semi Furnished");
                        setCampusTypeFilter("Covered");
                      }}
                      style={
                        campusTypeFilter === "Covered"
                          ? { backgroundColor: "rgb(2 201 136 / 25%)" }
                          : {}
                      }
                    >
                      Covered
                    </Button>
                  </Grid>
                </Grid> */}

                {/* Submit Buttons------------------------------------------------------------- */}
                <Grid container className={`search_section_outer_container `}>
                  <Grid container className="filter-modules-wrapper">
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "rgba(2, 201, 136, 1)",
                        color: "#FFF",
                        textTransform: "capitalize",
                        paddingTop: ".5rem",
                        paddingBottom: ".5rem",
                      }}
                      className="px-md-4 px-2"
                      onClick={() => {
                        filteredProperties();
                        setFiltersActivated(true);
                        // toggleDrawer();
                        setTimeout(() => {
                          const queryParams = {
                            propertyType:
                              propertyTypePageFilter !== null &&
                              propertyTypePageFilter !== undefined &&
                              propertyTypePageFilter.length !== 0
                                ? propertyTypePageFilter
                                : propertyTypePageFilter.length === 0 &&
                                  propertyType !== "all"
                                ? propertyType
                                : "",
                            addressStr:
                              localitiesData !== null &&
                              localitiesData !== undefined &&
                              localitiesData.length !== 0
                                ? localitiesData
                                : localitiesData.length === 0 &&
                                  proprertyKeys !== "list"
                                ? proprertyKeys
                                : "",
                            minPrice:
                              propertyType === "sell" ||
                              propertyTypePageFilter === "Sell"
                                ? sellBudgetRange[0]
                                : rentBudgetRange[0],
                            maxPrice:
                              propertyType === "sell" ||
                              propertyTypePageFilter === "Sell"
                                ? sellBudgetRange[1]
                                : rentBudgetRange[1],
                            furnishing: furnishedFilter,
                            available_for: tenantTypeFilter,
                            bhk_of_house: bedroomsFilter,
                            city:
                              cityFilter !== null && cityFilter !== undefined
                                ? cityFilter
                                : "",
                            buildUp_type: buildupTypeFilter,
                            // verify_property:
                            //   verifiedPropertiesFilter === true
                            //     ? 1
                            //     : verifiedPropertiesFilter === false
                            //     ? ""
                            //     : "",
                            // minAge:
                            //   lowestAgeFilter === undefined ? "" : lowestAgeFilter,
                            // maxAge:
                            //   highestAgeFilter === undefined ? "" : highestAgeFilter,
                            societyStr: societyData,
                            owner_availability:
                              withownerFilter !== null &&
                              withownerFilter !== undefined &&
                              withownerFilter !== ""
                                ? withownerFilter
                                : "",
                            // independent:
                            //   independentPropertiesFilter === true
                            //     ? 1
                            //     : independentPropertiesFilter === false
                            //     ? ""
                            //     : "",
                            // bathrooms:
                            //   bathroomsFilter !== null && bathroomsFilter !== undefined
                            //     ? bathroomsFilter
                            //     : "",
                            // propertyImages:
                            //   propertiesWithPhotoFilter === true
                            //     ? true
                            //     : propertiesWithPhotoFilter === false
                            //     ? ""
                            //     : "",
                            // minArea: areaRange[0],
                            // maxArea: areaRange[1],
                            construction_status:
                              constructionStatusFilter !== null &&
                              constructionStatusFilter !== undefined &&
                              constructionStatusFilter !== ""
                                ? constructionStatusFilter
                                : "",
                            posted_by:
                              postedByFilter !== null &&
                              postedByFilter !== undefined &&
                              postedByFilter !== ""
                                ? postedByFilter
                                : "",
                            purchase_type:
                              purchaseTypeFilter !== null &&
                              purchaseTypeFilter !== undefined &&
                              purchaseTypeFilter !== ""
                                ? purchaseTypeFilter
                                : "",
                            rera_approved:
                              RERAApprovedFilter !== null &&
                              RERAApprovedFilter !== undefined &&
                              RERAApprovedFilter !== ""
                                ? RERAApprovedFilter
                                : "",
                            facing:
                              facingFilter !== null &&
                              facingFilter !== undefined &&
                              facingFilter !== ""
                                ? facingFilter
                                : "",
                            // startDate: selectedDate,
                            // premium:
                            //   premiumFilter === true
                            //     ? 1
                            //     : premiumFilter === false
                            //     ? ""
                            //     : "",
                            // campus_type:
                            //   campusTypeFilter !== null &&
                            //   campusTypeFilter !== undefined &&
                            //   campusTypeFilter !== ""
                            //     ? campusTypeFilter
                            //     : "",
                          };
                          const filteredQueryParams = Object.fromEntries(
                            Object.entries(queryParams).filter(
                              ([_, value]) => value !== ""
                            )
                          );
                          const queryString = new URLSearchParams(
                            filteredQueryParams
                          ).toString();
                          navigate(`?${queryString}`);
                        }, 1000);
                      }}
                    >
                      Filter Now
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Container>
        {/* Conditionally render the footer */}
        {selectedLocalities.length > 0 && (
          <footer
            style={{
              position: "fixed",
              bottom: "0",
              left: "0",
              width: "100%",
              // backgroundColor: "#333", // Ensure this line is uncommented to set the background color
              color: "white",
              padding: "10px 20px",
              boxShadow: "0px -8px 24px rgba(149, 157, 165, 0.4)",
            }}
          >
            <div className="d-flex justify-content-between">
              <Button variant="text">Clear All</Button>
              <Link
                to={
                  selectedLocalities.length > 0
                    ? `/properties/${selectedLocalities.join(",")}/rent`
                    : "#"
                }
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(2, 201, 136, 1)",
                    color: "#FFF",
                    padding: "10px 48px",
                  }}
                  className="search-button"
                  onClick={filteredProperties}
                >
                  Search
                </Button>
              </Link>

              {/* <Button
                variant="contained"
                onClick={() => {
                  filteredProperties();
                  // setFiltersActivated(true);
                  // toggleDrawer();
                  setTimeout(() => {
                    const queryParams = {
                      propertyType:
                        propertyTypePageFilter !== null &&
                        propertyTypePageFilter !== undefined &&
                        propertyTypePageFilter.length !== 0
                          ? propertyTypePageFilter
                          : propertyTypePageFilter.length === 0 &&
                            propertyType !== "all"
                          ? propertyType
                          : "",
                      addressStr:
                        localitiesData !== null &&
                        localitiesData !== undefined &&
                        localitiesData.length !== 0
                          ? localitiesData
                          : localitiesData.length === 0 &&
                            proprertyKeys !== "list"
                          ? proprertyKeys
                          : "",
                      minPrice:
                        propertyType === "sell" ||
                        propertyTypePageFilter === "Sell"
                          ? sellBudgetRange[0]
                          : rentBudgetRange[0],
                      maxPrice:
                        propertyType === "sell" ||
                        propertyTypePageFilter === "Sell"
                          ? sellBudgetRange[1]
                          : rentBudgetRange[1],
                      furnishing: furnishedFilter,
                      available_for: tenantTypeFilter,
                      bhk_of_house: bedroomsFilter,
                      city:
                        cityFilter !== null && cityFilter !== undefined
                          ? cityFilter
                          : "",
                      buildUp_type: buildupTypeFilter,
                      // verify_property:
                      //   verifiedPropertiesFilter === true
                      //     ? 1
                      //     : verifiedPropertiesFilter === false
                      //     ? ""
                      //     : "",
                      // minAge:
                      //   lowestAgeFilter === undefined ? "" : lowestAgeFilter,
                      // maxAge:
                      //   highestAgeFilter === undefined ? "" : highestAgeFilter,
                      societyStr: societyData,
                      owner_availability:
                        withownerFilter !== null &&
                        withownerFilter !== undefined &&
                        withownerFilter !== ""
                          ? withownerFilter
                          : "",
                      // independent:
                      //   independentPropertiesFilter === true
                      //     ? 1
                      //     : independentPropertiesFilter === false
                      //     ? ""
                      //     : "",
                      // bathrooms:
                      //   bathroomsFilter !== null && bathroomsFilter !== undefined
                      //     ? bathroomsFilter
                      //     : "",
                      // propertyImages:
                      //   propertiesWithPhotoFilter === true
                      //     ? true
                      //     : propertiesWithPhotoFilter === false
                      //     ? ""
                      //     : "",
                      // minArea: areaRange[0],
                      // maxArea: areaRange[1],
                      construction_status:
                        constructionStatusFilter !== null &&
                        constructionStatusFilter !== undefined &&
                        constructionStatusFilter !== ""
                          ? constructionStatusFilter
                          : "",
                      posted_by:
                        postedByFilter !== null &&
                        postedByFilter !== undefined &&
                        postedByFilter !== ""
                          ? postedByFilter
                          : "",
                      purchase_type:
                        purchaseTypeFilter !== null &&
                        purchaseTypeFilter !== undefined &&
                        purchaseTypeFilter !== ""
                          ? purchaseTypeFilter
                          : "",
                      rera_approved:
                        RERAApprovedFilter !== null &&
                        RERAApprovedFilter !== undefined &&
                        RERAApprovedFilter !== ""
                          ? RERAApprovedFilter
                          : "",
                      facing:
                        facingFilter !== null &&
                        facingFilter !== undefined &&
                        facingFilter !== ""
                          ? facingFilter
                          : "",
                      // startDate: selectedDate,
                      // premium:
                      //   premiumFilter === true
                      //     ? 1
                      //     : premiumFilter === false
                      //     ? ""
                      //     : "",
                      // campus_type:
                      //   campusTypeFilter !== null &&
                      //   campusTypeFilter !== undefined &&
                      //   campusTypeFilter !== ""
                      //     ? campusTypeFilter
                      //     : "",
                    };
                    const filteredQueryParams = Object.fromEntries(
                      Object.entries(queryParams).filter(
                        ([_, value]) => value !== ""
                      )
                    );
                    const queryString = new URLSearchParams(
                      filteredQueryParams
                    ).toString();
                    navigate(`?${queryString}`);
                  }, 1000);
                }}
              >
                Next
                <ArrowForwardIcon />
              </Button> */}
            </div>
          </footer>
        )}
      </section>
    </>
  );
}
