/** @format */

import React, { useEffect } from "react";
import HeaderSection from "../../Components/Homepage/HeaderSection";
import PerfectChoice from "../../Components/Homepage/PerfectChoice";
import Layout from "../../LayoutWrapper/AdminLayout";
import Residential from "../../Components/Aboutpage/residential";
import Postproperty from "../../Components/Aboutpage/postproperty";
import PartnersList from "../../Components/Aboutpage/PartnersList";
import NewArrivalProperty from "../../Components/Homepage/newArrivalProperty";
import SelectInterest from "../../Components/Homepage/selectInterest";
import PropertyType from "../../Components/Homepage/PropertyType";
import FreeConsultant from "../../Components/Homepage/FreeConsultant";
import PopularLocalities from "../../Components/Homepage/PopularLocalities";
import GharaajHelp from "../../Components/Homepage/GharaajHelp";
import PostPropertyWithGharaaj from "../../Components/Homepage/PostPropertyWithGharaaj";
import ReferEarn from "../../Components/Homepage/ReferEarn";
import CustomerLoves from "../../Components/Homepage/CustomerLoves";
import NoBroker from "../../Components/Homepage/NoBroker";
import PropertyListFind from "../../Components/Homepage/PropertyListFind";
import PopularTownship from "../../Components/Homepage/PopularTownship";
import InsightsTools from "../../Components/Homepage/InsightsTools";
// import { messaging } from "../../firebase";
import { getToken } from "firebase/messaging";
import { putApihandler } from "../../Apihandler";
import ChatWithUs from "../../LayoutWrapper/ChatWithUs";
import { Helmet } from "react-helmet";

const Home = () => {
  const [userId, setUserId] = React.useState();

  useEffect(() => {
    localStorage.setItem("activatedPage", "home");
  }, []);

  // async function requestPermission() {
  //   const permission = await Notification.requestPermission();
  //   if (permission === "granted") {
  //     // Generate Token
  //     const token = await getToken(messaging, {
  //       vapidKey:
  //         "BPgeik_-5RsHPffxlCzoyIg1POfxYmxi5obN7kZKMPvyy4zJwk2yQDRz0r4mzY5uuqBxuAZtp11bBIRAN_a6vd8",
  //     });
  //     // console.log("Token Gen", token);

  //     const item = {
  //       fcm_token: token,
  //     };
  //     // console.log("item->", item);
  //     // if (item !== undefined) {
  //     const resoftoken = await putApihandler(`/addFcmToken/${userId}`, item);
  //     // console.log("resoftoken--->", resoftoken);
  //     // }
  //   } else if (permission === "denied") {
  //     alert("You denied for the notification");
  //   }
  // }

  // useEffect(() => {
  //   // Req user for notification permission
  //   if (userId !== undefined && userId !== null) {
  //     requestPermission();
  //   }
  // }, [userId]);

  // Get Local Storage Data
  React.useEffect(() => {
    setUserId(localStorage.getItem("UserId"));
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Find Your Ideal Home in Indore: Buy or Rent with Ease</title>
        <meta
          name="description"
          content="Discover a range of homes for sale or rent in Indore with our expert guidance. Whether you're looking for a cozy nest or a spacious abode, we have options that suit every need."
        />
        <meta name="keywords" content="keyword1, keyword2, keyword3" />
      </Helmet>
      <div className="home-wrapper">
        <HeaderSection />
        <NewArrivalProperty />
        <PropertyType />
        <FreeConsultant />
        <SelectInterest />
        <GharaajHelp />
        <PopularLocalities />
        <PostPropertyWithGharaaj />
        <ReferEarn />
        <PerfectChoice />
        <CustomerLoves />
        <PropertyListFind />
        <PopularTownship />
        <InsightsTools />
        {/* <PropertyBenifits /> */}
        {/* <BrowseProperty /> */}
        {/* <PropertyListing /> */}
        {/* <MadeForAll /> */}
        {/* <HomeOwner /> */}
        {/* <SuccessStories /> */}
        {/* <ResidentialCollection /> */}
        <Residential />
        {/* <NewArrivalProperty />
        <HotProperty /> */}
        <Postproperty />
        <PartnersList />
        <NoBroker />

        {/* Chat Icons----------------------- */}
        <ChatWithUs />
      </div>
    </Layout>
  );
};

export default Home;
