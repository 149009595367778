/** @format */

import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styles from "../../Css/signup-login.module.css";
import bgimg from "../../Images/Universal/signup.webp";
import facebook from "../../Images/Universal/facebook.png";
import google from "../../Images/Universal/google.png";
import logo from "../../Images/Universal/logo.png";
import { useForm } from "react-hook-form";
import { postApihandler, putApihandler } from "../../Apihandler";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { getToken } from "firebase/messaging";
// import { messaging } from "../../firebase";
import PropertyImg1 from "../../Images/Login/login-propertyImg.png";
import { Carousel } from "react-bootstrap";
import GoogleLogin from "react-google-login";
import { auth, FacebookAuth, provider } from "./config";
import { signInWithPopup } from "firebase/auth";
import { useEffect } from "react";

const defaultTheme = createTheme();

export default function SignInSide() {
  const [userEmail, setUserEmail] = React.useState(null);
  // console.log("userEmail - ", typeof userEmail);
  const [userName, setUserName] = React.useState(null);

  // console.log("userName - ", userName);
  const { register, handleSubmit, reset } = useForm();
  const [toogle, setToggle] = React.useState("signup");
  const [id, setId] = React.useState("");

  const navigate = useNavigate();

  // Login With Google

  const handleGoogleLogin = () => {
    signInWithPopup(auth, provider).then((data) => {
      console.log("signin google response - ", data);

      if (data.user.email === null || data.user.displayName === null) {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          text: "Please try again later!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        setUserEmail(data.user.email);
        setUserName(data.user.displayName);
        localStorage.setItem("userEmail", data.user.email);
        localStorage.setItem("UserName", data.user.displayName);
        localStorage.setItem("phone_number", data.user.phoneNumber);
      }
    });
  };
  React.useEffect(() => {
    if (userEmail !== null && userName !== null && userEmail !== "null") {
      LoginWithGoogle();
    }
  }, [userEmail, userName]);

  const LoginWithGoogle = async () => {
    // console.log("inner userEmail - ", userEmail);
    if (userEmail !== null && userName !== null && userEmail !== "null") {
      const res = await postApihandler("/signupByAccount", {
        fullName: userName,
        userEmail: userEmail,
        signupBy: "Google",
      });

      console.log("google login api response - ", res);
      if (res.status === 200) {
        localStorage.setItem("UserId", res.data._id);
        Swal.fire({
          icon: "success",
          title: "Successfully login",
          showConfirmButton: false,
          timer: 2000,
        });
        reset();
        navigate("/");
      } else {
        localStorage.clear();
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          text: res.error.response.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      localStorage.clear();
      Swal.fire({
        icon: "error",
        title: "Please Try Again",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  useEffect(() => {
    localStorage.setItem("activatedPage", "login");
  }, []);

  React.useEffect(() => {
    setUserEmail(localStorage.getItem("userEmail"));
    setUserName(localStorage.getItem("UserName"));
    setId(localStorage.getItem("UserId"));
  }, [userEmail, userName]);

  const SignupSubmit = async (value) => {
    console.log("value====>", value);
    const res = await postApihandler("/userSignUp", value);
    console.log("res=====>", res);
    if (res.status === 200) {
      Swal.fire({
        position: "middle-centre",
        icon: "success",
        title: "Successfully Signup",
        showConfirmButton: false,
        timer: 2000,
      });
      reset();
      setToggle("login");
      // navigate("/login");
    } else {
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        text: res.error.response.data.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const loginSubmit = async (value) => {
    // console.log("value====>", value);
    const res = await postApihandler("/UserLogin", value);

    if (res.status === 200) {
      localStorage.setItem("UserId", res.data._id);
      localStorage.setItem("UserName", res.data.fullName);

      Swal.fire({
        icon: "success",
        title: "Successfully login",
        showConfirmButton: false,
        timer: 2000,
      });
      reset();
      navigate("/");
    } else {
      // console.log("Errors");
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        text: res.error.response.data.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const PropertyData = [
    {
      imgURL: PropertyImg1,
      title1: '"Real Estate for Every Dream:',
      title2: 'Your Perfect Property Awaits."',
    },
    {
      imgURL: PropertyImg1,
      title1: '"Real Estate for Every Dream:',
      title2: 'Your Perfect Property Awaits."',
    },
    {
      imgURL: PropertyImg1,
      title1: '"Real Estate for Every Dream:',
      title2: 'Your Perfect Property Awaits."',
    },
  ];

  const FacebookAuthButtonClick = async () => {
    const data = await FacebookAuth();
    console.log("user login with facebook--->", data._tokenResponse);
    const item = {
      userEmail: data._tokenResponse.email,
      fullName: data._tokenResponse.firstName,
      signupBy: "Facebook",
    };
    const res = await postApihandler("/signupByAccount", item);
    if (res.status === 200) {
      localStorage.setItem("UserId", res.data._id);
      localStorage.setItem("UserName", res.data.fullName);

      Swal.fire({
        icon: "success",
        title: "Successfully login",
        showConfirmButton: false,
        timer: 2000,
      });

      navigate("/");
    } else {
      // console.log("Errors");
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        text: res.error.response.data.message,
      });
    }
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        className="flex-lg-row flex-column flex-lg-wrap flex-nowrap mt-4"
      >
        <CssBaseline />
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            backgroundImage: "url(" + bgimg + ")",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="px-5 py-lg-0 py-5 d-md-block d-none"
        >
          <div
            className="login-carouselWrapper h-100 d-flex align-items-center justify-content-center"
            style={{ overflow: "hidden" }}
          >
            <Carousel>
              {PropertyData.map((imgsrc, i) => {
                return (
                  <Carousel.Item interval={3000 + i * 1000} key={i}>
                    <img
                      src={`${imgsrc.imgURL}`}
                      alt=""
                      style={{
                        width: "100%",
                      }}
                    />
                    <div className="LoginProperty-name">
                      <Typography
                        component="h2"
                        // className="text-white text-center"
                        // style={{
                        //   fontSize: "32px",
                        //   lineHeight: "46px",
                        //   fontWeight: 500,
                        // }}
                        className={styles.loginCarousel_heading}
                      >
                        {imgsrc.title1} <br />
                        {imgsrc.title2}
                      </Typography>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </Grid>
        <Grid
          item
          lg={6}
          xs={12}
          component={Paper}
          elevation={6}
          square
          className="px-md-5 px-2 py-lg-0 py-5"
        >
          <Link to="/">
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                paddingTop: "30px",
              }}
              className="mb-3"
            >
              <img style={{ width: "30%" }} src={logo} alt="logo" />
            </div>
          </Link>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              paddingTop: "1rem",
            }}
          >
            <ul
              className="nav nav-pills vikas mb-3"
              id="pills-tab"
              role="tablist"
              style={{
                background: "#F0F0F0",
                padding: "5px",
              }}
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link loginbtn"
                  id="pills-home-tab "
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  // style={{ padding: "17px 100px" }}
                  onClick={() => {
                    setToggle("login");
                  }}
                >
                  Login
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active signupbtn"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  // style={{ padding: "17px 88px" }}
                  onClick={() => {
                    setToggle("signup");
                  }}
                >
                  Registration
                </button>
              </li>
            </ul>
          </div>
          {toogle === "signup" ? (
            <Box
              sx={{
                my: 3,
                mx: 4,

                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit(SignupSubmit)}
                sx={{ mt: 1 }}
              >
                <Typography>Full Name</Typography>
                <TextField
                  placeholder="Full Name"
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  id="fullName"
                  name="fullName"
                  autoComplete="fullName"
                  autoFocus
                  {...register("fullName")}
                  style={{ marginTop: "10px" }}
                />
                <Typography className="mt-3">Email</Typography>
                <TextField
                  placeholder="Email"
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  name="userEmail"
                  type="email"
                  id="userEmail"
                  autoComplete="userEmail"
                  {...register("userEmail")}
                  style={{ marginTop: "10px" }}
                />
                <Typography className="mt-3">Mobile</Typography>
                <TextField
                  placeholder="Mobile"
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  name="phone_number"
                  type="number"
                  id="phone_number"
                  autoComplete="phone_number"
                  {...register("phone_number")}
                  style={{ marginTop: "10px" }}
                />
                <Typography className="mt-3">Password</Typography>
                <TextField
                  placeholder="Password"
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  type="text"
                  id="password"
                  autoComplete="current-password"
                  {...register("password")}
                  style={{ marginTop: "10px" }}
                />
                <Typography className="mt-3">Confirm Password</Typography>
                <TextField
                  placeholder="Confirm Password"
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  type="password"
                  id="confirmPassword"
                  autoComplete="confirmPassword"
                  {...register("confirmPassword")}
                  style={{ marginTop: "10px" }}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={styles.form_button}
                >
                  Sign Up
                </Button>
              </Box>

              <div>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "16px",
                    color: "rgba(102, 102, 102, 1)",
                    paddingTop: "20px",
                  }}
                  className="d-md-block d-none"
                >
                  <span
                    style={{
                      color: "rgba(210, 210, 210, 1)",
                      paddingRight: "15px",
                    }}
                  >
                    -------------------------------------------------------
                  </span>
                  or
                  <span
                    style={{
                      color: "rgba(210, 210, 210, 1)",
                      paddingLeft: "15px",
                    }}
                  >
                    -------------------------------------------------------
                  </span>
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "16px",
                    color: "rgba(102, 102, 102, 1)",
                    paddingTop: "20px",
                  }}
                  className="d-md-none d-block"
                >
                  <span
                    style={{
                      color: "rgba(210, 210, 210, 1)",
                      paddingRight: "15px",
                    }}
                  >
                    -------------
                  </span>
                  or
                  <span
                    style={{
                      color: "rgba(210, 210, 210, 1)",
                      paddingLeft: "15px",
                    }}
                  >
                    -------------
                  </span>
                </Typography>
              </div>
              <div className="my-3">
                <div style={{ paddingTop: "1rem" }}>
                  {" "}
                  <Button
                    variant="outlined"
                    fullWidth
                    className={styles.button_social}
                    onClick={FacebookAuthButtonClick}
                  >
                    <img
                      src={facebook}
                      style={{
                        width: "25px",
                        paddingRight: "5px",
                      }}
                      alt="logo"
                    />
                    Sigup with Facebook
                  </Button>
                </div>

                <div style={{ paddingTop: "1rem" }}>
                  {" "}
                  <Button
                    variant="outlined"
                    fullWidth
                    className={styles.button_social}
                    onClick={handleGoogleLogin}
                  >
                    <img
                      src={google}
                      style={{
                        width: "25px",
                        paddingRight: "5px",
                      }}
                      alt="logo"
                    />
                    Signup with Google
                  </Button>
                </div>
              </div>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "20px",
                  paddingTop: "10px",
                  fontWeight: "500",
                  color: "rgba(102, 102, 102, 1)",
                  lineHeight: "30px",
                }}
              >
                By signing up, I agree to Bharat Makaan’s{" "}
                <span style={{ color: "rgb(7, 139, 76)" }}>Terms of Use</span>{" "}
                and{" "}
                <span style={{ color: "rgb(7, 139, 76)" }}>Privacy Policy</span>
                .
              </Typography>
            </Box>
          ) : toogle === "login" ? (
            <Box
              sx={{
                my: 3,
                mx: 4,

                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit(loginSubmit)}
                sx={{ mt: 1 }}
              >
                <Typography>Email</Typography>
                <TextField
                  placeholder="Email"
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  id="userEmail"
                  name="userEmail"
                  autoComplete="userEmail"
                  type="email"
                  autoFocus
                  {...register("userEmail")}
                  style={{ marginTop: "10px" }}
                />

                <Typography className="mt-3">Password</Typography>
                <TextField
                  placeholder="Confirm Password"
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  {...register("password")}
                  style={{ marginTop: "10px" }}
                />
                <div>
                  <Typography
                    sx={{
                      textAlign: "right",
                      marginTop: "10px",
                    }}
                  >
                    <Link to="/reset-password">
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "rgba(2, 201, 136, 1)",
                        }}
                      >
                        Forgot Password?
                      </span>
                    </Link>
                  </Typography>
                </div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={styles.form_button}
                >
                  Login
                </Button>
              </Box>
              <div>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "16px",
                    color: "rgba(102, 102, 102, 1)",
                    paddingTop: "20px",
                  }}
                  className="d-md-block d-none"
                >
                  <span
                    style={{
                      color: "rgba(210, 210, 210, 1)",
                      paddingRight: "15px",
                    }}
                  >
                    -------------------------------------------------------
                  </span>
                  or
                  <span
                    style={{
                      color: "rgba(210, 210, 210, 1)",
                      paddingLeft: "15px",
                    }}
                  >
                    -------------------------------------------------------
                  </span>
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "16px",
                    color: "rgba(102, 102, 102, 1)",
                    paddingTop: "20px",
                  }}
                  className="d-md-none d-block"
                >
                  <span
                    style={{
                      color: "rgba(210, 210, 210, 1)",
                      paddingRight: "15px",
                    }}
                  >
                    -------------
                  </span>
                  or
                  <span
                    style={{
                      color: "rgba(210, 210, 210, 1)",
                      paddingLeft: "15px",
                    }}
                  >
                    -------------
                  </span>
                </Typography>
              </div>
              <div className="mt-3">
                <div style={{ paddingTop: "1rem" }}>
                  {" "}
                  <Button
                    variant="outlined"
                    fullWidth
                    className={styles.button_social}
                    onClick={FacebookAuthButtonClick}
                  >
                    <img
                      src={facebook}
                      style={{
                        width: "25px",
                        paddingRight: "5px",
                      }}
                      alt="logo"
                    />
                    Login with Facebook
                  </Button>
                </div>

                <div style={{ paddingTop: "1rem" }}>
                  {" "}
                  <Button
                    variant="outlined"
                    fullWidth
                    className={styles.button_social}
                    onClick={handleGoogleLogin}
                  >
                    <img
                      src={google}
                      style={{
                        width: "22px",
                        paddingRight: "10px",
                      }}
                      alt="logo"
                    />
                    Login with Google
                  </Button>
                </div>
              </div>
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
