/** @format */

import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "../../Pages/Home/home.css";
import {
  Box,
  Button,
  Card,
  CardContent,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import priceTrends from "../../Images/Home/price-trends.svg";
import locality from "../../Images/Home/locatity.svg";
import movers from "../../Images/Home/packers.svg";
import painting from "../../Images/Home/painting.svg";
import { getApihandler, postApihandler } from "../../Apihandler";
import Swal from "sweetalert2";
import Carousel from "react-bootstrap/Carousel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const InsightsTools = () => {
  const [userId, setUserId] = useState();
  const [servicesList, setServicesList] = useState([]);
  const [ServiceId, setServiceId] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setUserId(localStorage.getItem("UserId"));
    getServices();
  }, []);

  const getServices = async () => {
    const response = await getApihandler(`/getServices/All`);
    // console.log("getServices List", response);
    setServicesList(response.data);
  };

  const bookService = async () => {
    if (userId !== undefined && userId !== null) {
      if (ServiceId !== "") {
        console.log("ServiceId-->", ServiceId);
        console.log("userId-->", userId);
        const response = await postApihandler(
          `/bookServices/${userId}/${ServiceId}`
        );
        console.log("bookService====>", response);
        if (response.status === 200) {
          Swal.fire({
            position: "middle-centre",
            icon: "success",
            title: "Service Booked",
            showConfirmButton: false,
            timer: 2000,
          });
          setServiceId("");
          handleClose();
        } else {
          Swal.fire({
            position: "middle-centre",
            icon: "error",
            title: response.error.response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        handleClose();
        Swal.fire({
          // position: "middle-centre",
          icon: "error",
          title: "Please Select Any Service",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      handleClose();
      Swal.fire({
        icon: "error",
        title: "Please Login Your Account",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <section className="success-stories insight-tools py-md-5 py-2 px-md-0 px-2">
      <Container maxWidth="xxl" className="py-md-4 py-2">
        <Typography
          variant="h5"
          component="h4"
          className="smallHeading_TwoHeadings mb-2 text-white text-center mt-md-0 mt-3"
        >
          Insights & Tools
        </Typography>

        <Typography
          variant="body1"
          component="p"
          className="pb-2 text-white text-center"
          accordion
        >
          Go from browsing to buying
        </Typography>
        {/* Desktop-x-x-xx-x-x-x-x-x-x-x-x-x-x-x-x */}
        <Grid
          container
          spacing={2}
          className="justify-content-between d-md-flex d-none flex-md-row flex-column flex-lg-nowrap pt-lg-4 pt-md-5 pt-3 pb-lg-0 pb-md-5 pb-3"
        >
          <Grid
            item
            className="py-lg-0 py-2 px-lg-3 home-ownerboxWrapper"
            lg={3}
            sm={6}
            xs={12}
          >
            <Link to="/price-trends">
              <Card
                className="mx-auto py-2"
                style={{
                  backgroundColor: "#ed7225",
                  boxShadow: "none",
                }}
              >
                <CardContent className="card-container-tool">
                  <div className="text-center">
                    <img
                      src={priceTrends}
                      style={{
                        width: "85px",
                        height: "80px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <Typography
                    variant="subtitle1"
                    component="h3"
                    className="text-center box-title"
                  >
                    Price Trends
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className="text-center box-subtitle"
                  >
                    Check property rates and prices
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid
            item
            className="py-lg-0 py-2 px-lg-3 home-ownerboxWrapper"
            lg={3}
            sm={6}
            xs={12}
          >
            <Link to="/locality-insight">
              <Card
                className="mx-auto py-2"
                style={{
                  backgroundColor: "#ed7225",
                  boxShadow: "none",
                }}
              >
                <CardContent className="card-container-tool">
                  <div className="text-center">
                    <img
                      src={locality}
                      style={{
                        width: "85px",
                        height: "80px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <Typography
                    variant="subtitle1"
                    component="h3"
                    className="text-center box-title"
                  >
                    Locality Insight
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className="text-center box-subtitle"
                  >
                    Know more about different localities
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid
            item
            className="py-lg-0 py-2 px-lg-3 home-ownerboxWrapper"
            lg={3}
            sm={6}
            xs={12}
          >
            <Link
              onClick={() => {
                handleOpen();
              }}
            >
              <Card
                className="mx-auto py-2"
                style={{
                  backgroundColor: "#ed7225",
                  boxShadow: "none",
                }}
              >
                <CardContent className="card-container-tool">
                  <div className="text-center">
                    <img
                      src={movers}
                      style={{
                        width: "85px",
                        height: "80px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <Typography
                    variant="subtitle1"
                    component="h3"
                    className="text-center box-title"
                  >
                    Packers & Movers
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className="text-center box-subtitle"
                  >
                    Know what residents are saying
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid
            item
            className="py-lg-0 py-2 px-lg-3 home-ownerboxWrapper"
            lg={3}
            sm={6}
            xs={12}
          >
            <Link
              onClick={() => {
                handleOpen();
              }}
            >
              <Card
                className="mx-auto py-2"
                style={{
                  backgroundColor: "#ed7225",
                  boxShadow: "none",
                }}
              >
                <CardContent className="card-container-tool">
                  <div className="text-center">
                    <img
                      src={painting}
                      style={{
                        width: "85px",
                        height: "80px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <Typography
                    variant="subtitle1"
                    component="h3"
                    className="text-center box-title"
                  >
                    Painting & Housekeeping
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className="text-center box-subtitle"
                  >
                    Know more about different localities
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        </Grid>

        {/* Mobile--x-xx-x-x-x-x-x-x-x-x-x-x-x */}
        <Grid className="hide-slider-controls d-md-none d-block pb-md-0 pb-3">
          <Carousel>
            <Carousel.Item interval={3000 + 1 * 1000}>
              <Grid
                item
                className="py-lg-0 py-2 px-lg-3 home-ownerboxWrapper"
                lg={3}
                sm={6}
                xs={11}
              >
                <Link to="/price-trends">
                  <Card
                    className="mx-auto py-2"
                    style={{
                      backgroundColor: "#00E79B",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent className="card-container-tool">
                      <div className="text-center">
                        <img
                          src={priceTrends}
                          style={{
                            width: "85px",
                            height: "80px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <Typography
                        variant="subtitle1"
                        component="h3"
                        className="text-center box-title"
                      >
                        Price Trends
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        className="text-center box-subtitle"
                      >
                        Check property rates and prices
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            </Carousel.Item>
            <Carousel.Item interval={3000 + 1 * 1000}>
              <Grid
                item
                className="py-lg-0 py-2 px-lg-3 home-ownerboxWrapper"
                lg={3}
                sm={6}
                xs={12}
              >
                <Link to="/locality-insight">
                  <Card
                    className="mx-auto py-2"
                    style={{
                      backgroundColor: "#00E79B",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent className="card-container-tool">
                      <div className="text-center">
                        <img
                          src={locality}
                          style={{
                            width: "85px",
                            height: "80px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <Typography
                        variant="subtitle1"
                        component="h3"
                        className="text-center box-title"
                      >
                        Locality Insight
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        className="text-center box-subtitle"
                      >
                        Know more about different localities
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            </Carousel.Item>
            <Carousel.Item interval={3000 + 1 * 1000}>
              <Grid
                item
                className="py-lg-0 py-2 px-lg-3 home-ownerboxWrapper"
                lg={3}
                sm={6}
                xs={12}
              >
                <Link
                  onClick={() => {
                    handleOpen();
                  }}
                >
                  <Card
                    className="mx-auto py-2"
                    style={{
                      backgroundColor: "#00E79B",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent className="card-container-tool">
                      <div className="text-center">
                        <img
                          src={movers}
                          style={{
                            width: "85px",
                            height: "80px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <Typography
                        variant="subtitle1"
                        component="h3"
                        className="text-center box-title"
                      >
                        Packers & Movers
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        className="text-center box-subtitle"
                      >
                        Know what residents are saying
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            </Carousel.Item>
            <Carousel.Item interval={3000 + 1 * 1000}>
              <Grid
                item
                className="py-lg-0 py-2 px-lg-3 home-ownerboxWrapper"
                lg={3}
                sm={6}
                xs={12}
              >
                <Link
                  onClick={() => {
                    handleOpen();
                  }}
                >
                  <Card
                    className="mx-auto py-2"
                    style={{
                      backgroundColor: "#00E79B",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent className="card-container-tool">
                      <div className="text-center">
                        <img
                          src={painting}
                          style={{
                            width: "85px",
                            height: "80px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <Typography
                        variant="subtitle1"
                        component="h3"
                        className="text-center box-title"
                      >
                        Painting & Housekeeping
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        className="text-center box-subtitle"
                      >
                        Know more about different localities
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            </Carousel.Item>
          </Carousel>
        </Grid>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className={
              "model-header wrapper d-flex justify-content-between align-content-center"
            }
          >
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Book Services
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h4"
              className="closeModel"
            >
              <span
                onClick={() => {
                  handleClose();
                }}
                style={{ cursor: "pointer" }}
              >
                X
              </span>
            </Typography>
          </div>

          <div className={"book-services-wrapper"}>
            <Typography id="transition-modal-description">
              Book Additional Services:
            </Typography>
            <div className="mt-5">
              <Grid container spacing={2} className="m-0">
                <Grid md={6}>
                  <FormControl variant="standard">
                    <InputLabel id="demo-simple-select-standard-label">
                      Select Services
                    </InputLabel>
                    <Select
                      sx={{ mt: 2, minWidth: { lg: 214, xs: 250 } }}
                      variant="standard"
                      id="demo-simple-select-standard"
                      onChange={(e) => setServiceId(e.target.value)}
                      value={ServiceId}
                    >
                      <MenuItem value="">
                        <em>Services...</em>
                      </MenuItem>
                      {servicesList !== null &&
                        servicesList !== undefined &&
                        servicesList.map((val, ind) => {
                          return (
                            <MenuItem value={val._id}>
                              {val.serviceName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid md={6}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "rgb(7, 139, 76)",
                      color: "#FFF",
                      textTransform: "capitalize",
                      paddingTop: ".6rem",
                      paddingBottom: ".6rem",
                    }}
                    className="px-md-3 px-3 mt-sm-0 mt-4"
                    onClick={() => {
                      handleClose();
                      // setModelBtnCount(2);
                      bookService();
                    }}
                  >
                    Book
                    <span
                      style={{
                        color: "rgba(31, 64, 105, 1)",
                        fontSize: "18px",
                      }}
                      className="different-font"
                    >
                      Services
                    </span>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
      </Modal>
    </section>
  );
};

export default InsightsTools;
