import * as React from "react";
import { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";

export default function LayoutTemplate({ children }) {
  const [userId, setuserId] = useState();

  // Get Local Storage Data
  useEffect(() => {
    setuserId(localStorage.getItem("UserId"));
  }, []);

  return (
    <div>
      <Header />
      <section>{children}</section>
      <Footer />
    </div>
  );
}
